import React, {useState, useEffect} from 'react';
import {faChevronLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../editor.scss';
import axios from "axios";
import {useForm} from "react-hook-form";
import {useAlert} from "react-alert";
import {Link} from "react-router-dom";

const NovinkyAddUpdate = (props) => {

    const [loaded, setLoaded] = useState(false);
    const {register, handleSubmit, setValue, reset} = useForm({defaultValues:{
            uvod: "", zobrazovat: false, titulek: "", zprava: ""
    }});

    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty(),
    );

    const alert = useAlert();

    const handleWyswyg = (a) => {
        setEditorState(a);
    }

    useEffect(() => {
        if (props.id && !loaded) {
            axios.get('/novinky/' + props.id).then(res => {
               setValue('uvod', res.data.uvod);
               setValue("titulek", res.data.titulek);
               setValue("zobrazovat", res.data.zobrazovat);
                setValue("headPhotoUrl", res.data.headPhotoUrl);


                

                const contentBlock = htmlToDraft(res.data.zprava);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                setEditorState(() => EditorState.createWithContent(contentState));

                setLoaded(true);
            });
        }
    });

    const onSubmit = (data) => {
        data.zprava = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        axios.post('/novinky/upsert/' + props.id, data).then(res => {
            if(res.data.result === 0){
                alert.success(res.data.message);
                reset();
                setEditorState(() => EditorState.createEmpty());
            } else {
                alert.error(res.data.message, {timeout: 0})
            }
        }).catch(err => {
            console.log(err);
            alert.error("Uložení se nezdařilo chyba serveru", {timeout: 0})
        });
    }

    return (
        <div>

            {!props.id ? <h1>Přidat novinku</h1> : <h1>Upravit novinku</h1>}
            {!props.edit ?

                <div>
                    <div className="breadCrumb">
                        <div className="item"><Link to="/novinky">Novinky</Link></div>
                        <div className="item"><FontAwesomeIcon icon={faChevronLeft} className="i"/></div>
                        <div className="item">Přidat novinku</div>
                    </div>
                </div>
                : null

            }
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className={"form"}>
                <div className="header"></div>
                <div className="body">
                    <div className={"item"}>
                        <label>Titulek*</label>
                        <input type={"text"} name={"titulek"} maxLength="100"
                               ref={register({required: true})}/>
                    </div>
                    <div className={"item"}>
                        <label>Náhledová fotka - url</label>
                        <input type={"text"} name={"headPhotoUrl"} maxLength="1500"
                               ref={register()}/>
                    </div>
                    <div className={"item"}>
                        <label>Úvodní text*</label>
                        <textarea maxLength={"250"} name={"uvod"} ref={register({required: true})}></textarea>
                    </div>
                    
                    <div className={"item"}>
                        <label>Zpráva</label>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={handleWyswyg}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'emoji', 'image', 'link'],
                                inline: {
                                    inDropdown: false,
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    options: ['bold', 'italic', 'underline', 'strikethrough']
                                },
                            }}
                        />
                    </div>

                    <div className={"item"}>
                        <label>Zobrazovat na webu</label>
                        <input type={"checkbox"} name={"zobrazovat"} ref={register()}/>
                    </div>
                </div>
                <div className="footer">
                    <div className="ctrl">
                        <button type="submit" className="item btn-save">
                            <FontAwesomeIcon icon={faSave} className="i"/>Uložit
                        </button>
                    </div>
                </div>
            </div>
            </form>
        </div>
    );

}
export default NovinkyAddUpdate;