import React, {useState} from 'react';



const SelectTypAkce = (props) => {

    const [selectedValue, setSelectedValue] = useState(props.default);


    const onChange = (e) =>{
        setSelectedValue(e.target.value);
    }



    return(
        <select name={props.name} onChange={onChange}  defaultValue={selectedValue} ref={props.reff}>
            <option value={'spolecenska'}>Společenská</option>
            <option value={'turnaj'}>Turnaj</option>
            <option value={'mistrovskeUtk'}>Mistrovské utkání</option>
            <option value={'primestTabor'}>Příměstský tábor</option>
            <option value={'ostatni'}>Ostatní</option>
        </select>

    )
}

export default SelectTypAkce;