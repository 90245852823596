import React, {useEffect, useState} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import './Finance.scss'
import 'react-tabs/style/react-tabs.css';
import ClenoveTab from "./ClenoveTab";
import PlatbyTab from "./PlatbyTab";
import BackToDashboard from "../shared/BackToDashboard";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import {useAlert} from "react-alert";

const Finance = () => {

    const [clenoveLoaded, setClenoveLoaded] = useState(false);
    const [platbyLoaded, setPlatbyLoaded] = useState(false);
    const [fioDateFrom, setFioDateFrom] = useState(false);
    const [fioDateTo, setFioDateTo] = useState(false);

    const alert = useAlert();

    useEffect(() => {

    });


    const prepocitatPoplatky = () => {
        axios.post("finance/prepocitat").then(res => {
            console.log(res);
            if(res.data.result === 0){
                alert.success('Poplatky byly vygenerovány.');
            } else {
                alert.error('Došlo k chybě.', {timeout: 0});
            }
            
        }).catch(() => {
            alert.error('Došlo k chybě.', {timeout: 0});
        });
    }
    
    /*const upominkaNeuhrazenePlatby = () => {
        axios.post("finance/vygenerovatUpominky").then(res => {
            console.log(res);
            if(res.data.result === 0){
                alert.success('Upomínky byly vygenerovány.');
            } else {
                alert.error('Došlo k chybě.', {timeout: 0});
            }

        }).catch(err => {
            alert.error('Došlo k chybě.', {timeout: 0});
        });
    }*/

    const processFio = () => {
        if(!fioDateFrom || !fioDateTo){
            alert.error('Vyberte datum od a datum do.');
            return false;
        }
        axios.post("finance/processFio/" + fioDateFrom + "/" + fioDateTo).then(() => {
            alert.success('Fio bylo zprocesováno.');
        }).catch(() => {
            alert.error('Došlo k chybě.', {timeout: 0});
        });
    }

    const handleTabChange = (index) => {
        switch (index) {
            case 1:
                setPlatbyLoaded(false);
                break;
            default:
                setClenoveLoaded(false);

        }
    }

    return (
        <div className={"finance"}>
            <h1><BackToDashboard />Finance</h1>
            <div className="subMenu">
                
                <button type="button" className="item btn-save" onClick={prepocitatPoplatky}>
                    <FontAwesomeIcon icon={faSync} className="i"/>Vygenerovat nové členské poplatky pro tento rok
                </button>

                {/*<button type="button" className="item btn-save" >
                    <FontAwesomeIcon icon={faEnvelope} className="i"/>Vygenerovat emaily pro nezaplacené členské poplatky
                </button>*/}

                {/*<button type="button" className="item btn-save" onClick={upominkaNeuhrazenePlatby}>
                    <FontAwesomeIcon icon={faExclamationTriangle} className="i"/>Vygenerovat upomínky na veškeré neuhrazené platby po splatnosti
                </button>*/}
                
               
            </div>
            <div className="subMenu">
                <label>Od</label>
                <input style={{marginLeft: 10}} type={"date"} onChange={(e) => setFioDateFrom(e.target.value)}  />
                <label style={{marginLeft: 10}}>Do</label>
                <input style={{marginLeft: 10}} type={"date"} onChange={(e) => setFioDateTo(e.target.value)} />
                <button type="button" className="item btn-save" onClick={processFio}>
                    <FontAwesomeIcon icon={faSync} className="i"/>Stáhnout FIO
                </button>

            </div>
            <div className={"filter"}>
                <Tabs onSelect={handleTabChange}>
                    <TabList>
                        <Tab>Roční členské příspěvky</Tab>
                        <Tab>Platby</Tab>
                    </TabList>
                    <TabPanel>
                        <ClenoveTab refresh={clenoveLoaded} />
                    </TabPanel>
                    <TabPanel>
                        <PlatbyTab refresh={platbyLoaded} />
                    </TabPanel>
                </Tabs>


            </div>
        </div>
    )
}

export default Finance;