import React from 'react';





const NotAllowed = () => {




    return(
        <div className="notAllowed" >

            Not Allowed

        </div>

    )
}

export default NotAllowed;