import React from 'react';
import axios from "axios";


const Test = () => {

    const test1 = () =>{
        axios.get('api/test/latestVariable').then(res => {
            console.log(res);


        }).catch(err => {
            console.log(err)
        });
    }


    return (
        <div >
            <h1>Test</h1>
            <button onClick={test1}>Test generuj variabl</button>
           


        </div>
    )
}

export default Test;