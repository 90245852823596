import React, {useState} from 'react';
import {faMap, faFileExport} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from 'moment';
import axios from "axios";
export const TreninkDochazkaExport = (props) => {
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');

    /*const documentsBase64ToStream = (baseEncodedDoc) => {
        // https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript

        const byteCharacters = atob(baseEncodedDoc);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        return byteArray;
    }*/
    
    const handleExportClick = () => {
       
        
        let params='?from=' + from + '&to=' + to + '&treninkId=' + props.forModal.id;

        axios.get("api/reports/exportDochazka" + params).then(res => {
           console.log(res);

            //const bArray = documentsBase64ToStream(res.data);
            const file = new Blob([res.data], { type: 'text/csv'})
            const url = URL.createObjectURL(file)
            const link = document.createElement('a')
            link.href = url
            link.target = '_blank'
            link.download = 'dochazka.csv'
            link.click()
           
        });
        
        
    }
    
    return(
        <div>
            <h1>Export docházky tréninku</h1>
            <h2 style={{marginBottom: 5}}>{props.forModal.nazev}</h2>
            <h3 style={{marginTop: 0}}>
                <FontAwesomeIcon style={{color: '#3693D0', marginRight: 5}} icon={faMap} /> 
                {props.forModal.mistoNazev}
            </h3>
            <div style={{borderTop: '1px solid #3693D0'}}>
                <div className="filtr" style={{backgroundColor: 'rgb(227, 243, 253)', borderBottomLeftRadius: 10, borderBottomRightRadius: 10}}>
                    <div className="item filtrSearch">
                        <div className="searchInputs">
                            <div className="itemSearchInputs">
                                <label htmlFor="datumOD">Datum od:</label>
                                <input type="date" onChange={(e) => setFrom(moment(e.target.value).format('yyyy-mm-dd'))}  name={"datumOD"}  />

                            </div>
                            <div className="itemSearchInputs">
                                <label htmlFor="datumOD">Datum do:</label>
                                <input 
                                    type="date"  
                                    name={"datumOD"}
                                    onChange={(e) => setTo(moment(e.target.value).format('yyyy-mm-dd'))}
                                />

                            </div>

                        </div>
                        <div className="ctrl">
                            {to === '' || from === '' ? 
                                <div style={{marginTop: 10, opacity: 0.5}}>Vyplňte datum od a datum do.</div>
                            : <button
                                    className="btn-filtr"
                                    onClick={() => {
                                        handleExportClick()
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faFileExport}
                                        className="i"

                                    />
                                    Exportuj
                                </button> }
                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
    
}