import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import InputError from "../shared/InputError";
import messages from "../shared/InputErrorMessages";
import {useForm} from "react-hook-form";
import axios from "axios";
import {useAlert} from "react-alert";





const NoveEditMistaTreninku = (props) => {

    const {register, handleSubmit, errors, setValue, reset} = useForm({
        defaultValues: {nazev: "", gurl: "" ,popis: ""}
    });
    const [aktivni, setAktivni] = useState(0);
    const [loaded, setLoaded] = useState(false);

    const alert = useAlert();

    const onSubmit = (data) => {
        if(props.edit){
            axios.post('api/treninky/editMistoTreninku', data).then(res => {

                if(res.data.result === 1){
                    alert.success(res.data.message);
                    props.onClose();
                }else{
                    alert.error(res.data.message, {timeout: 0});
                    console.log(res.data.exception)
                }

            }).catch(err => {
                alert.error('Chyba serveru.', {timeout: 0});
                console.log(err);

            });

        }else{
            axios.post('api/treninky/ulozMistoTreninku', data).then(res => {

                if(res.data.result === 1){
                    alert.success(res.data.message);
                    reset();
                }else{
                    alert.error(res.data.message, {timeout: 0});
                    console.log(res.data.exception)
                }

            }).catch(err => {
                alert.error('Chyba serveru.', {timeout: 0});
                console.log(err);

            });

        }


    }



    useEffect(() => {
        const loadData = () => {




            if(props.edit){
                axios.get('api/treninky/detailMista/' + props.id).then(res => {
                    //console.log(res.data);
                    setValue("id", props.id);
                    setValue("nazev", res.data.nazev);
                    setValue("popis", res.data.popis);
                    setValue("gurl", res.data.gurl);
                    setAktivni(res.data.aktivni);
                    setLoaded(true);

                }).catch(err => {

                });
            }



        }

        loadData();

    }, [setValue, props.edit, props.id]);


    return(
        <div>

            {props.edit
                ?
                <div>
                    <h1>Editovat místo</h1>

                </div>
                :
                <div>
                    <h1>Nové místo</h1>
                    <div className="breadCrumb">
                        <div className="item"> <Link to="/treninky-admin">Tréninky</Link></div>
                        <div className="item"><FontAwesomeIcon icon={faChevronLeft} className="i" /></div>
                        <div className="item"> <Link to="/treninky-admin/mistaTreninku">Místa tréninků</Link></div>
                        <div className="item"><FontAwesomeIcon icon={faChevronLeft} className="i" /></div>
                        <div className="item">Nové místo</div>
                    </div>
                </div>
            }

            <div className="form">
                <div className="header">

                </div>
                <div className="body">
                    {props.edit ?
                        <div className="item">
                            <label>Id*</label>
                            <input type="text" readOnly={"readonly"} name="id"  ref={register()}/>

                        </div>

                        : null}
                    {props.edit && loaded === true ?
                        <div className="item">
                            <label>Aktivní</label>
                            <select name={"aktivni"} defaultValue={aktivni} ref={register()}>
                                <option value={1}>Ano</option>
                                <option value={0}>Ne</option>
                            </select>

                        </div>
                        : null}
                    <div className="item">
                        <label>Název*</label>
                        <input type="text" name="nazev" maxLength="100" ref={register({required: true})}/>
                        {errors.nazev &&
                        <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Google map url</label>
                        <input name={"gurl"} type={"text"} maxLength="1000"  ref={register()} />
                    </div>
                    <div className="item">
                        <label>Popis</label>
                        <textarea name="popis" maxLength="2000" ref={register()}></textarea>
                    </div>



                </div>
                <div className="footer">
                    <div className="ctrl">
                        <button type="button" onClick={handleSubmit(onSubmit)} className="item btn-save"><FontAwesomeIcon icon={faSave} className="i" />Uložit</button>

                    </div>
                </div>

            </div>






        </div>

    )
}

export default NoveEditMistaTreninku;