import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {useForm} from "react-hook-form";
import InputError from '../shared/InputError';
import messages from '../shared/InputErrorMessages';
import {useAlert} from 'react-alert';
import SelectTrenSkupina from "../treninkovaSkupina-admin/SelectTrenSkupina";
import './NovyClen.scss';
import ClenFotkyUpload from "./ClenFotkyUpload";


const NovyClen = (props) => {

    const [starsi18, setStarsi18] = useState(false);
    const [changePassword, setChangePassword] = useState(!props.edit);
    const {register, handleSubmit, errors, reset, setValue} = useForm({
        defaultValues: {
            jmeno: "",
            prijmeni: "",
            rc: "",
            mobil: "",
            ulice: "",
            ulicecp: "",
            mesto: "",
            psc: "",
            email: "",
            vystavitpotvr: false,
            souhlas: false,
            starsi18: false,
            zastupce: "",
            zastupceemail: "",
            zastupcemobil: "",
            admin: false,
            password: "",
            passwordconfirm: "",
            fotky: [],
            poplatekPlatitOd: null,
            platRegPruk: null,
            vyskaCm: 0,
            vahaKg: 0,
            dosahSmecCm: 0,
            dosahBlokCm: 0,
            typPlatby: 0,
            pozice: '',
            trener: false
        }
    });


    const alert = useAlert();
    const [selectedTrenS, setSelectedTrenS] = useState(0);
    const [selectedPozice, setSelectedPozice] = useState("");
    const [loaded, setLoaded] = useState(false);
    const utils = require('../utils');


    const onSubmit = (data, event) => {
        if (props.edit) {

            axios.post('api/clenove/editClena', data).then(res => {
                //this.props.alert.success('Produkt byl přidán');
                if (res.data.result > 0) {
                    alert.success(res.data.message);
                    props.onClose();

                } else {
                    alert.error(res.data.message, {timeout: 0});
                    console.log(res.data.exception);
                }


            }).catch(err => {
                alert.error('Došlo k chybě na serveru.', {timeout: 0});
                console.log(err);
            });

        } else {
            axios.post('api/clenove/ulozClena', data).then(res => {
                //this.props.alert.success('Produkt byl přidán');
                if (res.data.result > 0) {
                    alert.success(res.data.message);
                    reset();
                } else {
                    alert.error(res.data.message, {timeout: 0});
                    console.log(res.data.exception);
                }


            }).catch(err => {
                alert.error('Došlo k chybě na serveru.', {timeout: 0});
                console.log(err);
            });
        }


        event.preventDefault();
    }


    useEffect(() => {

        const getClenDetail = () => {
            axios.get('api/clenove/detailClena/' + props.idClen).then(res => {
                //this.props.alert.success('Produkt byl přidán');
                //console.log(res.data);
                setValue("jmeno", res.data.jmeno);
                setValue("prijmeni", res.data.prijmeni);
                setValue("email", res.data.email);
                setValue("id", res.data.id);
                setValue("mesto", res.data.mesto);
                setValue("mobil", res.data.mobil);
                setValue("psc", res.data.psc);
                setValue("rc", res.data.rc);
                setValue("role", res.data.role);
                setValue("souhlas", res.data.souhlas);
                setValue("starsi18", res.data.starsi18);
                setValue("stav", res.data.stav);
                setSelectedTrenS(res.data.trens);
                setValue("ulice", res.data.ulice);
                setValue("ulicecp", res.data.ulicecp);
                setValue("vystavitpotvr", res.data.vystavitpotvr);
                setValue("zastupce", res.data.zastupce);
                setValue("poplatekPlatitOd", res.data.poplatekPlatitOd ? res.data.poplatekPlatitOd.substring(0, res.data.poplatekPlatitOd.length - 9) : null);
                setValue("zastupceemail", res.data.zastupceemail);
                setValue("zastupcemobil", res.data.zastupcemobil);
                setValue("platRegPruk", res.data.platRegPruk ? res.data.platRegPruk.substring(0, res.data.platRegPruk.length - 9) : null);
                setValue("datumnar", res.data.datumNar.substring(0, res.data.datumNar.length - 9));
                setValue("admin", res.data.admin);
                setStarsi18(res.data.starsi18);
                setValue("vyskaCm", res.data.vyskaCm);
                setValue("vahaKg", res.data.vahaKg);
                setValue("dosahSmecCm", res.data.dosahSmecCm);
                setValue("dosahBlokCm", res.data.dosahBlokCm);
                setValue("typPlatby", res.data.typPlatby);
                setSelectedPozice(res.data.pozice);
                setValue("datumRegistrace", res.data.datumRegistrace ? res.data.datumRegistrace.substring(0, res.data.datumRegistrace.length - 9) : null);
                setValue("trener", res.data.trener);
                setValue("asistentTrenera", res.data.asistentTrenera);
                setLoaded(true);


            }).catch(err => {
                alert.error('Nepovedlo se načíst údaje o členovi.', {timeout: 0});
                console.log(err);

            });
        }

        if (!loaded) {
            if (props.edit) {
                getClenDetail();
            } else {
                setLoaded(true);
            }
        }


    }, [props.edit, alert, loaded, props.idClen, setValue]);


    const isEmail = (value) => {
        return utils.isEmail(value);
    }

    return (

        <div className={"novy-clen"}>
            {props.edit ?
                <h1>Editace člena</h1>

                :
                <div>
                    <h1>Nový člen</h1>
                    <div className="breadCrumb">
                        <div className="item"><Link to="/clenove">Členové</Link></div>
                        <div className="item"><FontAwesomeIcon icon={faChevronLeft} className="i"/></div>
                        <div className="item">Nový člen</div>
                    </div>
                </div>

            }


            <div className="form">
                <div className="header">

                </div>
                <div className="body">
                    <div className="item"><h3 style={{margin: 0}}>POVINNÁ POLE</h3></div>
                    {props.edit ?
                        <div className="item">
                            <label>Id*</label>
                            <input type="text" readOnly={"readonly"} name="id" ref={register({required: true})}/>
                            {errors.id &&
                                <InputError message={messages.required}></InputError>}
                        </div>
                        : null}
                    <div className="item">
                        <label>Jméno*</label>
                        <input type="text" name="jmeno" maxLength="30" ref={register({required: true})}/>
                        {errors.jmeno &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Příjmení*</label>
                        <input type="text" name="prijmeni" maxLength="30" ref={register({required: true})}/>
                        {errors.prijmeni &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Datum narození*</label>
                        <input type="date" format="dd.MM.yyyy" name="datumnar" ref={register({required: true})}/>
                        {errors.datumnar &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Rodné číslo (bez lomítka)*</label>
                        <input type="text" name="rc" maxLength="20" ref={register({required: true})}/>
                        {errors.rc &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Mobilní číslo*</label>
                        <input type="text" name="mobil" maxLength="9" placeholder="111222333"
                               ref={register({required: true})}/>
                        {errors.mobil &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Ulice*</label>
                        <input type="text" name="ulice" maxLength="100" ref={register({required: true})}/>
                        {errors.ulice &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Číslo popisné*</label>
                        <input type="text" name="ulicecp" maxLength="10" ref={register({required: true})}/>
                        {errors.ulicecp &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Město*</label>
                        <input type="text" name="mesto" maxLength="100" ref={register({required: true})}/>
                        {errors.mesto &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>PSČ*</label>
                        <input type="text" name="psc" maxLength="6" ref={register({required: true})}/>
                        {errors.psc &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    {loaded ? <div className="item">

                        <label>Tréninková s.*</label>
                        <SelectTrenSkupina name="trens" default={selectedTrenS} reff={register()}></SelectTrenSkupina>

                    </div> : null}

                    <div className="item">
                        <label>Email*</label>
                        <input type="text" name="email" maxLength="60"
                               ref={register({required: true, validate: isEmail})}/>
                        {errors.email &&
                            <InputError message={messages.email}></InputError>}
                    </div>
                    {props.edit ?
                        <div className="item">
                            <label>Změnit heslo</label>
                            <input type="checkbox" onClick={() => {
                                setChangePassword(!changePassword)
                            }}/>


                        </div>
                        : null}
                    {changePassword
                        ? <div className="item">
                            <label>Heslo*</label>
                            <input type="password" name="password" ref={register({required: true})}/>
                            {errors.email &&
                                <InputError message={messages.required}></InputError>}
                            <label>Heslo znovu*</label>
                            <input type="password" name="passwordconfirm" ref={register({required: true})}/>
                            {errors.email &&
                                <InputError message={messages.required}></InputError>}
                        </div>

                        : null
                    }

                    <div className="item">
                        <label>Role*</label>
                        <select name="role" ref={register}>
                            <option value={"clen"}>Člen</option>

                            <option value={"neclen"}>Nečlenský účet</option>
                        </select>
                    </div>
                    <div className="item">
                        <label>Poplatek platí od*</label>
                        <input type="date" name="poplatekPlatitOd" ref={register({required: true})}/>
                        {errors.poplatekPlatitOd && <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Datum registrace*</label>
                        <input type="date" name="datumRegistrace" ref={register({required: true})}/>
                        {errors.datumRegistrace &&
                            <InputError message={messages.required}></InputError>}
                    </div>

                    <div className="item">
                        <label className="small">Člen chce vystavit potvrzení na zaplacení členských příspěvků?</label>
                        <input type="checkbox" name="vystavitpotvr" ref={register}/>

                    </div>
                    <div className="item">
                        <label className="small">Člen Souhlasí s uveřejněním svého rodného čísla pro účely MŠMT a
                            Volejbalového informačního systému (VIS). Souhlasí se zpracováním osobních údajů pro účely
                            volejbalového klubu VK DK z.s. (Pokud nesouhlasí s uveřejněním, bude z důvodu ušlé dotace
                            MŠMT členský příspěvek navýšen o 1500,-)</label>
                        <input type="checkbox" name="souhlas" ref={register}/>

                    </div>

                    <div className="item">
                        <label className="small">Člen prohlašuje, že je ke dni vyplnění formuláře starší 18-ti
                            let.</label>
                        <input type="checkbox" name="starsi18" onChange={e => {
                            setStarsi18(e.target.checked);
                        }} ref={register}/>

                    </div>
                    {!starsi18 ?
                        <div className="item">
                            <label>Jméno a příjmení odpovědného zástupce</label>
                            <input type="text" name="zastupce" maxLength="100" ref={register({required: true})}/>
                            {errors.zastupce &&
                                <InputError message={messages.required}></InputError>}
                        </div>
                        : null}

                    {!starsi18 ?
                        <div className="item">
                            <label>Email odpovědného zástupce*</label>

                            <input type="text" name="zastupceemail" maxLength="60"
                                   ref={register({required: true, validate: isEmail})}/>
                            {errors.zastupceemail &&
                                <InputError message={messages.email}></InputError>}
                        </div>

                        : null}

                    {!starsi18 ?
                        <div className="item">
                            <label>Mobil odpovědného zástupce*</label>
                            <input type="text" name="zastupcemobil" maxLength="9" placeholder="111222333"
                                   ref={register({required: true})}/>
                            {errors.zastupcemobil &&
                                <InputError message={messages.required}></InputError>}
                        </div>

                        : null}

                    <div className="item">
                        <label>Stav</label>

                        <select name="stav" ref={register()}>
                            <option value={1}>Nový</option>
                            <option value={2}>Aktivní</option>
                            <option value={-1}>Neaktivní</option>
                        </select>

                    </div>
                    <div className="item">
                        <label>Typ platby</label>

                        <select name="typPlatby" ref={register()}>
                            <option value={0}>Základní</option>
                            <option value={1}>Soutěž</option>
                        </select>

                    </div>

                    <div className="item" style={{marginTop: 25, paddingTop: 10, borderTop: '1px solid black'}}>
                        <h3 style={{margin: 0}}>OSTATNÍ</h3>

                    </div>

                    <div className="item">
                        <label>Je admin?</label>
                        <input type="checkbox" name="admin" ref={register}/>
                    </div>

                    <div className="item">
                        <label>Je trenér?</label>
                        <input type="checkbox" name="trener" ref={register}/>
                    </div>

                    <div className="item">
                        <label>Je asistent trenéra?</label>
                        <input type="checkbox" name="asistentTrenera" ref={register}/>
                    </div>


                    <div className="item">
                        <label>Platnost registračního průkazu</label>
                        <input type="date" name="platRegPruk" ref={register()}/>
                    </div>

                    <div className="item" style={{marginTop: 25, paddingTop: 10, borderTop: '1px solid black'}}>
                        <h3 style={{margin: 0}}>WEB</h3>

                    </div>


                    <div className="item">
                        <label>Výška v cm</label>
                        <input type="number" name="vyskaCm" ref={register({required: true})}/>
                        {errors.vyskaCm &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Váha v kg</label>
                        <input type="number" name="vahaKg" maxLength="6" ref={register({required: true})}/>
                        {errors.vahaKg &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Dosah blok v cm</label>
                        <input type="number" name="dosahBlokCm" maxLength="6" ref={register({required: true})}/>
                        {errors.dosahBlokCm &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Dosah smeč v cm</label>
                        <input type="number" name="dosahSmecCm" maxLength="6" ref={register({required: true})}/>
                        {errors.dosahSmecCm &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Pozice</label>
                        <select name="pozice" onChange={(e) => setSelectedPozice(e.target.value)} value={selectedPozice}
                                ref={register()}>

                            <option key={1} value="smečař">smečař</option>
                            <option key={2} value="blokař">blokař</option>
                            <option key={3} value="univerzál">univerzál</option>
                            <option key={4} value="libero">libero</option>
                            <option key={5} value="nahrávač">nahrávač</option>


                        </select>
                    </div>


                    <ClenFotkyUpload isEdit={props.edit} idClen={props.idClen}></ClenFotkyUpload>


                </div>
                <div className="footer">
                    <div className="ctrl">
                        <button type="button" onClick={handleSubmit(onSubmit)} className="btn-save"><FontAwesomeIcon
                            icon={faSave} className="i"/>Uložit
                        </button>
                    </div>
                </div>

            </div>


        </div>

    )
}

export default NovyClen;