import axios from "axios";



export const EmailService = {
    GetDetailEmailHeader,
    DeleteEmailHeader
}

async function GetDetailEmailHeader (nazev, vytvoreno, alert) {

    let response = await axios.get('api/email/getDetailEmailHeader/' + nazev + '/' + vytvoreno).then(res => {
        return res;


    }).catch(err => {
        alert.error('Došlo k chybě na serveru.', {timeout: 0});
        console.log(err);
        return err;
    });

     return response;
}

async function DeleteEmailHeader(nazev, vytvoreno, alert) {
    try{
        let response = await axios.delete('api/email/deleteEmailHeader/' + nazev + '/' + vytvoreno);
        console.log(response);
        if(response.data.result === 1){
            alert.success(response.data.message);
        } else {
            alert.error(response.data.message, {timeout: 0});
        }
        return response;
    } catch(error){
        alert.error('Došlo k chybě na serveru.', {timeout: 0});
        console.log(error);
        return error;
    }


}



export default EmailService;