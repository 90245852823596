import React, {useEffect, useState, useCallback} from 'react';
import Time from "react-time-format";
import axios from "axios";
import './DochazkaTrenink.scss';
import {useAlert} from "react-alert";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";


const DochazkaTrenink = (props) => {

    const [info, setInfo] = useState([]);
    const [trenS, setTrenS] = useState([]);
    const [clenove, setClenove] = useState([]);
    const [poznamka, setPoznamka] = useState('');
    const [clenoveIds, setClenoveIds] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const alert = useAlert();



    const loadData = useCallback( async () => {

        let data = [];
        const axios1 = await axios.get('api/treninky/detailTrenink/' + props.forModal.id).then(res => {
            //console.log(res.data);
            data[1] = res.data;
        }).catch(err => {});


        const axios2 = axios.get('api/treninkoveSkup/detailTrenSkup/' + data[1].trens).then(res => {
            //console.log(res.data);
            data[2] = res.data;
            }).catch();

        const axios3 = axios.get('api/treninky/clenoveNaTreninku/' + props.forModal.id).then(res => {
            //console.log(res.data);
            data[3] = res.data;

        }).catch(err => {});

        const axios4 = axios.post('api/treninky/getDochazkaOnTrenink', {idTrenink: props.forModal.id, datum: props.forModal.datum} ).then(res => {
            //console.log(res.data);
            data[4] = res.data;


        }).catch(err => {});



        await Promise.all([axios1,axios2,axios3,axios4]).then(() => {
            setInfo(data[1]);
            setTrenS(data[2]);
            setClenove(data[3]);
            setPoznamka(data[4].poznamka);
            setClenoveIds(data[4].clenoveIds);
            setLoaded(true);
        });







    }, [props.forModal.datum, props.forModal.id])

    const onChangePoznamka = (e) => {
        setPoznamka(e.target.value);
    }

    const onChangeChckbx = (e) => {
        let id = parseInt(e.target.value);
        if(clenoveIds.includes(id)){
            for( let i = 0; i < clenoveIds.length; i++){
                if ( clenoveIds[i] === id) {
                    clenoveIds.splice(i, 1);
                }
            }
        } else {
            clenoveIds.push(id);
        }
    }

    const saveDochazka = () =>{
        let data = {
            idTrenink: props.forModal.id,
            datum: props.forModal.datum,
            poznamka: poznamka,
            clenoveIds: clenoveIds
        }

        axios.post('api/treninky/ulozDochazku', data ).then(res => {
            if(res.data.result === 1){
                alert.success(res.data.message);
                props.onClose();
            }else{
                alert.error(res.data.message);
            }

        }).catch(err => {

        });
    }

    useEffect(() => {
        if(!loaded){
            loadData();
        }
    },[loaded, loadData]);
    
    const renderDochazkaStav  = (clenToNadchTrenink) => {
        
        //here
        
        let dochazka = clenToNadchTrenink.filter(x=>moment(x.datum).format('YYYY-MM-DD') === moment(props.forModal.datum).format('YYYY-MM-DD'));
     
        if(dochazka.length === 0)
            return '-';
        
        let item = dochazka[0];
        
        let stav = '-';
     
        
        
        switch (item.stav){
            case 0:
                stav =  'Ano';
                break;
            case 1:
                stav = 'Ne';
                break;
            case 2:
                stav = 'Nevím';
                break;
            default:
                stav = '-';
                break;
        }
        
        return(
            <div className="dochazkaClen">
                <div className="stav">{stav}</div>
                <div className="poznamka">{item.poznamka}</div>
            </div>
        )
    }

    return(
        <div className={"dochazkaTrenink"}>
            <h1>{info.nazev} - {info.den} - <Time value={props.forModal.datum} format="DD.MM.YY" /></h1>
            {loaded ?
                <div className={"info"}>
                    <div className={"container"}>
                        <div className={"item"}>
                            <label>Id:</label>
                            <span className={"value"}>{info.id}   </span>
                        </div>
                        <div className={"item"}>
                            <label>Od:</label>
                            <span className={"value"}>{info.od.substring(0, info.od.length - 3)}  </span>
                        </div>
                        <div className={"item"}>
                            <label>Do:</label>
                            <span className={"value"}>{info.do.substring(0, info.od.length - 3)}</span>
                        </div>
                        <div className={"item"}>
                            <label>Tren. sk.:</label>
                            <span className={"value"}>{trenS.nazev}</span>
                        </div>
                    </div>
                </div>
                : null}


            <div className={"dochazka"}>
                <h2>Docházka</h2>

                <div className={"clenoveContainer"}>
                    {loaded && clenove.map((x,k) => {
                        return <div className={"item"} key={k}>
                            
                            <div className={"item2 checkbox"}>
                                <input value={x.idClen} onChange={onChangeChckbx} type={'checkbox'} defaultChecked={clenoveIds.includes(x.idClen) ? true : false}  />
                            </div>
                            <div data-id={x.idClen} className={"item2 jmeno"}>
                                {x.jmeno + ' ' + x.prijmeni + ' ' + x.datumNar}
                               
                            </div>
                            <div className="item2 dochazkaClen">{renderDochazkaStav(x.clenToNadchTrenink)}</div>
                            
                            <div className="item2">
                                {!x.zaplacenyAktualniRok && <span title={"Uživatel nemá evidovanou platbu pro aktuální rok."}><FontAwesomeIcon icon={faExclamation} className="i nezaplaceno" /><span style={{fontSize: 13}}>Člen nemá evidovanou platbu za členský příspěvek pro aktuální rok</span></span>}
                            </div>
                        </div>
                    })}
                </div>


                <div className={"container"}>
                    <div className={"item poznamka"}>
                        <label>Poznámka</label>
                        <textarea defaultValue={poznamka} onChange={onChangePoznamka} maxLength={2000} className={"textarea1"}></textarea>
                    </div>
                </div>
                <div className={"ctrl"}>
                    <button onClick={saveDochazka} className={"btn-save"}>Uložit docházku</button>
                </div>

            </div>





        </div>
    )

}

export default DochazkaTrenink;