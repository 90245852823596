import React from 'react';
import ReactDom from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import './Modal.scss';

const Modal = (props) => {



    if (props.isOpen) {

        return ReactDom.createPortal(
            <div className={"modal-wrapper"}>
                <div onClick={props.onClose} className={"modal-backdrop"}/>
                <div className={"close"}><button onClick={props.onClose}><FontAwesomeIcon icon={faTimes} /></button></div>
                <div className={"modal-box"}>

                    {props.children}
                </div>
            </div>, document.getElementById("modal-root"))
    }

    return null;
}

export default Modal;