import React, {useState, useEffect} from 'react';
import {faEdit, faPlusSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from 'react-router-dom';
import DataTable from "react-data-table-component";
import DataTableStyles from "../shared/DataTableStyle";
import axios from "axios";
import Modal from "../shared/Modal";
import NovinkyAddUpdate from "./NovinkyAddUpdate";
import BackToDashboard from "../shared/BackToDashboard";

const Novinky = () => {

    const [novinky, setNovinky] = useState([]);
    const [loaded, setLoaded] = useState(false)
    const [idNovinka, setIdNovinka] = useState(0);
    const [tagModalOpen, setTagModalOpen] = useState(false);

    const columns = (clickHandler => [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
        },
        {
            name: 'Titulek',
            selector: 'titulek',
            sortable: true,
        },
        {
            cell: (row) => <button className="btn-table-edit" onClick={openModal} id={row.id}><FontAwesomeIcon
                icon={faEdit}/> Upravit</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]);

    const openModal = (e) => {

        let idNovinka = e.target.id;
        setIdNovinka(idNovinka);
        setTagModalOpen(true);
        //modalRef.current.openModal();
    };

    useEffect(() => {

        const  load = async () => {
            await  axios.get('/novinky/vse').then(res => {
                setNovinky(res.data);
            });
        }

        if (!loaded) {
            load().then(() => {
                setLoaded(true);
            });
           }
    }, [loaded]);





    const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false
    });

    const handleButtonClick = (state) => {

    };

    return (
        <div>
            <h1><BackToDashboard />Novinky</h1>
            <div className={"subMenu"}>
                <Link className={"item"} to={"/novinky/pridat"}><FontAwesomeIcon icon={faPlusSquare} className="i"/>Přidat
                    novinku</Link>
            </div>

            <DataTable
                title="Výpis novinek"

                data={novinky}
                columns={columns(handleButtonClick)}
                paginationComponentOptions={pagination}
                pagination
                dense
                customStyles={DataTableStyles}
            />

            <Modal isOpen={tagModalOpen} onClose={() => {setTagModalOpen(false);}}>
                <NovinkyAddUpdate onClose={() => {setTagModalOpen(false); setLoaded(false);}} id={idNovinka} edit={true} />
            </Modal>

        </div>
    )

}
export default Novinky;