import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import  { authenticationService }  from '../../helpers/AuthenticationService';

export const PrivateRoute = ({ component: Component, trener,  admin, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        
        //console.log(currentUser);
        //console.log('trener=' + admin);
        
        
        if (!currentUser) {

            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
        

        if(trener && (!currentUser.trener && !currentUser.assistentTrenera)){
            return (<Redirect to={{ pathname: '/notAllowed', state: { from: props.location } }} />)
        }

        if(admin && !currentUser.admin){
            return (<Redirect to={{ pathname: '/notAllowed', state: { from: props.location } }} />)
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)