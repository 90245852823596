import React, {useEffect, useState} from 'react';
import {faCalendarDay} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import AkceItem from "../akce-admin/AkceItem";
import "../akce-admin/Akce.scss";
import Modal from "../shared/Modal";
import NovaEditAkce from "../akce-admin/NovaEditAkce";
import {useAlert} from "react-alert";


const Akce = () => {

    const [akce, setAkce] = useState([]);
    const [jaToAkce, setJaToAkce] = useState([]);
    const [id, setId] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const [loaded, setLoaded] = useState(false);

    const alert = useAlert();



    const handleEdit = (idAkce, stav, poznamka) => {
        let data = {
            idAkce: idAkce,
            stav: stav,
            poznamka: poznamka
        }

        axios.post('api/akce/clenNaAkci', data).then(res => {
            if(res.data.result === 1){
                alert.success(res.data.message);
            } else{
                alert.error(res.data.message, {timeout: 0});
                console.log(res.data.exception);
            }
        }).catch(err => {
            alert.error("Došlo k chybě na serveru", {timeout: 0});
            console.log(err);
        });
    }




    useEffect(() => {

        const loadData = () => {

            axios.get('api/akce/getMojeAkce').then(res => {

                //console.log(res.data);
                setAkce(res.data.akce);
                setJaToAkce(res.data.jaToAkce);
                setLoaded(true);
            }).catch(err => {
                console.log(err);
            });

        }
        if (!loaded){
            loadData();
        }

    }, [loaded]);


    return(

        <div>

            <h1><FontAwesomeIcon className={"i"} icon={faCalendarDay} /> Nadcházející akce</h1>

            <div className={"akceCont"}>
                {loaded && akce && akce.map((x,k)=>{
                    return (


                        <AkceItem handleEdit={handleEdit} treninkData={jaToAkce} clen={true} setId={setId} data={x} key={k}  />

                    )
                })}
            </div>

            <Modal isOpen={modalOpen}  onClose={()=>{setModalOpen(false);}}><NovaEditAkce id={id}  edit={true} onClose={()=>{setModalOpen(false); setLoaded(false);}} /></Modal>



        </div>

    )
}

export default Akce;