import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft, faSave} from '@fortawesome/free-solid-svg-icons';
import InputError from "../shared/InputError";
import messages from "../shared/InputErrorMessages";
import {useForm} from "react-hook-form";
import axios from "axios";
import {Link} from "react-router-dom";
import {EditorState,convertToRaw,ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../editor.scss';
import SelectTypAkce from "./SelectTypAkce";
import {useAlert} from "react-alert";
import Select from "react-select";


const NovaEditAkce = (props) => {

    const {register, handleSubmit, errors, setValue} = useForm({
        defaultValues: {nazev: "", den: "po", od: "08:00", do: "09:00", trens: 0, kpopis: "",}
    });

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState([]);
    const [aktivni, setAktivni] = useState(1);
    const [verejna, setVerejna] = useState(0);
    const [zpoplatnena, setZpoplatnena] = useState(false);
    const [castka, setCastka] = useState(0);
    const [typ, setTyp] = useState('ostatni');
    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty(),
    );
    const [loaded, setLoaded] = useState(false);
    const alert = useAlert();

    const handleWyswyg = (a) => {
        setEditorState(a);
    }

    const handleChangeSelect = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };





    const onSubmit = (data) => {
        if( selectedOptions === null || selectedOptions.length === 0){
            alert.error("Výběr tréninkových skupin nemůže být prázdný", {timeout:0});
            return false;
        }
        data.dpopis = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        data.trens = selectedOptions;
        data.zpoplatnena = zpoplatnena;
        data.castka = castka;
        if(props.edit){


            axios.post('api/akce/editAkce', data).then(res => {
                //this.props.alert.success('Produkt byl přidán');
                if(res.data.result === 1){
                    alert.success(res.data.message);
                    props.onClose();
                }else{
                    alert.error(res.data.message, {timeout:0});
                    console.log(res.data.exception)
                }


            }).catch(err => {
                alert.error("Chyba na serveru", {timeout:0});
                console.log(err)
            });


        }else{


            axios.post('api/akce/ulozAkci', data).then(res => {
                //this.props.alert.success('Produkt byl přidán');
                if(res.data.result === 1){
                    alert.success(res.data.message);
                }else{
                    alert.error(res.data.message, {timeout:0});
                    console.log(res.data.exception)
                }


            }).catch(err => {
                alert.error("Chyba na serveru", {timeout:0});
                console.log(err)
            });
        }


    }



    useEffect(() => {

        const loadData = () => {


            axios.get('api/treninkoveSkup/getAllActiveSelect').then(res => {
                setOptions(res.data);
            }).catch(err => {

            });

            if(props.edit){
                axios.get('api/akce/detailAkce/' + props.id).then(res => {
                    console.log(res.data);
                    setValue("id", props.id);
                    setValue("nazev", res.data.nazev);

                    setValue("od", res.data.od.substring(0, res.data.od.length-9));
                    setValue("do", res.data.do.substring(0, res.data.do.length-9));
                    setValue("kpopis", res.data.kpopis);
                    setValue("headPhotoUrl", res.data.headPhotoUrl);
                    setAktivni(res.data.aktivni);
                    setVerejna(res.data.verejna);
                    setTyp(res.data.typ)
                    const contentBlock = htmlToDraft(res.data.dpopis);
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    setEditorState(() => EditorState.createWithContent(contentState));
                    setSelectedOptions(res.data.trenS);
                    if(res.data.zpoplatnena){
                        setZpoplatnena(res.data.zpoplatnena);
                        setCastka(res.data.castka);
                    }





                }).then(() => {
                    setLoaded(true);
                }).catch(err => {

                });
            } else {
                setLoaded(true);
            }



        }

        loadData();

    }, [props.edit,props.id,setValue]);



    return(


            <div>

                {props.edit ?
                    <div><h1>Editace akce</h1></div>
                    :
                    <div>
                        <h1>Nová akce</h1>
                        <div className="breadCrumb">
                            <div className="item"> <Link to="/akce">Akce</Link></div>
                            <div className="item"><FontAwesomeIcon icon={faChevronLeft} className="i" /></div>
                            <div className="item">Nová akce</div>
                        </div>
                    </div>

                }

                {loaded ?
                <div>
                    <label style={{fontSize: "12pt"}}>Vybrat tréninkové skupiny</label>

                    <Select
                        value={selectedOptions}
                        closeMenuOnSelect={true}
                        defaultValue={[]}
                        isMulti
                        options={options}
                        onChange={handleChangeSelect}


                    />

                </div>
                : null}

                <div className="form">
                    <div className="header">

                    </div>



                        <div className="body">
                            {props.edit ?
                                <div className="item">
                                    <label>Id*</label>
                                    <input type="text" readOnly={"readonly"} name="id"  ref={register()}/>

                                </div>
                                : null}
                            <div className="item">
                                <label>Název akce*</label>
                                <input type="text" name="nazev" maxLength="100" ref={register({required: true})}/>
                                {errors.nazev &&
                                <InputError message={messages.required}></InputError>}
                            </div>

                            <div className="item">
                                <label>Od*</label>
                                <input type="date" name="od" ref={register({required: true})} ></input>
                                {errors.od &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Do*</label>
                                <input type="date" name="do" ref={register({required: true})}></input>
                                {errors.do &&
                                <InputError message={messages.required}></InputError>}
                            </div>


                            {loaded ?
                            <div className="item">
                                <label>Typ</label>
                                <SelectTypAkce default={typ} name={'typ'} reff={register()} />

                            </div>
                                : null}
                            {loaded ? <div className="item">
                                <label>Aktivní</label>
                                <select name={"aktivni"} defaultValue={aktivni} ref={register()} >
                                    <option value={1}>Ano</option>
                                    <option value={0}>Ne</option>
                                </select>

                            </div> : null }
                            {loaded ? <div className="item">
                                <label>Verejna</label>
                                <select name={"verejna"} defaultValue={verejna} ref={register()} >
                                    <option value={1}>Ano</option>
                                    <option value={0}>Ne</option>
                                </select>

                            </div> : null }
                            <div className={"item"}>
                                <label>Zpoplatněná</label>
                                <input type={"checkbox"} checked={zpoplatnena} onChange={() => setZpoplatnena(!zpoplatnena)}/>
                                
                            </div>
                            { zpoplatnena ?
                                <div className={"item"}>
                                    <label>Částka</label>
                                    <input type={"number"} value={castka} onChange={(e) => setCastka(e.target.value)} />

                                </div>
                            : null}
                            <div className={"item"}>
                                <label>Náhledová fotka - url</label>
                                <input type={"text"} name={"headPhotoUrl"} maxLength="1500"
                                       ref={register()}/>
                            </div>
                            <div className="item">
                                <label>Krátký popis</label>
                                <textarea name={"kpopis"} maxLength={"400"} ref={register()}></textarea>

                            </div>
                            <div className="item">
                                <label>Dlouhý popis</label>
                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={handleWyswyg}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    toolbar={{
                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker','emoji', 'image', 'link'],
                                        inline: {
                                            inDropdown: false,
                                            className: undefined,
                                            component: undefined,
                                            dropdownClassName: undefined,
                                            options: ['bold', 'italic', 'underline', 'strikethrough']
                                        },
                                    }}
                                />

                            </div>
                        </div>








                    <div className="footer">
                        <div className="ctrl">
                            <button type="button" onClick={handleSubmit(onSubmit)} className="item btn-save"><FontAwesomeIcon icon={faSave} className="i" />Uložit</button>

                        </div>
                    </div>
                </div>




            </div>




    )
}

export default NovaEditAkce;