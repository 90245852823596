import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import InputError from "../shared/InputError";
import messages from "../shared/InputErrorMessages";
import {useForm} from "react-hook-form";
import {useAlert} from "react-alert";
import axios from "axios";
import Time from "react-time-format";


const NastaveniWebu = () => {

    
    const [loaded, setLoaded] = useState(false);
    const {register, handleSubmit, errors, setValue} = useForm({
        defaultValues: {
            cisloUctu: '',
            iban: '',
            replyTo: '',
            publicWebUrl: '',
            invoiceDueDate: 0
        }
    });
    const [nastaveni ] = useState({});

    const alert = useAlert();


    useEffect( ()  => {

        const loadData = async () => {
            await axios.get('/settings').then(res => {
                setValue("cisloUctu", res.data.cisloUctu);
                setValue("iban", res.data.iban);
                setValue("replyTo", res.data.replyTo);
                setValue('publicWebUrl', res.data.publicWebUrl);
                setValue('invoiceDueDate', res.data.invoiceDueDate);
            });
        }

        if(!loaded) {
            loadData();
            setLoaded(true);
        }
    }, [loaded]);


    const onSubmit = (data) => {

        axios.post('/settings/update', data).then(() => {
            alert.success("Nastavení bylo uloženo");

        }).catch(() => {
            alert.error("Během ukládání došlo k chybě.", {timeout: 0});
        });

    }



    return(
        <div>

            <h1>Nastavení webu</h1>
            <div className={"nastaveni"}>
                <div className={"user"}>
                    <div className={"form"}>
                        <div className={"body"}>

                            <div className="item">
                                <label>Číslo účtu*</label>
                                <input type="text" maxLength="50" name="cisloUctu" ref={register({required: true})}/>
                                {errors.cisloUctu &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>IBAN*</label>
                                <input type="text" name="iban" maxLength="50" ref={register({required: true})}/>
                                {errors.iban &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Email - odpověď*</label>
                                <input type="text" name="replyTo" maxLength="50" ref={register({required: true})}/>
                                {errors.replyTo &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Url veřejných stránek*</label>
                                <input type="text" name="publicWebUrl" maxLength="50" ref={register({required: true})}/>
                                {errors.publicWebUrl &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className={"item"}>
                                <label>Splatnost (dny): </label>
                                <input type={"number"} name={"invoiceDueDate"} ref={register({required: true})}/>
                                {errors.invoiceDueDate &&
                                    <InputError message={messages.required}></InputError>}
                            </div>
                        </div>
                        <div className="footer">
                            <div className="ctrl">
                                <button type="button"  className="btn-save" onClick={handleSubmit(onSubmit)}><FontAwesomeIcon
                                    icon={faSave} className="i"/>Uložit
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {nastaveni.user ?
                <div className={"byAdmin"}>
                    <div className={"form"}>
                        <div className={"header"}>
                            <h2>Ostatní</h2>
                        </div>
                        <div className={"body"}>
                            <div className={"item"}>
                                <label>Tréninková skupina</label>
                                <label className={"value"}>{nastaveni.trenSkupina != null ? nastaveni.trenSkupina.nazev : null}</label>
                            </div>

                            <div className="item">
                                <label>Admin práva</label>
                                <label className={"value"}>{nastaveni.user.admin ? "ANO" : "NE"}</label>

                            </div>
                            <div className="item">
                                <label>Role</label>

                                <label className={"value"}>
                                    {nastaveni.user.role === "clen" ? "Člen" : null }
                                    {nastaveni.user.role === "neclen" ? "Nečlenský účet" : null }
                                </label>

                            </div>
                            <div className="item">
                                <label>Stav</label>

                                <label className={"value"}>
                                    {nastaveni.user.stav === 1 ? "Nový" : null }
                                    {nastaveni.user.stav === 2 ? "Aktivní" : null }
                                    {nastaveni.user.stav === -1 ? "Neaktivní" : null }
                                </label>

                            </div>
                            <div className="item">
                                <label>Chci vystavit potvrzení na zaplacení členských příspěvků?</label>
                                <label className={"value"}>{nastaveni.user.vystavitpotvr ? "ANO" : "NE" }</label>

                            </div>
                            <div className="item">
                                <label>Souhlasím s uveřejněním svého rodného čísla pro účely MŠMT a Volejbalového informačního systému (VIS). Souhlasím se zpracováním osobních údajů pro účely volejbalového klubu VK DK z.s.
                                </label>
                                <label className={"value"}>{nastaveni.user.souhlas ? "ANO" : "NE" }</label>

                            </div>
                            <div className="item">
                                <label>Platnost registračního průkazu
                                </label>
                                <label className={"value"}><Time value={nastaveni.user.platRegPruk  } format="DD.MM.YYYY" /></label>

                            </div>


                        </div>
                    </div>
                </div>
                :
                null}

        </div>



    )
}

export default NastaveniWebu;