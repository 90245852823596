import React, {useEffect, useState} from 'react';
import {faMoneyBillWave, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import "./Platby.scss";
import Moment from "react-moment";
import {Page, Text, View, Document, StyleSheet, BlobProvider, Image, Font} from '@react-pdf/renderer';
import font from '../../styles/static/OpenSans/OpenSans-Regular.ttf';
import font_bold from '../../styles/static/OpenSans/OpenSans-Bold.ttf';





const Platby = () => {

    const [platby, setPlatby] = useState([]);
    const [clen, setClen] = useState({jmeno: "", prijemni:"", rc: "", datumNarozeni: ""});

    const [loaded, setLoaded] = useState(false);

    const [settings, setSettings] = useState(false);



    Font.register({ family: 'Open Sans', src: font })
    Font.register({ family: 'Open Sans Bold', src: font_bold })


    // Create styles
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#fff',
            fontFamily: "Open Sans",
            fontSize: 12,
            padding: 15
        },
        section: {
            margin: 5,
            padding: 5,
           
        },
       
        header: {
            fontFamily: "Open Sans Bold",
        },
        image: {
            margin: 5,
            padding: 5,
            width: 100
        },
        razitko: {
            margin: 5,
            padding: 5,
            width: 180
        }
       
    });

    // Create Document Component
    const MyDocument = (props) => {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <View style={styles.header}>
                            <Text>Potvrzení o úhradě pravidelné pohybové aktivity dítěte</Text>
                        </View>
                    </View>
                    
                    <View style={{display: "flex", flexDirection: "row", marginTop: 5, marginBottom: 25}}>
                        <View style={styles.image}>
                            <Image src="vkdk_logo_1.jpg" />
                        </View>
                        <View style={styles.section}>
                            <View style={styles.header}>
                                <Text>Název organizátora sportovní aktivity:</Text>
                            </View>
                            <View>
                                <Text >Volejbalový klub VK Dvůr Králové nad Labem, z.s.</Text>
                                <Text>Okružní 2319</Text>
                                <Text>544 01 Dvůr Králové nad Labem</Text>
                                <Text>IČ: 01666363</Text>
                            </View>
                        </View>
                        
                    </View>
                    
                    <View style={styles.section}>
                        <View style={styles.header}>
                            <Text>Jméno a příjmení dítěte: {clen.jmeno} {clen.prijemni}</Text>
                            <Text>RČ: {clen.rc}</Text>
                            <Text>Datum narození: {clen.datumNarozeni}</Text>
                        </View>
                    </View>
                    <View style={styles.section}>
                        <Text>Potvrzujeme přijetí platby za pravidelnou pohybovou aktivitu výše uvedeného dítěte.</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Částka: {props.castka} Kč</Text>
                        <Text>Datum úhrady: <Moment format="DD.MM.YYYY" date={props.datumPlatba}/></Text>
                        <Text>Způsob úhrady: převodem BÚ </Text>
                        <Text>Druh aktivity: členství ve sportovním klubu VK DK</Text>
                        <Text>Období: 1.9.{props.zacatekSezonyRok} - 30.8.{parseInt(props.zacatekSezonyRok) + 1}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>Potvrzení vydal dne: <Moment format="DD.MM.YYYY" date={props.datumPlatba}/></Text>
                        <Text>Ing. Zdeněk Havel</Text>
                        <Text>Předseda VK DK</Text>
                    </View>
                    <View style={styles.section}>
                        <View style={styles.razitko}>
                            <Image src="razitko.jpg" />
                        </View>
                    </View>
                    
                   
                    
                </Page>
            </Document>
        )
    };


    useEffect(() => {

        const loadData = async () => {

            await axios.get('api/platby/getMojePlatby').then(res => {

                //console.log(res.data);
                setPlatby(res.data.data);
                setClen(res.data.clen);

            }).catch(err => {
                console.log(err);
            });
            await axios.get('/settings').then(res => {

                //console.log(res.data);
                setSettings(res.data);

            }).catch(err => {
                console.log(err);
            });

        }
        if (!loaded) {
            loadData().then(setLoaded(true));
        }

    }, [loaded]);


    return (

        <div>
            <h1><FontAwesomeIcon className={"i"} icon={faMoneyBillWave}/> Platby</h1>
            <h2 style={{backgroundColor: "#fff", padding: 15, borderRadius: 5, width: "fit-content"}}>Výpis Vašich posledních 20ti plateb</h2>
            {loaded && settings ?
                <h3 style={{backgroundColor: "#fff", padding: 12, borderRadius: 5, width: "fit-content"}}>Číslo účtu: <span style={{fontWeight: "bold"}}>{settings.cisloUctu}</span></h3>
                : null}
            <div>
                <div style={{backgroundColor: "#fff", padding: 10, borderRadius: 5, width: "fit-content"}}>Člen: {clen.jmeno} {clen.prijemni}</div>
            </div>
            <div className={"platbyCont"}>
                {loaded && platby.length === 0 ? <h2>U Vašeho účtu neevidujeme žádné platby</h2>
                    :
                    platby.map((x) => {
                        return (
                            <div className={"item"} key={x.id}>
                                <div className="item2">
                                    <span className={"item3"}>
                                        <span className={"key"}>Stav</span>
                                        <span className={"value"}>
                                            {x.stav === 1 ? "Nezaplaceno" : null}
                                            {x.stav === 2 ? "Zaplaceno" : null}
                                            {x.stav === 0 ? "Storno" : null}
                                        </span>
                                    </span>
                                    {x.stav === 2 && x.platbaZa === 1 ?
                                    <span className="item3">
                                        
                                            <BlobProvider document={MyDocument(x)}>
                                                {({url}) => {
                                                    // Do whatever you need with blob here
                                                    return(
                                                        <a href={(url != null) ? url : ""} target="_blank" rel="noopener noreferrer" className="pdfDown">
                                                            <FontAwesomeIcon icon={faFilePdf} className="i" />Stáhnout PDF
                                                        </a>
                                                       );
                                                }}
                                            </BlobProvider>
                                    </span>
                                            : null
                                        }
                                    
                                </div>
                                
                                <div className="item2">
                                    <span className={"item3"}>
                                        <span className={"key"}>Id:</span>
                                        <span className={"value"}>{x.id}</span>
                                    </span>
                                    <span className={"item3"}>
                                        <span className={"key"}>Částka</span>
                                        <span className={"value"}>{x.castka} Kč</span>
                                    </span>
                                    <span className={"item3"}>
                                        <span className={"key"}>Var. symbol</span>
                                        <span className={"value"}>{x.variabilniSymbol}</span>
                                    </span>
                                   
                                    <span className={"item3"}>
                                        <span className={"key"}>Platba za</span>
                                        <span className={"value"}>
                                            {x.platbaZa === 1 ? "Členský poplatek" : null}
                                            {x.platbaZa === 2 ? "Akci (" + x.akce.nazev + ")" : null}
                                            {x.platbaZa === 0 ? "Ostatní" : null}
                                        </span>
                                    </span>
                                    
                                </div>
                                <div className="item2">
                                    
                                     <span className={"item3"}>
                                        <span className={"key"}>Vytvořeno</span>
                                        <span className={"value"}><Moment format="DD.MM.YYYY" date={x.datumVytvoreni}/></span>
                                    </span>
                                    
                                    <span className={"item3"}>
                                        <span className={"key"}>Splatnost</span>
                                        <span className={"value"}><Moment format="DD.MM.YYYY" date={x.splatnostDo}/></span>
                                    </span>
                                    {x.datumPlatba ?
                                    <span className={"item3"}>
                                        
                                            <React.Fragment>
                                                <span className={"key"}>Platba</span>
                                                <span className={"value"}>
                                                    <Moment format="DD.MM.YYYY" date={x.datumPlatba}/>
                                                </span>
                                            </React.Fragment>
                                     </span>
                                        : null}
                                   
                                </div>
                                <div className="item2">
                                    
                                    
                                </div>
                            </div>
                        )
                    })}

            </div>
            


        </div>

    )
}

export default Platby;