import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './Menu.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEllipsisV, faTimes} from '@fortawesome/free-solid-svg-icons'
import {authenticationService} from '../../helpers/AuthenticationService';

const Menu = () => {

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const [currentUser, setCurrentUser] = useState(false);


    useEffect(() => {
        authenticationService.currentUser.subscribe(x => setCurrentUser(x));

    });



    const closeMenu = () => {
        setIsMenuVisible(false);
    }

    const showMenu = () => {
        setIsMenuVisible(true);
    }

    const logout = (e) => {
        authenticationService.logout();
    }


    return (
        <div>
            {
                (isMenuVisible && localStorage.getItem("currentUser") !== null) ?
                    <menu>
                        <div className="header">
                            MENU
                        </div>
                        <div className="content">

                            {/*  DASHBOARD   */}
                            <div className={"group"}>
                                <div className={"header2"}>
                                    Dashboard
                                </div>
                                <div className={"content2"}>
                                    <Link to="/dashboard" onClick={closeMenu} className="item">Dashboard</Link>
                                </div>
                            </div>

                            {/*  CLEN   */}
                            <div className={"group"}>
                                <div className={"header2"}>
                                    Člen
                                </div>
                                <div className={"content2"}>
                                    <Link to="/treninky-clen" onClick={closeMenu} className="item">Tréninky</Link>
                                </div>
                                <div className={"content2"}>
                                    <Link to="/akce-clen" onClick={closeMenu} className="item">Akce</Link>
                                </div>
                                <div className={"content2"}>
                                    <Link to="/platby-clen" onClick={closeMenu} className="item">Platby</Link>
                                </div>
                                <div className={"content2"}>
                                    <Link to="/dok" onClick={closeMenu} className="item">Dokumenty</Link>
                                </div>
                            </div>

                            {/*  TRENER   */}
                            {(currentUser.trener || currentUser.asistentTrenera ) ?
                                <div className={"group"}>
                                    <div className={"header2"}>
                                        Trenér
                                    </div>
                                    <div className={"content2"}>
                                        <Link to="/treninky-trener" onClick={closeMenu} className="item">Tréninky</Link>

                                    </div>
                                </div>

                            :
                            null}



                            {/*  ADMIN   */}
                            {currentUser.admin
                                ?
                                <div className={"group"}>
                                    <div className={"header2"}>
                                        Admin
                                    </div>
                                    <div className={"content2"}>
                                        <Link to="/clenove" onClick={closeMenu} className="item">Členové</Link>
                                        <Link to="/treninkoveSkupiny" onClick={closeMenu} className="item">Tréninkové skupiny</Link>
                                        <Link to="/treninky-admin" onClick={closeMenu} className="item">Tréninky</Link>
                                        <Link to="/akce" onClick={closeMenu} className="item">Akce</Link>
                                        <Link to={"/novinky"} onClick={closeMenu} className={"item"}>Novinky</Link>
                                        <Link to="/finance" onClick={closeMenu} className="item">Finance</Link>
                                        <Link to="/dok" onClick={closeMenu} className="item">Dokumenty</Link>
                                        <Link to={"/emaily"} onClick={closeMenu} className={"item"}>Emaily</Link>
                                        <Link to={"/nastaveni-webu"} onClick={closeMenu} className={"item"}>Nastavení webu</Link>
                                        <Link to={"/carousel"} onClick={closeMenu} className={"item"}>Fotky na hlavní strance</Link>
                                    </div>

                                </div>
                                : null}
                            <div className={"group"}>
                                <div className={"header2"}>
                                    Účet: { currentUser && (currentUser.jmeno + ' ' + currentUser.prijmeni)} (Id: {currentUser && currentUser.id}) 
                                </div>
                                <div className={"content2"}>
                                    <Link to={"/nastaveni"} onClick={closeMenu} className={"item"}>Nastavení</Link>
                                    <Link className="item" onClick={e => {logout(e); closeMenu()}} to='/'>Odhlásit</Link>
                                </div>
                            </div>

                        </div>
                        <button className="close" onClick={closeMenu}><FontAwesomeIcon icon={faTimes}/></button>
                    </menu>

                    : <div id="showMenuButt" onClick={showMenu}><FontAwesomeIcon icon={faEllipsisV}/></div>
            }
        </div>

    )

};

export default Menu;