import React from 'react';
import Treninky from './Treninky';
import '../Dashboard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBasketballBall} from '@fortawesome/free-solid-svg-icons';

const TrenerDashboard = () => {






    return(
        <div>
            <h1>Trenér</h1>
            <div className={"dashBlocksContainer"}>
                <div className={"dashBlock-M"}>
                    <h1><FontAwesomeIcon icon={faBasketballBall} className={"i"} />Nadcházející tréninky</h1>
                        <Treninky />

                </div>

            </div>


        </div>
    )
}

export default TrenerDashboard;