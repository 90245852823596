import React, {useEffect, useState} from 'react';
import axios from "axios";
import Time from "react-time-format";
import TreninkItem from "../../treninky-admin/TreninkItem";
import './Treninky.scss';

const Treninky = () => {

    const [days, setDays] = useState([]);
    const [treninky, setTreninky] = useState([]);
    const [loaded, setLoaded] = useState(false);

    let cyklusDatum = '';
    const setCyklusDatum = (x) => {
        cyklusDatum = x;
    }

    const handleEdit = (e) => {


    };

    const loadData = () =>{


        axios.get('api/treninky/nadchazejiciDny').then(res => {
            //console.log(res.data);
            setDays(res.data);
            axios.get('api/treninky/getTreninkyByTrener').then(res => {
                //console.log('treninky');
                //console.log(res.data);
                setTreninky(res.data);
                setLoaded(true);
            }).catch(err => {

            });
        }).catch(err => {

        });


    }

    useEffect(() => {

        loadData();

    }, []);

    return(
        <div className={"treninkyVypis"}>
            {loaded && days.length > 0 && treninky.length > 0 && days.map((x) => {
                return treninky.map((x2) => {

                    return x2.den === x.denVtydnu ?
                        <div className={"item"} key={x2.id} data-date={x.datum}>

                            {cyklusDatum !== x.datum ? <h2>{x.denVtydnu} <Time value={x.datum} format="DD.MM.YY" /></h2> : null}
                            <TreninkItem   data={x2} den={x.denVtydnu}  handleEdit={handleEdit} trenerDashboard={true} datum={x.datum} ></TreninkItem>
                            { setCyklusDatum(x.datum)}
                        </div>
                        : null
                })
            })}
        </div>
    )
}

export default Treninky;