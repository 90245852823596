import React,{useEffect, useState} from 'react';
import axios from "axios";
import TreninkItem from "../treninky-admin/TreninkItem";
import Time from "react-time-format";
import {useAlert} from "react-alert";
import {faBasketballBall} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";



const Treninky = () =>{
    const [days, setDays] = useState([]);
    const [treninky, setTreninky] = useState([]);
    const [myTren, setMyTren] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const alert = useAlert();

    let cyklusDatum = '';
    const setCyklusDatum = (x) => {
        cyklusDatum = x;
    }

    const handleEdit = (idTrenink, datum, stav, poznamka) => {
        let data = {
            idTrenink: idTrenink,
            datum: datum,
            stav: stav,
            poznamka: poznamka
        }
        //console.log(data);
        axios.post('api/treninky/clenNaNadchazejiciTrenAction', data).then(res => {
            //console.log(res.data);
            if(res.data.result === 1){
                alert.success(res.data.message);
            } else{
                alert.error(res.data.message, {timeout: 0});
            }
        }).catch(err => {

        });
    }





    useEffect(() => {



            axios.get('api/treninky/nadchazejiciDny').then(res => {
                setDays(res.data);
                axios.get('api/treninky/mojeTreninky').then(res => {
                    setTreninky(res.data);
                    axios.get('api/treninky/mojeNadchazejiciTren').then(res => {
                        setMyTren(res.data);
                        setLoaded(true)

                    }).catch(err => {

                    });

                }).catch(err => {

                });
            }).catch(err => {

            });






    }, []);

    return(
        <div className={"nadchazejiciTren"}>
            <h1><FontAwesomeIcon icon={faBasketballBall} className={"i"} />Moje nadcházející tréninky</h1>
            {treninky.length === 0 ?
                <h3>Nejste přiřazen na žádný trénink</h3>
            :
                <div className={"treninkyVypis"}>
                    {loaded && days.map((x,k) => {
                        return treninky.map((x2, k2) => {
                            return x2.den === x.denVtydnu ?
                                <div className={"item"} key={"A" + k2} data-date={x.datum}>
                                    {cyklusDatum !== x.datum ? <h2>{x.denVtydnu} <Time value={x.datum} format="DD.MM.YY" /></h2> : null}
                                    <TreninkItem key={x2.id} clen={true} data={x2} den={x.denVtydnu} handleEdit={handleEdit} datum={x.datum} treninkData={myTren.filter(f => f.idTrenink === x2.id && f.datum.substring(0, 10) === x.datum)}></TreninkItem>
                                    { setCyklusDatum(x.datum)}
                                </div>
                                : null
                        })
                    })}
                </div>
            }



        </div>
    )
}

export default Treninky;