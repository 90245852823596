import React, {useState} from 'react';
import Moment from "react-moment";
import {faCamera, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import Modal from "../../shared/Modal";


const NovinkyItem = (props) => {

    const [showDetail, setShowDetail] = useState(false);


    return(
        <div className={"item"}>
            <div className="cont1">
                { props.data.headPhotoUrl ? <img className="headImg" alt="novnka" src={props.data.headPhotoUrl} /> : <FontAwesomeIcon  icon={faCamera} className={"noPhoto"} /> }
            </div>
            <div className="cont2">

                <span className={"date"}><FontAwesomeIcon onClick={()=>{setShowDetail(!showDetail)}} icon={faInfoCircle} className={"i"} /><Moment format="DD.MM.YYYY" date={props.data.vytvoreno} /></span>
                <span className={"titulek"}>{props.data.titulek}</span>
                
            </div>
            
           

            <Modal isOpen={showDetail}  onClose={()=>{setShowDetail(false);}}>
                <div className={"modalNovinka"}>
                    <div className="uvod">
                        <div className="img"><img alt={props.data.titulek} src={props.data.headPhotoUrl} /></div>
                        <div className="uvodText">{props.data.uvod}</div>
                    </div>
                    <div className={"dpopis"}>
                        {parse(props.data.zprava)}
                    </div>

                </div>
            </Modal>




        </div>
    )
}

export default NovinkyItem;