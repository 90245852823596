import axios from "axios";



export const ClenService = {
    EditNastaveni,
    GetAllNoveCLeny,
    GetAllOnTrenS
}

function EditNastaveni (data, alert) {
    axios.post('api/clenove/editNastaveni', data).then(res => {
        //this.props.alert.success('Produkt byl přidán');
        if (res.data.result > 0) {
            alert.success(res.data.message);

        } else {
            alert.error(res.data.message, {timeout: 0});
            console.log(res.data.exception);
        }


    }).catch(err => {
        alert.error('Došlo k chybě na serveru.', {timeout: 0});
        console.log(err);
    });
}

async function GetAllNoveCLeny(alert){
    let response = await axios.get('api/clenove/filtr?stav=1&pouzeAktivni=false').then(res => {
        return res;

    }).catch(err => {
        alert.error('Nepovedlo se načíst nové členy.', {timeout: 0});
        console.log(err);
        return [];


    });

    return response;
}

async function GetAllOnTrenS(trenS,alert){
    let response = await axios.get('api/clenove/filtr?pouzeAktivni=true&trenS=' + trenS).then(res => {
        console.log(res);
        return res;


    }).catch(err => {
        alert.error('Nepovedlo se načíst členy na tréninkové skupině.', {timeout: 0});
        console.log(err);
        return [];


    });

    return response;
}

/*const ClenService = {

    EditClen: function(data) {
        const alert = useAlert();
        axios.post('api/clenove/editClena', data).then(res => {
            //this.props.alert.success('Produkt byl přidán');
            if (res.data.result > 0) {
                alert.success(res.data.message);

            } else {
                alert.error(res.data.message, {timeout: 0});
                console.log(res.data.exception);
            }


        }).catch(err => {
            alert.error('Došlo k chybě na serveru.', {timeout: 0});
            console.log(err);
        });
    },


};*/

export default ClenService;