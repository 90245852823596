import React  from 'react';
import {Link} from 'react-router-dom';
import {faThLarge} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './BackToDashboard.scss';


const BackToDashboard = () => {




    return (
        <div className={"backToDashboard"}>
            <Link to={"/dashboard"}>
            <FontAwesomeIcon className={"i"} icon={faThLarge} />
            </Link>
        </div>

    )

};

export default BackToDashboard;