import React  from 'react';
import {Link} from 'react-router-dom';
import './AdminMenu.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';



const AdminMenu = () => {




    return (
        <div className={"adminMenu"}>
            <Link to={"/clenove"}>
               <FontAwesomeIcon icon={faExternalLinkAlt} className={"i"} />Členové
            </Link>
            <Link to={"/treninkoveSkupiny"}>
                <FontAwesomeIcon icon={faExternalLinkAlt} className={"i"} />Tréninkové skup.
            </Link>
            <Link to={"/treninky-admin"}>
                <FontAwesomeIcon icon={faExternalLinkAlt} className={"i"} />Tréninky
            </Link>
            <Link to={"/akce"}>
                <FontAwesomeIcon icon={faExternalLinkAlt} className={"i"} />Akce
            </Link>
            <Link to={"/finance"}>
                <FontAwesomeIcon icon={faExternalLinkAlt} className={"i"} />Finance
            </Link>
        </div>

    )

};

export default AdminMenu;