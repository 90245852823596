import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import InputError from "../shared/InputError";
import messages from "../shared/InputErrorMessages";
import {useForm} from "react-hook-form";
import {useAlert} from "react-alert";
import axios from "axios";
import Time from "react-time-format";
import ClenService from "../../Services/ClenService";
import ClenFotkyUpload from "../clenove-admin/ClenFotkyUpload";

const Nastaveni = () => {

    const [starsi18, setStarsi18] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const {register, handleSubmit, errors, setValue, watch} = useForm({
        defaultValues: {
            jmeno: "",
            prijmeni: "",
            rc: "",
            mobil: "",
            ulice: "",
            ulicecp: "",
            mesto: "",
            psc: "",
            email: "",
            zastupce: "",
            zastupceemail: "",
            zastupcemobil: "",
            password: "",
            passwordconfirm: "",
            vyskaCm: 0,
            vahaKg: 0,
            dosahSmecCm: 0,
            dosahBlokCm: 0
        }
    });
    const [nastaveni, setNastaveni] = useState({});

    const userId = watch("id");

    const alert = useAlert();

    const utils = require('../utils');
    const isEmail = (value) => {
        return utils.isEmail(value);
    }



    useEffect( ()  => {

        const loadData = async () => {
            await axios.get('api/clenove/nastaveniClena').then(res => {
                //console.log(res.data);
                setStarsi18(res.data.user.starsi18);
                setNastaveni(res.data);
                setValue("jmeno", res.data.user.jmeno);
                setValue("prijmeni", res.data.user.prijmeni);
                setValue("email", res.data.user.email);
                setValue("id", res.data.user.id);
                setValue("mesto", res.data.user.mesto);
                setValue("mobil", res.data.user.mobil);
                setValue("psc", res.data.user.psc);
                setValue("rc", res.data.user.rc);
                setValue("ulice", res.data.user.ulice);
                setValue("ulicecp", res.data.user.ulicecp);
                setValue("zastupce", res.data.user.zastupce);
                setValue("zastupceemail", res.data.user.zastupceemail);
                setValue("zastupcemobil", res.data.user.zastupcemobil);
                setValue("datumnar", res.data.user.datumNar.substring(0, res.data.user.datumNar.length - 9));
                setValue("vyskaCm", res.data.user.vyskaCm);
                setValue("vahaKg", res.data.user.vahaKg);
                setValue("dosahSmecCm", res.data.user.dosahSmecCm);
                setValue("dosahBlokCm", res.data.user.dosahBlokCm);



            });
        }

        loadData();
    }, [setValue]);


    const onSubmit = (data) => {
        ClenService.EditNastaveni(data, alert);
    }



    return(
        <div>

            <h1>Nastavení</h1>
            <div className={"nastaveni"}>
                <div className={"user"}>
                    <div className={"form"}>
                        <div className={"header"}>
                            <h2>Nastavení uživatele</h2>
                        </div>
                        <div className={"body"}>

                            <div className="item">
                                <label>Id*</label>
                                <input type="text" readOnly={"readonly"} name="id" ref={register({required: true})}/>
                                {errors.id &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Jméno*</label>
                                <input type="text" name="jmeno" maxLength="30" ref={register({required: true})}/>
                                {errors.jmeno &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Příjmení*</label>
                                <input type="text" name="prijmeni" maxLength="30" ref={register({required: true})}/>
                                {errors.prijmeni &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Datum narození*</label>
                                <input type="date" name="datumnar" ref={register({required: true})}/>
                                {errors.datumnar &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Rodné číslo (bez lomítka)*</label>
                                <input type="text" name="rc" maxLength="20" ref={register({required: true})}/>
                                {errors.rc &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Mobilní číslo*</label>
                                <input type="text" name="mobil" maxLength="9" placeholder="111222333"
                                       ref={register({required: true})}/>
                                {errors.mobil &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Ulice*</label>
                                <input type="text" name="ulice" maxLength="100" ref={register({required: true})}/>
                                {errors.ulice &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Číslo popisné*</label>
                                <input type="text" name="ulicecp" maxLength="10" ref={register({required: true})}/>
                                {errors.ulicecp &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Město*</label>
                                <input type="text" name="mesto" maxLength="100" ref={register({required: true})}/>
                                {errors.mesto &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>PSČ*</label>
                                <input type="text" name="psc" maxLength="6" ref={register({required: true})}/>
                                {errors.psc &&
                                <InputError message={messages.required}></InputError>}
                            </div>

                            <div className="item">
                                <label>Email*</label>
                                <input type="text" name="email" maxLength="60" ref={register({
                                    required: true,
                                    validate: isEmail
                                })}/>
                                {errors.email &&
                                <InputError message={messages.email}></InputError>}
                            </div>
                            <div className="item">
                                <label>Změnit heslo</label>
                                <input type="checkbox" onClick={() => {
                                    setChangePassword(!changePassword)
                                }}/>

                            </div>
                            {changePassword
                                ? <div className="item">
                                    <label>Heslo*</label>
                                    <input type="password" name="password" ref={register({required: true})}/>
                                    {errors.email &&
                                    <InputError message={messages.required}></InputError>}
                                    <label>Heslo znovu*</label>
                                    <input type="password" name="passwordconfirm" ref={register({required: true})}/>
                                    {errors.email &&
                                    <InputError message={messages.required}></InputError>}
                                </div>
                                : null
                            }


                            {!starsi18 ?
                                <div className="item">
                                    <label>Jméno a příjmení odpovědného zástupce</label>
                                    <input type="text" name="zastupce" maxLength="100" ref={register({required: true})}/>
                                    {errors.zastupce &&
                                    <InputError message={messages.required}></InputError>}
                                </div>
                                : null}

                            {!starsi18 ?
                                <div className="item">
                                    <label>Email odpovědného zástupce</label>
                                    <input type="text" name="zastupceemail" maxLength="60" ref={register({required: true})}/>
                                    {errors.zastupceemail &&
                                    <InputError message={messages.required}></InputError>}
                                </div>

                                : null}

                            {!starsi18 ?
                                <div className="item">
                                    <label>Mobil odpovědného zástupce</label>
                                    <input type="text" name="zastupcemobil" maxLength="9" placeholder="111222333"
                                           ref={register({required: true})}/>
                                    {errors.zastupcemobil &&
                                    <InputError message={messages.required}></InputError>}
                                </div>

                                : null}
                            <div className="item">
                                <label>Výška v cm</label>
                                <input type="number" name="vyskaCm" ref={register({required: true})}/>
                                {errors.vyskaCm &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Váha v kg</label>
                                <input type="number" name="vahaKg" maxLength="6" ref={register({required: true})}/>
                                {errors.vahaKg &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Dosah blok v cm</label>
                                <input type="number" name="dosahBlokCm" maxLength="6" ref={register({required: true})}/>
                                {errors.dosahBlokCm &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Dosah smeč v cm</label>
                                <input type="number" name="dosahSmecCm" maxLength="6" ref={register({required: true})}/>
                                {errors.dosahSmecCm &&
                                <InputError message={messages.required}></InputError>}
                            </div>

                                <ClenFotkyUpload idClen={userId} isEdit={true} />



                        </div>
                        <div className="footer">
                            <div className="ctrl">
                                <button type="button"  className="btn-save" onClick={handleSubmit(onSubmit)}><FontAwesomeIcon
                                    icon={faSave} className="i"/>Uložit
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                </div>
            {nastaveni.user ?
                <div className={"byAdmin"}>
                    <div className={"form"}>
                        <div className={"header"}>
                            <h2>Ostatní</h2>
                        </div>
                        <div className={"body"}>
                            <div className={"item"}>
                                <label>Tréninková skupina</label>
                                <label className={"value"}>{nastaveni.trenSkupina != null ? nastaveni.trenSkupina.nazev : null}</label>
                            </div>

                            <div className="item">
                                <label>Admin práva</label>
                                <label className={"value"}>{nastaveni.user.admin ? "ANO" : "NE"}</label>

                            </div>
                            <div className="item">
                                <label>Role</label>

                                <label className={"value"}>
                                    {nastaveni.user.role === "clen" ? "Člen" : null }
                                    {nastaveni.user.role === "neclen" ? "Nečlenský účet" : null }
                                </label>

                            </div>
                            <div className="item">
                                <label>Stav</label>

                                <label className={"value"}>
                                    {nastaveni.user.stav === 1 ? "Nový" : null }
                                    {nastaveni.user.stav === 2 ? "Aktivní" : null }
                                    {nastaveni.user.stav === -1 ? "Neaktivní" : null }
                                </label>

                            </div>
                            <div className="item">
                                <label>Chci vystavit potvrzení na zaplacení členských příspěvků?</label>
                                <label className={"value"}>{nastaveni.user.vystavitpotvr ? "ANO" : "NE" }</label>

                            </div>
                            <div className="item">
                                <label>Souhlasím s uveřejněním svého rodného čísla pro účely MŠMT a Volejbalového informačního systému (VIS). Souhlasím se zpracováním osobních údajů pro účely volejbalového klubu VK DK z.s.
                                </label>
                                <label className={"value"}>{nastaveni.user.souhlas ? "ANO" : "NE" }</label>

                            </div>
                            <div className="item">
                                <label>Platnost registračního průkazu
                                </label>
                                <label className={"value"}><Time value={nastaveni.user.platRegPruk  } format="DD.MM.YYYY" /></label>

                            </div>


                        </div>
                    </div>
                </div>
                :
            null}

            </div>



    )
}

export default Nastaveni;