import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {useAlert} from "react-alert";
import './ClenFotkyUpload.scss';

const ClenFotkyUpload = ({isEdit, idClen}) => {

    const alert = useAlert();
    const [loaded, setLoaded] = useState(false);
    const [fotka, setFotka] = useState(new FormData());
    const [fotky, setFotky] = useState({
        standard: "",
        dres: ""
    });
    
    const [fotoStandardEnabled, setFotoStandardEnabled] = useState(true);
    

    useEffect(() => {

        const loadFotky = () => {
            axios.get('/api/clenove/detailClena/' + idClen).then(res => {
                 setFotky({
                   ...fotky,
                   dres: res.data.fotky.find(obj => obj.typeFotky === "dres"),
                   standard: res.data.fotky.find(obj => obj.typeFotky === "standard")
               })
            }).catch(err => {
                alert.error('Při zpracování došlo k chybě', {timeout: 0});
            });
        }

        if (!loaded && idClen) {
            loadFotky();
            setLoaded(true);
        }

    }, [loaded, idClen, alert, fotky]);

    const uploadPhoto = (type) => {
        axios.post("api/media/clen/" + idClen + "/fotka/" + type, fotka, {'Content-Type': 'multipart/form-data'}).then(() => {
            setFotka(new FormData());
            setLoaded(false);
        }).catch(err => {
            alert.error('Došlo k chybě na serveru.', {timeout: 0});
            console.log(err);
        });
    }

    const removePhoto = (id) => {
        axios.delete("api/media/" + id).then(() => {
            alert.success("Fotka byla smazána");
            setLoaded(false);
        }).catch(err => {
            alert.error('Došlo k chybě na serveru.', {timeout: 0});
            console.log(err);
        });
    }
    
    const verifyImage = (file) => {

        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => resolve([img.width, img.height])
            img.onerror = reject
            img.src = window.URL.createObjectURL(file)
        });
        
        
    }
    
    

    const handleImageChange = (e) => {
        console.log(e);
        setFotoStandardEnabled(true);
        let file = e.target.files[0];
        let isOk = true;
        if(e.target.id === "fotkaStandard") {
            verifyImage(e.target.files[0]).then(result => {
                console.log(result[0]);
                console.log(result[1]);
                if(result[0] > 240 || result[1] > 320)
                {
                    alert.error('Fotka je moc velká. Maximální velikost je 240*320.', {timeout: 0});
                    isOk = false;
                    setFotoStandardEnabled(false);
                }
            });

          
        }

        
        
        if (e.target.id === "fotkaStandard" && e.target.id.size > 5000000) {
            

            alert.error('Fotka je moc velká. Maximální velikost je 5mb.', {timeout: 0});
            e.target.value = null;
            setFotoStandardEnabled(false);
            return false;    
        }
        

        fotka.append("file", file);

        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div className={"clen-fotky-upload"}>
            {isEdit &&
            <div className="item fotka-box">
                <div>
                <label>Foto standard</label>
                    <div className={"description"}>
                        Vlastní fotografii si můžete změnit kdykoliv chcete.
                        Fotografii si musíte změnit, pokud je starší než 1 rok a chcete provést tisk průkazu nebo přestup.
                        Ve formuláři vyhledejte požadovanou osobu. Pak nahrajte novou fotografii.
                        Soubor musí být ve tvaru JPG, minimální rozměry jsou 240 x 320 bodů, orientace na výšku. Maximální velikost je 5MB.
                    </div>

                </div>
                {fotky.standard ?
                    <div className={"imagePreview"}>
                        <img alt={"fotkaStandard"} src={"dokumenty/fotky/" + idClen + "/" + fotky.standard.fileName}/>
                        <button className={"btn-foto-remove"} type={"button"} onClick={() => {
                            removePhoto(fotky.standard.id)

                        }}>
                            <FontAwesomeIcon icon={faTimes} className="remove"/>
                        </button>
                    </div> :
                    <div>
                        <input id='fotkaStandard' type="file"
                               onChange={(e) => handleImageChange(e)}
                               accept="image/png, image/jpeg, image/jpg, image/png"/>
                        <button disabled={!fotoStandardEnabled} className={"btn-1"} type={"button"} onClick={() => {
                            uploadPhoto("standard")
                        }}>Nahrát
                        </button>
                    </div>
                }
            </div>
            }
            {isEdit &&
            <div className="item fotka-box">
                <label>Foto v dresu</label>
                {fotky.dres ?
                    <div className={"imagePreview"}>
                        <img alt={"fotkaDres"} src={"dokumenty/fotky/" + idClen + "/" + fotky.dres.fileName}/>
                        <button className={"btn-foto-remove"} type={"button"} onClick={() => {
                            removePhoto(fotky.dres.id)
                        }}>
                            <FontAwesomeIcon icon={faTimes} className="remove"/>
                        </button>
                    </div> :
                    <div>
                        <input id='fotkaDres' type="file"
                               onChange={(e) => handleImageChange(e)}
                               accept="image/png, image/jpeg, image/jpg, image/png"/>
                        <button  className={"btn-1"} type={"button"} onClick={() => {
                            uploadPhoto("dres")
                        }}>Nahrát
                        </button>
                    </div>

                }

            </div>
            }
        </div>
    )

}

export default ClenFotkyUpload;
