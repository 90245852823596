import React from "react";

export const PlatbaZa = (props) => {
    let type = '';
    switch (props.platbaZa) {
        case 1:
            type = 'Členský poplatek';
            break;
        case 2:
            type = 'Akci';
            break;
        default:
            type = 'Ostatní';
    }
    
    if(props.input){
        return(
            <input type="text" readOnly="readonly" value={type} /> 
        )
    } else {
        return(
            <span>{type}</span>
        )
        
    }
    
    
}