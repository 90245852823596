import React from 'react';
import { useForm } from "react-hook-form";
import InputError from '../shared/InputError';
import messages from '../shared/InputErrorMessages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import  { authenticationService }  from '../../helpers/AuthenticationService';
import {Link, useHistory} from "react-router-dom";
import './Login.scss';


const Login = () => {

    const { register, handleSubmit, errors} = useForm();

    let history = useHistory();

    const onSubmit = (async (data) => {
        //console.log(data);
        await authenticationService.login(data.id, data.password)
            .then(
                res => {
                    if(res.data.error){
                        alert(res.data.error);
                    }else {
                        history.push('/')
                    }
                    
                },
                error => {
                    console.log(error);
                    alert("Uživatelské jméno nebo heslo není správné");
                    

                }
            );
        
    })

   
    return(
        <div className="login login-page">
             <h1>Login</h1>
            
            <div className="form">
                <div className="header">
                </div>
                <div className="body">
                <div className="item">
                        <label>Vaše členské číslo</label>
                        <input type="number" name="id" ref={register({required: true})}/>
                            {errors.nazev &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Heslo</label>
                        <input type='password' ref={register} name="password" />
                    </div>
                </div>
                <div className="footer">
                    <div className="ctrl">
                        <button type="button" onClick={handleSubmit(onSubmit)} className="btn-save"><FontAwesomeIcon icon={faSave} className="i" />Login</button>
                    </div>
                </div>
                  <div className={"recovery-password"}>
                      <Link to="/obnova-hesla">Zapomenuté heslo</Link>
                  </div>
            </div>
           
        </div>
        
    )
}

export default Login;