import React, {useEffect, useState} from 'react';
import ClenService from "../../../Services/ClenService";
import {useAlert} from "react-alert";
import './NovyClenove.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

const NovyCLenove = () => {

    const [data, setData] = useState([]);
    const alert = useAlert();

useEffect(() => {
    const loadData = async () => {
        let res  = await ClenService.GetAllNoveCLeny(alert);
        setData(res.data);
    }

    loadData();


},[alert]);



    return(
        <div className={"dash_novyClenove"}>
           <div className={"text"}>
               <FontAwesomeIcon icon={faUserPlus} className={"i"} /> Počet nezpracovaných nových členů:
           </div>
            <div className={"no"}>
                {data.length}
            </div>



        </div>
    )
}

export default NovyCLenove;