import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import axios from 'axios';
import {useHistory} from "react-router-dom";
import {authenticationService} from './helpers/AuthenticationService';
import {transitions, positions, Provider as AlertProvider} from 'react-alert'
import AlertTemplate from './components/react-template-custom'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

axios.interceptors.request.use(
    config => {
        document.getElementById('loader').classList.remove('lds-spinner-hide');
        if (config.url === '/api/auth/authenticate' || config.url.startsWith('/api/clenove/obnova-hesla')) {
            return config;
        } else {
            const currentUser = authenticationService.currentUserValue;
            if (currentUser) {
                config.headers['Authorization'] = 'Bearer ' + currentUser.token;
                return config;
            } else {
                let history = useHistory();
                history.push('/login')
            }
        }
    },
    error => {
        Promise.reject(error)
    });


    axios.interceptors.response.use(response => {
    document.getElementById('loader').classList.add('lds-spinner-hide');
    return response;
    }, error => {

        document.getElementById('loader').classList.add('lds-spinner-hide');
        if (error.response.status === 401 || error.response.status === 404) {
            console.log(error);
            authenticationService.logout();
            let history = useHistory();
            history.push('login');
    
    
        }
        return error;
});

//react-alert
// optional configuration
const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_LEFT,
    timeout: 8000,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.FADE
}


ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <AlertProvider template={AlertTemplate} {...options}>
            <App/>
        </AlertProvider>
    </BrowserRouter>,
    rootElement);

registerServiceWorker();

