import React from 'react';
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './InputError.scss';

const InpurtError = ({message}) => {

    return (

       <div className={"error-icon"}>
           <FontAwesomeIcon icon={faExclamationTriangle} title={message}/>
       </div>
    );
}

export default InpurtError;