import React, {Component} from 'react';
import {Route} from 'react-router';
import {Layout} from './components/Layout';

import {PrivateRoute} from './components/login/PrivateRoute';
import Clenove from './components/clenove-admin/Clenove';
import NovyClen from './components/clenove-admin/NovyClen';
import TreninkoveSkupiny from './components/treninkovaSkupina-admin/TreninkoveSkupiny';
import NovaTrenSkupina from './components/treninkovaSkupina-admin/NovaTrenSkupina';
import Trenink from './components/treninky-admin/Treninky';
import NovyTrenink from './components/treninky-admin/NovyTrenink';
import Login from './components/login/Login';
import TreninkyClen from './components/treninky-clen/Treninky'
import Akce from "./components/akce-admin/Akce";
import NovaEditAkce from "./components/akce-admin/NovaEditAkce";
import AkceClen from './components/akce-clen/Akce';
import Novinky from "./components/publicWeb/Novinky";
import NovinkyAddUpdate from "./components/publicWeb/NovinkyAddUpdate";
import TreninkyTrener from './components/treninky-trener/Treninky';
import MistaTreninku from "./components/treninky-admin/MistaTreninku";
import NoveEditMisto from "./components/treninky-admin/NoveEditMisto";
import DokumentyAddOrUpdate from "./components/publicWeb/DokumentyAddOrUpdate";
import Dashboard from "./components/dashboard/Dashboard";
import Nastaveni from "./components/nastaveni/Nastaveni";
import Emaily from "./components/emaily/Emaily";
import Finance from "./components/finance/Finance";
import Dokumenty from "./components/publicWeb/Dokumenty";
import ObnovaHesla from "./components/login/ObnovaHesla";
import NotAllowed from "./components/NotAllowed";
import NastaveniWebu from "./components/nastaveni-webu/NastaveniWebu";
import Test from "./components/test/Test";
import Platby from "./components/platby-clen/Platby";
import CarouselImages from "./components/publicWeb/CarouselImages";


export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/login' component={Login}/>
                <Route exact path="/obnova-hesla/:token?" component={ObnovaHesla}/>

                <PrivateRoute exact path='/' component={Dashboard}/>



                {/* DASHBOARD */}

                <PrivateRoute  path='/dashboard' component={Dashboard}/>
                {/* ADMIN */}
                <PrivateRoute  admin={true} exact path='/clenove' component={Clenove}/>
                <PrivateRoute admin={true} exact path='/clenove/novyClen' component={NovyClen}/>
                <PrivateRoute admin={true} exact path='/treninkoveSkupiny' component={TreninkoveSkupiny}/>
                <PrivateRoute admin={true} exact path='/treninkoveSkupiny/novaTrenSkupina' component={NovaTrenSkupina}/>
                <PrivateRoute admin={true} exact path='/treninky-admin' component={Trenink}/>
                <PrivateRoute admin={true} exact path='/treninky-admin/novyTrenink' component={NovyTrenink}/>
                <PrivateRoute admin={true} exact path='/akce' component={Akce}/>
                <PrivateRoute admin={true} exact path='/akce/novaAkce' component={NovaEditAkce}/>
                <PrivateRoute admin={true} exact path='/treninky-admin/mistaTreninku' component={MistaTreninku}/>
                <PrivateRoute admin={true} exact path='/treninky-admin/mistaTreninku/noveMisto' component={NoveEditMisto}/>
                    <PrivateRoute admin={true} exact path='/nastaveni-webu' component={NastaveniWebu}/>
                <PrivateRoute admin={true} exact path={"/carousel"} component={CarouselImages} />

                {/* CLEN */}
                <PrivateRoute exact path='/treninky-clen' component={TreninkyClen}/>
                <PrivateRoute exact path='/akce-clen' component={AkceClen}/>
                <PrivateRoute exact path='/platby-clen' component={Platby}/>

                {/* TRENER */}
                <PrivateRoute trener={true}  exact path='/treninky-trener' component={TreninkyTrener}/>


                {/*VEREJNY WEB */}

                <PrivateRoute admin={true} exact path={"/novinky"} component={Novinky}/>
                <PrivateRoute admin={true} exact path={"/novinky/pridat"} component={NovinkyAddUpdate}/>
                <PrivateRoute exact path={"/dok"} component={Dokumenty}/>
                <PrivateRoute admin={true} exact path={"/dokumenty/pridat"} component={DokumentyAddOrUpdate}/>

                {/* FINANCE */}
                <PrivateRoute admin={true} exact path={"/finance"} component={Finance}/>

                {/* NASTAVENI */}
                <PrivateRoute exact path={"/nastaveni"} component={Nastaveni}/>

                {/* Emaily */}
                <PrivateRoute admin={true} exact path={"/emaily"} component={Emaily}/>

                {/* Testovaci cas */}
                <PrivateRoute admin={true} exact path={"/test"} component={Test}/>

                {/* NotAllowed */}
                <PrivateRoute exact path={"/notAllowed"} component={NotAllowed}/>
            </Layout>
        );
    }
}
