import React, {useState, useCallback} from 'react';
import './Emaily.scss';
import axios from 'axios';
import {useAlert} from "react-alert";

import Select from "react-select";
import EmailHeaders from "./EmailHeaders";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import BackToDashboard from "../shared/BackToDashboard";
import {Editor} from "react-draft-wysiwyg";
import {EditorState, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {useDropzone} from 'react-dropzone'
import '../editor.scss';

const Emaily = () => {
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [name, setName] = useState('');
    const [emailData, setEmailData] = useState({});

    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty(),
    );

    const handleWyswyg = (a) => {
        setEditorState(a);
    }


    const alert = useAlert();


    const handleChangeSelect = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    }

    const handleClick = (e) => {
        setSelectedOptions([]);
        setName(e.target.getAttribute('data-name'));
        axios.get(e.target.getAttribute('data-url')).then(res => {
            setOptions(res.data);
        });


    }

    const emailDataChange = (e) => {

        const {name, value} = e.target;
        setEmailData(prevState => ({
            ...prevState,
            [name]: value
        }))


    }

    /*const startSendQue = () => {
        axios.get("api/email/startProcessingSendQue").then(res => {
            if (res.data.result === 1) {
                alert.success(res.data.message);
            } else {
                alert.error(res.data.message, {timeout: 0})
            }
        }).catch(err => {
                alert.error("Došlo k chybě na serveru");
                console.log(err);
            }
        );
    }*/

    const markToBeSent = () => {
        axios.get("api/email/markEmailsToBeSent").then(res => {
            if (res.data.result === 1) {
                alert.success(res.data.message);
            } else {
                alert.error(res.data.message, {timeout: 0})
            }
        }).catch(err => {
                alert.error("Došlo k chybě na serveru");
                console.log(err);
            }
        );
    }


    const addToSendQue = () => {
        if (selectedOptions.length === 0) {
            alert.info('Vyberte alespoň jednu volbu.');
            return false;
        }
        /*setEmailData(prevState => ({
            ...prevState,
            selectedOptions: selectedOptions,
            typ: name
        }))*/

        let formData = new FormData();

        for (var i = 0; i < acceptedFiles.length; i++) {
            let file = acceptedFiles[i];
            formData.append('files', file);
        }
        
        
        
        
      //  emailData.files = formData;

        emailData.zprava = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        

        if (emailData.nazev === undefined || emailData.predmet === undefined || emailData.zprava === undefined) {
            alert.info("Vyplňte prosím veškerá pole.", {timeout: 0})
            return false;
        }

        
        //emailData.selectedOptions = selectedOptions;
        //emailData.typ = name;

        formData.append('zprava', draftToHtml(convertToRaw(editorState.getCurrentContent())));
        formData.append('predmet', emailData.predmet);
        formData.append('nazev', emailData.nazev);
        formData.append('selectedOptions', JSON.stringify(selectedOptions));        
        
        formData.append('typ', name);

        axios.post('api/email/pridatEmailDoEmailFronty/', formData).then(res => {
            if (res.data.result === 1) {
                alert.success(res.data.message);
            } else {
                alert.error(res.data.message, {timeout: 0});
            }
        }).catch(err => {
            alert.error("Došlo k chybě na serveru.", {timeout: 0});
            console.log(err);
        });
    }

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                console.log(binaryStr)
            }
            reader.readAsArrayBuffer(file)
        })

    }, [])


    const {getRootProps, getInputProps, acceptedFiles} = useDropzone(onDrop);
    const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>);

    return (
        <div>
            <div className={"emaily"}>
                <h1><BackToDashboard/>Emaily</h1>
                <div className="subMenu">

                    <button className={"btn-1"} data-name={"Členové"} data-url={"api/clenove/clen-select"}
                            onClick={handleClick}>Členové
                    </button>
                    <button className={"btn-1"} data-name={"Tréninkové skupiny"}
                            data-url={"api/treninkoveSkup/getAllActiveSelect"} onClick={handleClick}>Tréninkové skupiny
                    </button>
                    <button className={"btn-1"} data-name={"Tréninky"} data-url={"api/treninky/getAllActiveSelect"}
                            onClick={handleClick}>Tréninky
                    </button>
                    <button className={"btn-1"} data-name={"Akce"} data-url={"api/akce/getAllAkceActiveSelect"}
                            onClick={handleClick}>Akce
                    </button>
                </div>
                <div className={"selectEmail"}>
                    <label>{name}</label>
                    <Select isMulti defaultValue={[]} closeMenuOnSelect={true} value={selectedOptions}
                            className={"select"} onChange={handleChangeSelect} options={options}/>

                </div>
                <div className={"email"}>

                    <div className={"item"}>
                        <label>Předmět</label>
                        <input name={'predmet'} onChange={emailDataChange} maxLength={200} type={"text"}/>
                    </div>
                    <div className={"item"}>
                        <label>Zpráva</label>
                        {/*<textarea name={'zprava'} onChange={emailDataChange} maxLength={4000}></textarea>*/}

                        <Editor
                            editorState={editorState}
                            onEditorStateChange={handleWyswyg}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'emoji', 'image', 'link'],
                                inline: {
                                    inDropdown: false,
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    options: ['bold', 'italic', 'underline', 'strikethrough']
                                },
                            }}
                        />
                    </div>
                    <div className={"item"}>
                        <label>Název v odesílací frontě</label>
                        <input name={'nazev'} maxLength={100} onChange={emailDataChange} type={"text"}/>
                    </div>
                    <div className={"item"}>
                        {acceptedFiles.length === 0 ?
                            <>
                                <label>Nahrát přílohu</label>

                                <div className={"upload"}>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Přetáhněte sem soubor, nebo klikněte pro výběr souboru</p>
                                    </div>
                                </div>
                            </> : null}
                        
                        {acceptedFiles.length > 0 ?
                            <aside>
                                <h4>Přílohy</h4>
                                <ul>{files}</ul>
                            </aside> : null}
                    </div>
                    <div className={"item"}>
                        <button onClick={addToSendQue} className={"btn-filtr"}>Přidat do odesílací fronty</button>
                    </div>
                </div>

            </div>
            <div style={{
                backgroundColor: '#fff',
                maxWidth: '800px',
                padding: '10px',
                borderRadius: '5px',
                marginTop: '10px'
            }}>
                <button onClick={markToBeSent} className={"btn-save"}><FontAwesomeIcon className={"i"}
                                                                                       icon={faPaperPlane}/> Označit
                    neodeslané emaily čekající ve frontě k odeslání
                </button>

            </div>
            <EmailHeaders/>

        </div>
    )
}

export default Emaily;