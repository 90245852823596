import React, {useEffect, useState} from "react";
import axios from "axios";

export const TrenSkupinaCategorySelect = (props) => {
    const [categories, setCategories] = useState(props.default);
    
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        if(!isLoaded){
            axios.get('api/TreninkoveSkup/categories').then(res => {
                setCategories(res.data);
                console.log(res.data);
                setLoaded(true);
            });
        }

    }, [isLoaded, props.default]);

    const onChange = (e) =>{
         props.setCategory(e.target.value);
    }
    
    if(props.value === null){
        return null;
    }
    
    return(
        <React.Fragment>
            <label>Kategorie web</label>
        <select name={props.name} onChange={onChange}   ref={props.reff} value={props.value}  >
            
            {isLoaded && categories.length > 0 && categories.map((x, k) =>
                <option key={k+1} value={x.value}>{x.label}</option>
            )}
        </select>
        </React.Fragment>
    )
    
}