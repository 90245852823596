import React from 'react';
import ImageUploading from 'react-images-uploading';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faSave, faUpload} from "@fortawesome/free-solid-svg-icons";
import './CarouselImages.scss';
import axios from "axios";
import CarouselTable from "./CarouselTable";
import {useAlert} from "react-alert";

const CarouselImages = () => {

    const alert = useAlert();
    const [images, setImages] = React.useState([]);
    const [tableId, setTableId] = React.useState(Math.random());
    const maxNumber = 5;

    const onChange = (imageList) => {
        setImages(imageList);
    };

    const upload = () => {

        var data = [];

        images.forEach(function (f) {
            console.log(f);
            data.push({
                DataUrl: f.data_url,
                LastModified: f.file.lastModified,
                LastModifiedDate: f.file.lastModifiedDate,
                Name: f.file.name,
                Size: f.file.size,
                Type: f.file.type
            })
        })

        axios.post('api/CarouselImages/Upload', data).then(() => {
            setImages([]);
            setTableId(Math.random());
            alert.success("Fotky byly nahrány");
        });

    }

    return (
        <>
            <h1>Fotky na hlavní stránce</h1>
            <div className={"carousel-images"}>
                <div className={"form"}>
                    <h2>Nahrát fotku</h2>
                    <div className={"body"}>
                        <div className={"upload-form"}>
                            <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                            >
                                {({
                                      imageList,
                                      onImageUpload,
                                      onImageRemoveAll,
                                      onImageRemove,
                                      isDragging,
                                      dragProps,
                                  }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <button
                                            className={"upload-button"}
                                            style={isDragging ? {color: 'red'} : undefined}
                                            onClick={onImageUpload}
                                            {...dragProps}

                                        >
                                            <FontAwesomeIcon icon={faUpload} className="i"/>

                                            Pro nahrání fotek klikněte sem, nebo sem přetáhněte obrázky
                                        </button>

                                        {imageList.length > 0 ?
                                            <div className={"image-preview"}>
                                                {imageList.map((image, index) => (
                                                    <div key={"car" + index} className="image-item">
                                                        <div className="image-item__btn-wrapper">
                                                            <button className="binBtn" onClick={() => onImageRemove(index)}>
                                                                <FontAwesomeIcon icon={faBan}  /></button>
                                                        </div>
                                                        <img src={image['data_url']} alt=""/>
                                                        
                                                    </div>
                                                ))}
                                            </div>

                                            : null}

                                        {images.length > 0 ?
                                            <>
                                                <button onClick={onImageRemoveAll} className={"btn-delete"}>
                                                    Odstranit všechny nenahrané fotky
                                                </button>
                                                <button type="button" className="btn-save" onClick={() => {
                                                    upload();
                                                }}>
                                                    <FontAwesomeIcon icon={faSave} className="i"/>
                                                    Nahrát na server
                                                </button>
                                            </>
                                            : null}
                                    </div>
                                )}


                            </ImageUploading>
                        </div>
                    </div>
                </div>
                <div className={"form"}>
                    <div className={"body"}>
                        <h2>Nahrané fotky</h2>
                        <CarouselTable key={tableId}/>
                    </div>
                </div>
            </div>
        </>);
}

export default CarouselImages;