import React, {useEffect, useState, useCallback} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faChevronLeft, faSync} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import {useForm} from "react-hook-form";
import InputError from '../shared/InputError';
import messages from '../shared/InputErrorMessages';
import axios from 'axios';
import Select from 'react-select'
import {useAlert} from "react-alert";
import ClenService from "../../Services/ClenService";
import DataTableStyles from "../shared/DataTableStyle";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import {TrenSkupinaCategorySelect} from "./TrenSkupinaCategorySelect";


const NovaTrenSkupina = (props) => {
    const alert = useAlert();

    const {register, handleSubmit, errors, setValue, reset} = useForm({
        defaultValues: {nazev: "", popis: "", cena: 0, cenaSoutez: 0, externalUrl: '', showOnWeb: true}
    });

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [aktivni, setAktivni] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [clenove, setClenove] = useState([]);
    const [selectedCat, setSelectedCat] = useState(null);
    const [noClen, setNoClen] = useState(false);
    

    const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false
    });

    const columns =  [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
        },
        {
            name: 'Jméno',
            selector: 'jmeno',
            sortable: true,
        },
        {
            name: 'Příjmení',
            selector: 'prijmeni',
            sortable: true,
        },
        {
            name: 'Datum nar.',
            selector: d => { return <Moment format="DD.MM.YYYY" date={d.datumNar} />},
            sortable: true,
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
        }


    ];

    const getDetail = useCallback (() => {
        //console.log(props.id);
        axios.get('api/treninkoveSkup/detailTrenSkup/' + props.id).then(res => {
            //this.props.alert.success('Produkt byl přidán');
            setValue('nazev', res.data.nazev);
            setValue('popis', res.data.popis);
            setValue('id', res.data.id);
            setValue('cena', res.data.cena);
            setValue('cenaSoutez', res.data.cenaSoutez);
            setValue('externalUrl', res.data.externalUrl);
            setValue('telegramUrl', res.data.telegramUrl);
            setAktivni(res.data.aktivni);
            //console.log(res.data);
            setSelectedOptions(res.data.trenerove);

            setValue('webPriority', res.data.webPriority);
            setValue( 'showOnWeb',  res.data.showOnWeb);
            setSelectedCat(res.data.category);
            console.log(res.data);
            setLoaded(true);

        }).catch(err => {
            alert.error("Došlo k chybě při načítání dat.", {timeout: 0});
            console.log(err);
        });
    },[props.id, setValue, alert])

    const getTrenerSelect = useCallback( () => {
        axios.get('api/clenove/trener-select').then(res => {
            //this.props.alert.success('Produkt byl přidán');
            setOptions(res.data);


        }).catch(err => {
            alert.error("Došlo k chybě při načítání dat.", {timeout: 0});
            console.log(err);
        });
    },[alert])

    const handleChangeSelect = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
        console.log(`Option selected:`, selectedOptions);
    };

    const getClenoveOnTrenS = async () => {
        let res = await ClenService.GetAllOnTrenS(props.id,alert);
        if(res.data.length === 0){
            setNoClen(true);
        } else {
            setNoClen(false);
        }
        setClenove(res.data);
    }


    useEffect(() => {
        if (props.edit) {
            getDetail();
            getTrenerSelect();
        }

    }, [props.edit, getDetail, getTrenerSelect]);

    const onSubmit = (data, event) => {
        if (props.edit) {
            data.trenerove = selectedOptions;
            console.log(data);
            event.preventDefault();
            axios.post('api/treninkoveSkup/editTrenSkup', data).then(res => {
                //this.props.alert.success('Produkt byl přidán');
                if (res.data.result === 1) {
                    alert.success(res.data.message);
                    props.onClose();
                } else {
                    alert.error(res.data.message);
                    console.log(res.data.exception);
                }

            }).catch(err => {
                alert.error('Chyba server při ukládání.', {timeout: 0});
                console.log(err);
            });

        } else {
            axios.post('api/treninkoveSkup/ulozTrenSkup', data).then(res => {
                //this.props.alert.success('Produkt byl přidán');
                if (res.data.result === 1) {
                    alert.success(res.data.message);
                    reset();
                } else {
                    alert.error(res.data.message);
                    console.log(res.data.exception);
                }

            }).catch(err => {
                alert.error('Chyba server při ukládání.', {timeout: 0});
                console.log(err);
            });
        }


    }
    


    return (
        <div>
            {props.edit
                ?
                <div>
                    <h1>Editace tréninkov skupiny</h1>

                </div>
                :
                <div>
                    <h1>Nová tréninková skupina</h1>
                    <div className="breadCrumb">
                        <div className="item"><Link to="/treninkoveSkupiny">Tréninkové skupiny</Link></div>
                        <div className="item"><FontAwesomeIcon icon={faChevronLeft} className="i"/></div>
                        <div className="item">Nová tréninková skupina</div>
                    </div>
                </div>
            }


            <div className="form">
                <div className="header">
                </div>
                <div className="body">
                    {props.edit
                        ?
                        <div className={"item"}>
                            <label>ID</label>
                            <input type="text" name="id" readOnly={"readonly"} ref={register()}/>

                        </div>
                        : null}

                    <div className="item">
                        <label>Název*</label>
                        <input type="text" name="nazev" maxLength="100" ref={register({required: true})}/>
                        {errors.nazev &&
                        <InputError message={messages.required}></InputError>}
                    </div>
                    
                    <div className="item">
                        <label>Cena</label>
                        <input type="number" name="cena" ref={register({required: true})}/>
                        {errors.cena &&
                        <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Cena - Soutěž</label>
                        <input type="number" name="cenaSoutez" ref={register({required: true})}/>
                        {errors.cenaSoutez &&
                        <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>ČVF Odkaz</label>
                        <input type="text" name="externalUrl" ref={register()}/>
                       
                    </div>
                    <div className="item">
                        <label>Teams link pro skupinu</label>
                        <input type="text" name="telegramUrl" ref={register()}/>

                    </div>

                    {loaded ?
                        <div className="item">
                            <label>Aktivní</label>
                            <select name={"aktivni"} defaultValue={aktivni} ref={register()}>
                                <option value={1}>Ano</option>
                                <option value={0}>Ne</option>
                            </select>

                        </div>
                        : null}
                    <div className="item">
                        <TrenSkupinaCategorySelect name="category" setCategory={(val) => setSelectedCat(val)} value={selectedCat} reff={register()} />

                    </div>
                    <div className="item">
                        <label>Zobrazovat na webu</label>
                        <input type="checkbox" name="showOnWeb" ref={register}/>
                        

                    </div>
                    <div className="item">
                        <label>Priorita na webu</label>
                        <input type="number" defaultValue={0} min={0} max={10} name="webPriority" ref={register({required: true})}/>

                    </div>
                    
                    <div className="item">
                        <label>Popis</label>
                        <textarea ref={register} name="popis"></textarea>

                    </div>
                    

                </div>


                {props.edit
                    ?
                    <div className={"item"} style={{marginTop: 20, marginLeft: 5, marginBottom: 20, maxWidth: 700}}>
                        <label>Přiřadit trenéra či asistenta trenéra</label>
                        <Select
                            value={selectedOptions}
                            closeMenuOnSelect={true}
                            defaultValue={[]}
                            isMulti
                            options={options}
                            onChange={handleChangeSelect}


                        />
                    </div>
                    : null}


                <div className="footer">
                    <div className="ctrl">
                        <button type="button" onClick={handleSubmit(onSubmit)} className="btn-save"><FontAwesomeIcon
                            icon={faSave} className="i"/>Uložit
                        </button>
                    </div>
                </div>

            </div>

            {props.edit
                ?
                <div className={"clenove"} style={{marginTop: "40px"}}>
                    <button className={"btn-1"} onClick={getClenoveOnTrenS}> <FontAwesomeIcon icon={faSync} className={"i"} /> Načti členy přiřazené na trén. skupině</button>
                    {clenove.length > 0 ?
                        <div className={"table"}>
                            <DataTable
                                title="Výpis členů"

                                data={clenove}
                                columns={columns}
                                paginationComponentOptions={pagination}
                                pagination
                                dense
                                customStyles={DataTableStyles}
                                paginationPerPage = {30}
                                paginationRowsPerPageOptions = {[10,30,50,100]}
                            />
                        </div>
                        : null }

                    {noClen ? 
                        <div>Na tréninkové skupině není přiřazen žádný člen.</div>
                    : null}

                </div>
                : null}

        </div>

    )
}

export default NovaTrenSkupina;