import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faEdit } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DataTableStyles from '../shared/DataTableStyle';
import Modal from "../shared/Modal";
import NovaTrenSkupina from "./NovaTrenSkupina";
import BackToDashboard from "../shared/BackToDashboard";


const TreninkoveSkupiny = () => {

    const [data, setData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [id, setId] = useState(0);
    
    const loadData = () => {

        axios.get('api/treninkoveSkup/getAll').then(res => {
           setData(res.data);
           //console.log(res.data);
        }).catch(err => {

        });

    }

    useEffect(() => {

      loadData();


        
    }, []);

    const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false
    });

    const columns = (clickHandler => [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
        },
        {
            name: 'Název',
            selector: 'nazev',
            sortable: true,
        },
        {
            name: 'Aktivní',
            selector: d => { return d.aktivni === 0 ? "Ne" : "Ano"  },
            sortable: true,
        },
        {
            name: 'Cena',
            selector: 'cena',
            sortable: true,
        },
        {
            name: 'Cena - Soutěž',
            selector: 'cenaSoutez',
            sortable: true,
        },
        {
            cell: (row) => <button className="btn-table-edit" onClick={clickHandler} id={row.id}><FontAwesomeIcon icon={faEdit}/> Upravit</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
       
    
    ]);

    const handleButtonClick = (state) => {
        let id = state.target.closest(".btn-table-edit").id;
        setId(id);
        setModalOpen(true);
        
    };

    return(
        <div>
             <h1><BackToDashboard />Tréninkové skupiny</h1>
             <div className="subMenu">
                <Link className="item" to='/treninkoveSkupiny/novaTrenSkupina'><FontAwesomeIcon icon={faPlusSquare} className="i" />Nová skupina</Link>
            </div>
            
            <div className="table">
            <DataTable
                title="Výpis tréninkových skupin"

                data={data}
                columns={columns(handleButtonClick)}
                paginationComponentOptions={pagination}
                pagination
                dense
                customStyles={DataTableStyles}
            />    
            </div>
            <Modal isOpen={modalOpen}  onClose={()=>{setModalOpen(false);}}><NovaTrenSkupina id={id} edit={true} onClose={()=>{setModalOpen(false); loadData();}} /></Modal>
             
        </div>
        
    )
}

export default TreninkoveSkupiny;