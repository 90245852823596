import React, {useState, useEffect} from 'react';
import axios from "axios";



const SelectMistaTreninku = (props) => {

    const [selectedValue, setSelectedValue] = useState(props.default);
    const [data, setData] = useState([]);


    const onChange = (e) =>{
        setSelectedValue(e.target.value);
    }

    useEffect(() => {

        axios.get('api/treninky/getMistaTreninkuAllActive').then(res => {
            console.log(res.data);
            setData(res.data);
            setSelectedValue(props.default);
            //console.log(props.default);
        }).catch(err => {
            alert.error('Nepovedlo se načíst místa tréninků do selectu.', {timeout: 0});
            console.log(err);
        });

    }, [props.default]);



    return(
        <select name={props.name} onChange={onChange}  value={selectedValue} ref={props.reff}>
            {data.map((x,k) => {
               return <option key={k} value={x.id}>{x.nazev}</option>
            })}
        </select>

    )
}

export default SelectMistaTreninku;