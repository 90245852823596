import React, {useCallback, useEffect, useState} from 'react';
import './Dashboard.scss';
import Trener from "./trener/Trener";
import Clen from "./clen/clen";
import {authenticationService} from '../../helpers/AuthenticationService';
import Admin from "./admin/Admin";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faThLarge, faExternalLinkSquareAlt, faUser, faUsers} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const Dashboard = () => {

    const [currentUser, setCurrentUser] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [publicUrl, setPublicUrl] = useState('');
    const [trenS, setTrenS] = useState({});

    const load = useCallback(async () => {
        await authenticationService.currentUser.subscribe(x => setCurrentUser(x));
    }, []);


    useEffect(() => {
        load().then( () => {
             axios.get('/settings').then(res => {
                setPublicUrl(res.data.publicWebUrl);
            });
             if(currentUser.trens !== undefined){
                 axios.get('api/treninkoveSkup/detailTrenSkup/' + currentUser.trens).then(res => {
                     setTrenS(res.data);
                 })
             }


        }).then( () => {
             setLoaded(true)
        });



    }, [loaded, currentUser, load]);


    return (
        <div className={"dashboard"}>
            <h1><FontAwesomeIcon icon={faThLarge} className={"i"}/> Dashboard</h1>
            <div className={"info"}>
                <div className={"item"}>


                    <div className={"item2 label"}><FontAwesomeIcon icon={faUser} className={"i"}/>Přihlášený uživatel:</div>
                    <div className={"item2 value"}>{currentUser.email}</div>
                    <div className={"item2 value"}>Id: {currentUser && currentUser.id}</div>


                </div>
                {trenS ?
                    <div className={"item"}>
                        <div className={"item2 label"}><FontAwesomeIcon icon={faUsers} className={"i"}/>Tréninková skupina:</div>
                        <div className={"item2 value"}>{trenS.nazev}</div>
                        {trenS.telegramUrl ? <div className={"item2 link"}><a target={'_blank'} rel="noopener noreferrer" href={trenS.telegramUrl}><FontAwesomeIcon icon={faExternalLinkSquareAlt} className={"i"}/>Teams link trén. skupiny</a></div> : null }
                    </div>
                    : null}

                    {publicUrl && <div className={"item"}>
                        <a className={"item2"} href={publicUrl} target={"_blank"} rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkSquareAlt} className={"i"}/>Veřejné stránky </a>
                        <a className={"item2"} href={"https://www.microsoft.com/cs-cz/microsoft-teams/download-app"} target={"_blank"} rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkSquareAlt} className={"i"}/>Stáhnout Teams pro komunikaci mezi členy</a>
                       {/* <a className={"item2"} href={"https://apps.apple.com/app/telegram-messenger/id686449807"} target={"_blank"} rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkSquareAlt} className={"i"}/>Stáhnout Telegram pro iOS</a>
                        <a className={"item2"} href={"https://play.google.com/store/apps/details?id=org.telegram.messenger"} target={"_blank"} rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkSquareAlt} className={"i"}/>Stáhnout Telegram pro Android</a>*/}



                    </div>
                    }

            </div>

            {loaded && currentUser && currentUser.admin ?
                <Admin></Admin>
                : null}

            {loaded && currentUser.trener || currentUser.asistentTrenera ?
                <Trener></Trener>
                : null}

            {loaded ?
                <Clen></Clen>
                : null}


        </div>
    )
}

export default Dashboard;