import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faFilter, faEdit } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DataTableStyles from '../shared/DataTableStyle';
import Modal from "../shared/Modal";
import NovyClen from "./NovyClen";
import { useAlert } from 'react-alert';
import Moment from "react-moment";
import moment from 'moment';
import BackToDashboard from "../shared/BackToDashboard";
import { CSVLink} from "react-csv";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import ReactExport from "react-export-excel";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons/faFileExcel";



const Clenove = () => {

    //excel
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

    const [excelData, setExcelData] = useState([]);

    const [data, setData] = useState({
        prijmeni: '',
        stav: 2
        
    });

    const alert = useAlert();

    //const modalRef = React.useRef();

    const [clenove, setClenove] = useState([]);

    const [idClen, setIdClen] = useState(0);

    const [tagModalOpen, setTagModalOpen] = useState(false);

    const openModal = (id) => {

        setIdClen(id);
        setTagModalOpen(true);
        //modalRef.current.openModal();
    };


    const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false
    });

    const columns =  [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
        },
        {
            name: 'Jméno',
            selector: 'jmeno',
            sortable: true,
        },
        {
            name: 'Příjmení',
            selector: 'prijmeni',
            sortable: true,
        },
        {
            name: 'Tren. skupina',
            selector: d => d.trenSkupiny ? d.trenSkupiny.nazev : null,
            sortable: true,
        },
        {
            name: 'mobil | zastupce',
            cell: row => <span><a style={{textDecoration: 'underline'}} href={'tel:' + row.mobil}>{row.mobil}</a> | <a style={{textDecoration: 'underline'}} href={'tel:' + row.zastupcemobil}>{row.zastupcemobil}</a></span>,
            sortable: false,
        },
        
        {
            name: 'Email',
            cell: row => <span><a style={{textDecoration: 'underline'}} href={'mailto:' + row.email}>{row.email}</a> </span>,
            sortable: false,
        },
        {
            name: 'Datum nar.',
            selector: "datumNar",
            sortable: true,
            cell: row => <Moment format="DD.MM.YYYY" date={row.datumNar} />
        },
        {
            cell: (row) => <button className="btn-table-edit" onClick={()=>openModal(row.id)} data-id={row.id}><FontAwesomeIcon icon={faEdit}/> Upravit</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
       
    
    ];

    const [csvData, setCsvData] = useState([]);
    const [fileName, setFileName] = useState("VKDK_clenove");
    const [generatingCsv, setGeneratingCsv] = useState(false);





    const onChange = (e) => {
        const {name, value} = e.target;
        //console.log(e.target);
        setData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const filtrHandler = () => {

        let prijmeni = data.prijmeni ? data.prijmeni : '';

        axios.get('api/clenove/filtr?stav=' + data.stav + '&pouzeAktivni=false&prijmeni=' + prijmeni).then(res => {
            //console.log(res.data);
            setClenove(res.data);
           
        }).catch(err => {
            alert.error('Nepovedlo se načíst členy z DB.', {timeout: 0});
            console.log(err);
            
        });


    }

    /*const generujEmail = () => {

       

        axios.post('api/clenove/generujPrihlaseni' ).then(res => {

            alert("ok");

        }).catch(err => {
            

        });


    }*/

    const generujCsv = () => {
        
        setGeneratingCsv(true);

        axios.get('api/clenove/generateCsv' ).then(res => {

            //console.log(res);
            let data = res.data;
            if(data && data.length > 0){
                let csvArr = [];
                let excelArr = [];
                let excelObj = {};
                let firstRow = [];
                Object.keys(data[0]).forEach(function(key) {

                    firstRow.push(key);

                });
                csvArr.push(firstRow);
                excelObj.columns = firstRow;
                excelObj.data = [];
                
                for (let i2 = 0; i2 < data.length; i2++) {
                    let row = [];
                    for (let prop in data[i2]) {
                        row.push(data[i2][prop]);
                    }
                    csvArr.push(row);
                    excelObj.data.push(row);
                }

                let now = new Date();
                let dateString = moment(now).format('YYYY-MM-DD_HH_MM');
                let fileName = "VKDK_clenove_" + dateString;
                
                excelArr[0] = excelObj;
                

                //console.log(arr);
                setFileName(fileName);
                setCsvData(csvArr);
                setExcelData(excelArr);
                //console.log(firstRow);
                setGeneratingCsv(false);
                
            }
            

        }).catch(() => {


        });


    }



    return(
        <div>
             <h1> <BackToDashboard /> Členové</h1>
             <div className="subMenu">
                <Link className="item" to='/clenove/novyClen'><FontAwesomeIcon icon={faPlusSquare} className="i" />Nový člen</Link>
                 {/*<button onClick={generujEmail}>Generuj prihlasovaci email</button>*/}
                
                 
                 {csvData && csvData.length > 0 ?
                    <React.Fragment>
                     <div className={"item"} >
                         <CSVLink filename={fileName} data={csvData}>
                             <span style={{color: "#fff"}}><FontAwesomeIcon icon={faDownload} className="i" />Stáhnout CSV</span>
                         </CSVLink>
                     </div>
                    <div className={"item"}>
                        <ExcelFile
                            element={
                                <span>
                                    <FontAwesomeIcon icon={faFileExcel} className="i "/>
                                    Stáhnout EXCEL
                                </span>
                            }
                            
                        >
                            <ExcelSheet dataSet={excelData} name={fileName} />
                        </ExcelFile>
                        
                    </div>

                         
                        

                     </React.Fragment>

                     : <button className={"item"} onClick={() => {generujCsv()}}>Export CSV/EXCEL</button>}

                 {generatingCsv ?
                     <div>Generuji data...</div>

                     : null}
            </div>
            <div className="filtr">
                <div className="item filtrSearch">
                    <div className="searchInputs">
                        <div className="itemSearchInputs">
                            <label htmlFor="prijmeni">Příjmení</label>
                            <input type="search" onChange={onChange} name="prijmeni" maxLength="30" value={data.prijmeni}  />
                            
                        </div>
                        <div className="itemSearchInputs">
                            
                            <label htmlFor="stav">Stav</label>
                            <select name={"stav"} onChange={onChange}>
                                <option value={2}>Aktivní</option>
                                <option value={1}>Nový</option>
                                <option value={-1}>Neaktivní</option>
                            </select>
                        </div>
                    </div>
                    <div className="ctrl">
                    <button className="btn-filtr" onClick={filtrHandler}><FontAwesomeIcon icon={faFilter} className="i" />Filtruj</button>
                    </div>
                </div>
                  
            </div>

            <div className="table">

            <DataTable
                title="Výpis členů"

                data={clenove}
                columns={columns}
                paginationComponentOptions={pagination}
                pagination
                dense
                customStyles={DataTableStyles}
                paginationPerPage = {200}
                paginationRowsPerPageOptions = {[20,50,100,200,300]}
            />    
            </div>
            <Modal isOpen={tagModalOpen}  onClose={()=>{setTagModalOpen(false);}}><NovyClen onClose={()=>{setTagModalOpen(false); filtrHandler();}} idClen={idClen} edit={true}></NovyClen></Modal>
        </div>
        
    )
}

export default Clenove;