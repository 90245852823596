import React, {useState, useCallback} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilter, faInfo} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Modal from "../shared/Modal";
import {useAlert} from "react-alert";
import DataTable from "react-data-table-component";
import DataTableStyles from "../shared/DataTableStyle";
import './Finance.scss'
import 'react-tabs/style/react-tabs.css';
import Moment from "react-moment";
import PlatbaDetail from "./PlatbaDetail";
import {faExclamation} from "@fortawesome/free-solid-svg-icons/faExclamation";
import moment from "moment";
import {PlatbaZa} from "./PlatbaZa";

const PlatbyTab = () => {

    const [platby, setPlatby] = useState([]);
    const [vybranaPlatba, setVybranaPlatba] = useState({});
    const [actualPage, setActualPage] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage] = useState(50);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    let now = new Date();
    const today = moment(now).format('YYYY-MM-DD');

    const [filter, setFilter] = useState({
        pouzeStornovane: false, 
        pouzeUhrazene: false, 
        pouzeNeuhrazene: true,
        preplatek: false,
        vse: false,
        variabilniSymbol: ""
        
    });

    const alert = useAlert();

    
    
    const filterHandler = useCallback( (page) => {

        if (page === 1) {
            setResetPaginationToggle(!resetPaginationToggle);
        }
        
        setLoading(true);
        
        page --;
        
        let parameters = "?skip=" + (page * perPage);
       
            parameters += "&take=" + perPage ;
        
        parameters += "&paggination=true";
        parameters += "&pouzeNeuhrazene=" + filter.pouzeNeuhrazene;
        parameters += "&pouzeUhrazene=" + filter.pouzeUhrazene;
        parameters += "&pouzeStornovane=" + filter.pouzeStornovane;
        parameters += "&variabilniSymbol=" + filter.variabilniSymbol;
        parameters += "&preplatek=" + filter.preplatek;
        
        axios.get('finance/platby' + parameters).then(res => {
            setPlatby(res.data.items);
            setTotalRows(res.data.totalRows);
        }).catch(() => {
            alert.error('Nepovedlo se načíst data.', {timeout: 0});
        }).finally(() => {setLoading(false)})
    },[resetPaginationToggle, filter, alert, perPage] )
    
    const Stav = (props) => {
        let stav = props.stav;
        let value = ""
        switch (stav) {
            case 2:
                value = "Uhrazená";
                break;
            case 1:
                value = "Neuhrazená";
                break;
            case 0:
                value = "Strono";
                break;
            default:
                value = "Neznámý stav";
        }
        
        
        return(
            <span> {value}</span>
        )
        
    }

    const ClenStav = (props) => {
        let stav = props.stav;
        let value = ""
        switch (stav) {
            case 2:
                value = "Aktivní";
                break;
            case 1:
                value = "Nový";
                break;
            case -1:
                value = "Neaktivní";
                break;
            default:
                value = "Neznámý stav";
        }


        return(
            <span> {value}</span>
        )

    }
    
    const PoSplatnosti = (props) => {
        
        return(
            props.data.stav === 1 && moment(props.data.splatnostDo).format('YYYY-MM-DD') < today ? <span title="Po splatnosti" ><FontAwesomeIcon style={{backgroundColor: "red", padding: 5, borderRadius: 3, color: '#fff'}} icon={faExclamation} className="i" /></span> : null
        )
    }

    const columns = (() => [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            width: '100px'
        },
        
        {
            name: 'Jméno',
            selector: d => d.clen.jmeno ,
            sortable: true,
        },
        {
            name: 'Příjmení',
            selector: d => d.clen.prijmeni,
            sortable: true,
        },
        {
            name: 'Člen stav',
            sortable: false,
            selector: d => <ClenStav stav={d.clen.stav}  />,
            width: '100px'
        },
       
        {
            name: 'Variabilní symbol',
            selector: 'variabilniSymbol',
            sortable: true,
            width: '80px'
        },
        {
            name: 'Platba za',
            sortable: false,
            selector: d => <PlatbaZa platbaZa={d.platbaZa} />,
            width: '100px'
        }
        ,
        {
            name: 'Název akce',
            sortable: false,
            selector: d => d.akce !== null ? d.akce.nazev : "N/A" ,
            width: '100px'
        }
        ,
       
        {
            name: 'Stav',
            sortable: false,
            selector: d => <Stav stav={d.stav} splatnost={d.splatnostDo} />,
            width: '100px'
        }
        ,
        {
            name: 'Datum vytvoření',
            selector: d => <Moment date={d.datumVytvoreni} format={"DD.MM.yyyy"}/>,
            sortable: true,
            width: '90px'
        },
        {
            name: '',
            cell: (row) => <PoSplatnosti data={row} /> ,
            sortable: false,
            width: '30px'
        },
        {
            name: 'Splatnost',
            selector: d => <Moment date={d.splatnostDo} format={"DD.MM.yyyy"}/>,
            sortable: true,
            width: '90px'
        },
         {
            name: 'Datum uhrazení',
            selector: d => d.datumPlatba ? <Moment date={d.datumPlatba} format={"DD.MM.yyyy"}/> : null,
            sortable: true,
             width: '90px'
        }/*,  
        {
            cell: (row) => row.stav === 1 ?
                    <button 
                        className="btn-table-edit green" 
                        onClick={() => {handleUhradit(row)}}>
                        <FontAwesomeIcon className="i" icon={faMoneyBill}/> 
                        Uhradit
                    </button>
            : null,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }*/,
       
        {
            cell: (row) =>
                <button className="btn-table-edit" onClick={() => {
                    handleButtonClick(row)
                }}><FontAwesomeIcon
                    icon={faInfo} className="i"/> Detail</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }

    ]);


    const handleButtonClick = (state) => {
        setSelectedId(state.id);
        setVybranaPlatba(state);
        setModalOpen(true);
    };

    /*const handleUhradit = (state) => {
        setSelectedPlatba(state);
        setModalUhraditOpen(true);
    }*/

   


    /*const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false
    });*/

    const handlePageChange = page => {
        setActualPage(page);
        filterHandler(page);
    };

    

    return (
        <div>

            <div className={"filter"}>

                <div className={"filter-item"}>
                    <label>
                    <input type={"radio"} checked={filter.pouzeNeuhrazene} onChange={() => {
                        setFilter({...filter, 
                            pouzeStornovane: false, 
                            pouzeUhrazene: false, 
                            pouzeNeuhrazene: true, 
                            vse: false,
                            preplatek: false,
                        });
                    }}/>
                    Neuhrazené platby</label>
                </div>
                <div className={"filter-item"}>
                    <label>
                    <input type={"radio"} checked={filter.pouzeUhrazene} onChange={() => {
                        setFilter({...filter, 
                            pouzeStornovane: false, 
                            pouzeUhrazene: true,
                            pouzeNeuhrazene: false, 
                            vse: false,
                            preplatek: false});
                    }}/>
                    Uhrazené platby</label>
                </div>
                <div className={"filter-item"}>
                    <label>
                        <input type={"radio"} checked={filter.preplatek} onChange={() => {
                            setFilter({...filter, 
                                pouzeStornovane: false, 
                                pouzeUhrazene: false, 
                                pouzeNeuhrazene: false, 
                                vse: false,
                                preplatek: true,});
                        }}/>
                        Platby s přeplatkem</label>
                </div>
                <div className={"filter-item"}>
                    <label>
                        <input type={"radio"} checked={filter.pouzeStornovane} onChange={() => {
                            setFilter({...filter, 
                                pouzeStornovane: true, 
                                pouzeUhrazene: false, 
                                pouzeNeuhrazene: false, 
                                vse: false,
                                preplatek: false});
                        }}/>
                        Stornované platby</label>
                </div>
                <div className={"filter-item"}>
                    <label>
                    <input type={"radio"} checked={filter.vse} onChange={() => {
                        setFilter({...filter, 
                            pouzeStornovane: false, 
                            pouzeUhrazene: false, 
                            pouzeNeuhrazene: false, 
                            vse: true,
                            preplatek: false
                        });
                    }}/>
                    Vše</label>
                </div>
                <div className={"filter-item"}>
                    <label>Variabilní symbol</label>
                    <input className={"var-symbol"} type={"text"} onChange={(e) => {
                        setFilter({...filter, variabilniSymbol: e.target.value});
                    }}/>

                </div>
                <div className="filter-item">
                    <div className="ctrl">
                        <button className="btn-filtr" onClick={()=> filterHandler(actualPage)}><FontAwesomeIcon icon={faFilter} className="i" />Filtruj</button>
                    </div>
                </div>
            </div>
                <DataTable

                    columns={columns()}
                    data={platby}
                    progressPending={loading}
                    pagination
                    dense
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[50]}
                    paginationResetDefaultPage={resetPaginationToggle}
                    customStyles={DataTableStyles}
                />


    
            <Modal 
                isOpen={modalOpen} 
                onClose={() => {
                setModalOpen(false)
            }}
            >
                <PlatbaDetail 
                    id={selectedId}
                    onClose={() => {
                        setModalOpen(false);
                        
                    }}
                    parentFilter={(p) => {
                        filterHandler(p);
                    }}
                    
                    actualPage={actualPage}
                    platba={vybranaPlatba}
                />
            </Modal>
            {/*<Modal isOpen={modalUhraditOpen} onClose={() => {
                setModalUhraditOpen(false); filterHandler(actualPage);
                
            }}>
                <PlatbaUhrada 
                   
                    platba={selectedPlatba}
                />
            </Modal>*/}
        </div>
    )
}

export default PlatbyTab;