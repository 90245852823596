

const DataTableStyles = {
    rows: {
      style: {
        minHeight: '76px', // override the row height
        '&:hover': {
            backgroundColor: '#ececec'
        }
      }
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        backgroundColor: '#3693D0',
        color: '#fff',
        borderRight: '#ececec'
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  export default DataTableStyles;