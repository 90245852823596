import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useAlert} from "react-alert";
import DataTable from "react-data-table-component";
import DataTableStyles from "../shared/DataTableStyle";
import './Finance.scss'
import 'react-tabs/style/react-tabs.css';
import Moment from "react-moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import {faExclamation} from "@fortawesome/free-solid-svg-icons/faExclamation";
import {PlatbaZa} from "./PlatbaZa";
import PlatbaDetail from "./PlatbaDetail";
import Modal from "../shared/Modal";

const ClenPlatby = ({clen}) => {

    const [platby, setPlatby] = useState([]);
    const [loaded, setLoaded] = useState(false);
    let now = new Date();
    const today = moment(now).format('YYYY-MM-DD');
    const [modalOpen, setModalOpen] = useState(false);
    const [vybranaPlatba, setVybranaPlatba] = useState(null);

    

    const alert = useAlert();

    useEffect(() => {

        const load = () => {
            axios.get('/api/clenove/' + clen.id + '/platby').then(res => {
                setPlatby(res.data)
                //console.log(res.data);
            }).catch( () => {
                alert.error('Nepovedlo se načíst data.', {timeout: 0});
            })
        }

        if (!loaded) {
            load();
            setLoaded(true);
        }
    }, [loaded, clen, alert]);

    const Stav = (props) => {
        let stav = props.stav;
        let value = "";
        switch (stav) {
            case 2:
                value = "Uhrazená";
                break;
            case 1:
                value = "Neuhrazená";
                break;
            case 0:
                value = "Strono";
                break;
            default:
                value = "Neznámý stav";
        }


        return(
            <span> {value}</span>
        )

    }

    const PoSplatnosti = (props) => {

        return(
            props.data.stav === 1 && moment(props.data.splatnostDo).format('YYYY-MM-DD') < today ? <span title="Po splatnosti" ><FontAwesomeIcon style={{backgroundColor: "red", padding: 5, borderRadius: 3, color: '#fff'}} icon={faExclamation} className="i" /></span> : null
        )
    }

    const columns = (() => [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            width: '50px'
        },

        {
            name: 'V. symbol',
            selector: 'variabilniSymbol',
            sortable: true,
            width: '90px'
        },

        {
            name: 'Částka',
            selector: 'castka',
            sortable: true,
            width: '80px'
        },

        {
            name: 'Platba za',
            selector: d => <PlatbaZa platbaZa={d.platbaZa} input={false} />,
            sortable: true,
            width: '130px'
        },

        {
            name: 'Stav',
            sortable: false,
            selector: d => <Stav stav={d.stav} splatnost={d.splatnostDo} />,
            width: '100px'
        }
        ,
        {
            name: 'Vytvořeno',
            selector: d => <Moment date={d.datumVytvoreni} format={"DD.MM.yyyy"}/>,
            sortable: true,
            width: '90px'
        },
        {
            name: '',
            cell: (row) => <PoSplatnosti data={row} /> ,
            sortable: false,
            width: '30px'
        },
        {
            name: 'Splatnost',
            selector: d => <Moment date={d.splatnostDo} format={"DD.MM.yyyy"}/>,
            sortable: true,
            width: '90px'
        },
        {
            name: 'Uhrazeno',
            selector: d => d.datumPlatba ? <Moment date={d.datumPlatba} format={"DD.MM.yyyy"}/> : null,
            sortable: true
        }/*,  
        {
            cell: (row) => row.stav === 1 ?
                    <button 
                        className="btn-table-edit green" 
                        onClick={() => {handleUhradit(row)}}>
                        <FontAwesomeIcon className="i" icon={faMoneyBill}/> 
                        Uhradit
                    </button>
            : null,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }*/,

        {
            cell: (row) =>
                <button className="btn-table-edit" onClick={() => detail(row)} ><FontAwesomeIcon
                    icon={faInfo} className="i"/> Detail</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }

    ]);



    const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false
    });
    
    const detail = (row) => {
        row.clen = clen;
        setVybranaPlatba(row);
        setModalOpen(true);
    }

    return (
        <div>
            <div className={"filter"}>


                <DataTable
                    title="Platby"
                    data={platby}
                    columns={columns()}
                    paginationComponentOptions={pagination}
                    pagination
                    dense
                    customStyles={DataTableStyles}
                />

            </div>
            <Modal
                isOpen={modalOpen}
                onClose={() => {
                    setModalOpen(false)
                }}
            >
                <PlatbaDetail
                    onClose={() => {
                        setModalOpen(false);

                    }}
                    parentFilter={() => {
                       
                    }}

                    actualPage={1}
                    platba={vybranaPlatba}
                />
            </Modal>
        </div>
    )
}

export default ClenPlatby;