import React from 'react';
import NovyCLenove from "./NovyClenove";
import AdminMenu from "./AdminMenu";
import ClenovePlatReg from "./ClenovePlatReg";

const AdminDashboard = () => {






    return(
        <div>
            <h1>Admin</h1>
            <AdminMenu />
            <div className={"dashBlocksContainer"}>
                <div className={"dashBlock-M"}>
                    <NovyCLenove />
                    <ClenovePlatReg />
                </div>
            </div>



        </div>
    )
}

export default AdminDashboard;