import React, {useState} from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import './EmailHeaders.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faHourglass, faInbox, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import Modal from "../shared/Modal";
import EmailHeader from "./EmailHeader";


const EmailHeaders = () => {


    const [emailHeaders, setEmailHeaders] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [showMore, setShowMore] = useState(-1);
    const [modalOpen, setModalOpen] = useState(false);
    const [nazev, setNazev] = useState('');
    const [vytvoreno, setVytvoreno] = useState('');



    const loadHeaders = (systemType) => {
        axios.get(`api/email/getLastEmialHeaders/${systemType}`).then(res => {
            setEmailHeaders(res.data);

        }).then( () => {setLoaded(true);});
    }

    const handleButtonClick = (state) => {
        let nazev = state.target.closest(".clickEdit").getAttribute('data-nazev');
        let vytvoreno = state.target.closest(".clickEdit").getAttribute('data-vytvoreno');
        setNazev(nazev);
        setVytvoreno(vytvoreno);
        setModalOpen(true);

    };





    return(
        <div className={"emailyHeaders"}>
            <button className={"btn-1"} onClick={() => loadHeaders(0)}><FontAwesomeIcon icon={faHourglass} className={"i"} /> Načti aktuální neodeslanou frontu (30 dnů zpět)</button>
            <button className={"btn-1"} onClick={() => loadHeaders(1)}><FontAwesomeIcon icon={faInbox} className={"i"} /> Načti odeslanou frontu (30 dnů zpět)</button>
            {loaded ?
                <div className={"content"}>
                    {emailHeaders.map( (x,k) => {
                        return <div className={"item"} key={k}>
                            <span className={"item2 datum"}>
                                <Moment format="DD.MM.YYYY" date={x.vytvoreno} />
                            </span>
                            <span className={"item2 bar"}><progress  value={x.odeslano} max={x.celkem}></progress></span>
                            <span className={"item2 nazev"}>{x.nazev}</span>

                            <span className={"item2 ctrl"}>
                                <FontAwesomeIcon data-nazev={x.nazev} data-vytvoreno={x.vytvoreno} className={"i clickEdit"} onClick={handleButtonClick}  icon={faEdit} />
                                <FontAwesomeIcon className={"i"} onClick={() => {showMore === k ? setShowMore(-1) : setShowMore(k)}} icon={faInfoCircle} />
                            </span>
                            {showMore === k ?
                                <div className={"more"}>
                                    <div className={"item3"}>
                                        <span className={"item4"}>Typ: </span> <span className={"item4"}>{x.typ}</span>
                                    </div>
                                    <div className={"item3"}>
                                        <span className={"item4"}>Odesláno: {x.odeslano} z {x.celkem}</span>
                                        {x.neodeslano > 0 ?
                                            <span className={"item4"}>zbývá: {x.neodeslano} </span>

                                            : null}
                                    </div>

                                    <div className={"item3 column"}>
                                        <span className={"item4"}>Předmět:</span>
                                        <span className={"item4"}>{x.predmet}</span>
                                    </div>
                                    <div className={"item3 column"}>
                                        <span className={"item4"}>Zpráva:</span>
                                        <span className={"item4"} dangerouslySetInnerHTML={{ __html: x.zprava }}/>
                                    </div>
                                </div>
                                : null}

                            </div>

                    })}
                </div>
                : null}

            <Modal isOpen={modalOpen}  onClose={()=>{setModalOpen(false);}}><EmailHeader nazev={nazev} vytvoreno={vytvoreno} onClose={()=>{setModalOpen(false);}} /></Modal>








        </div>
    )
}

export default EmailHeaders;