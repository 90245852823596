import {BehaviorSubject} from 'rxjs';
import axios from 'axios';
import {useHistory} from "react-router-dom";

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    }
};

function login(username, password) {

    return axios.post('/api/auth/authenticate', {username, password })
        .then(user => {
            localStorage.setItem('currentUser', JSON.stringify(user.data));
            currentUserSubject.next(user.data);

            return user;
        }).catch(err=>{
            let history = useHistory();
            console.log(err);
            history.push('/login')
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}