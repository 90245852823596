import React, { Component } from 'react';
import Menu from './shared/Menu';
import './Layout.scss';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div >
        <Menu></Menu>
        <div className="containerGlobal">
        {this.props.children}

        </div>
         <div className={"showMenuButtFooter"}></div>
      </div>
    );
  }
}
