import React, {useEffect, useState} from 'react';
import './TreninkItem.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faInfoCircle, faSync, faUsers, faBan, faMap, faMapPin, faFileExcel} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import DataTableStyles from "../shared/DataTableStyle";
import DataTable from "react-data-table-component";


const TreninkItem = (props) => {

    const [showDetailInfo, setShowDetailInfo] = useState(false);
    const [showClenoveInfo, setShowClenoveInfo] = useState(false);
    const [clenoveNaNadchTren, setClenoveNaNadchTren] = useState([]);
    const [poznamka, setPoznamka] = useState("");
    const [stav, setStav] = useState(null);
    const [zadniClenove, setZadniClenove] = useState(false);

    useEffect(() => {
        if (props.treninkData && props.treninkData.length > 0) {
            setPoznamka(props.treninkData[0].poznamka);
            setStav(props.treninkData[0].stav);

        }
    }, [props]);


    const getClenyNaTren = () => {
        setZadniClenove(false);
        let data = {
            idTrenink: props.data.id,
            datum: props.datum

        }
        axios.post("api/treninky/clenoveNaNadchazejicimTreninku", data).then(res => {
            //console.log(res.data);
            setClenoveNaNadchTren(res.data);
            if (res.data.length === 0) {
                setZadniClenove(true);
            }
        });
    }

    const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false
    });

    const columns = [
        {
            name: '#',
            selector: (row, index) => {return (index + 1)},
            sortable: true,
            width: '40px'
        },
        {
            name: 'Jméno',
            selector: 'jmeno',
            sortable: true,
            width: '100px'
        },

        {
            name: 'Příjmení',
            selector: 'prijmeni',
            sortable: true,
            width: '100px'
        },
        {
            name: 'Stav',
            selector: d => {
                switch (d.stav) {
                    case 0:
                        return 'Ano';
                    case 1:
                        return 'Ne';
                    case 2:
                        return 'Nevím'
                }
            },
            sortable: true,
            width: '50px'
        },
        {
            name: 'Poznámka',
            selector: 'poznamka',
            sortable: true,
        }


    ];
    



    return (
        <div className="item">


            <div className="item2">
                <div className="item3" data-nazev={props.data.nazev} data-nazevmisto={props.data.mistoNazev} data-id={props.data.id} data-datum={props.datum} data-den={props.den}>
                    <div className="item3_2">
                        <span className="item4"> 
                            <FontAwesomeIcon onClick={() => {setShowDetailInfo(!showDetailInfo)}} icon={faInfoCircle} className="i"/>
                        </span>
                        <span className={"item4 pocetClenu"}>
                              {props.trener || props.trenerDashboard ?
                                  <span ><FontAwesomeIcon onClick={() => {setShowClenoveInfo(!showClenoveInfo)}} className="i" icon={faUsers}/> {props.data.aktualClenu[props.datum] ? props.data.aktualClenu[props.datum] : 0}/{props.data.maxClenu}</span>
                                  : null}
                        </span>
                        <span className="item4">
                            {props.admin || props.trener ? <span onClick={(e) =>props.handleEdit(e, 'editModal')}><FontAwesomeIcon style={{marginLeft: 15}} className="i"
                                                                                                             icon={faEdit}/></span> : null}
                        </span>
                        <span className="item4">
                            {props.admin || props.trener ? <span onClick={(e) =>props.handleEdit(e, 'export')}><FontAwesomeIcon style={{marginLeft: 5}} className="i"
                                                                                                             icon={faFileExcel}/></span> : null}
                        </span>
                        {props.data.aktivni === 1 ? null :
                            <span title={"Neaktivní trénink"} className={"item4 neaktivniIcon"}> <FontAwesomeIcon
                                icon={faBan} className="i"/></span>}
                        <span className="item4 time">{props.data.od.substring(0, 5)} - {props.data.do.substring(0, 5)}</span>


                        <span className="item4 nazev">{props.data.nazev} </span>
                    </div>
                    <div className="item3_2">
                      

                        <span className="item5 ctrl">
                                    

                           

                            


                            {props.clen ?
                                <>
                                        <div className="form-group">
                                            {/*<label htmlFor="note" className={"note"}>Poznámka:</label>*/}
                                            <input type="text" className="form-control" placeholder="Poznámka..." id="note"
                                                   onChange={(e) => setPoznamka(e.target.value)} value={poznamka}/>
                                        </div>
                                    <div className="stavBtns">
                                        <button className={stav === 0 ? "activeBtn" : null} onClick={() => {
                                            setStav(0);
                                            props.handleEdit(props.data.id, props.datum, 0, poznamka)
                                        }}>Ano
                                        </button>
                                        <button className={stav === 1 ? "activeBtn" : null} onClick={() => {
                                            setStav(1);
                                            props.handleEdit(props.data.id, props.datum, 1, poznamka)
                                        }}>Ne
                                        </button>
                                        <button className={stav === 2 ? "activeBtn" : null} onClick={() => {
                                            setStav(2);
                                            props.handleEdit(props.data.id, props.datum, 2, poznamka)
                                        }}>Nevím
                                        </button>
                                    </div>

                                </>


                                : null}




                                </span>
                    </div>
                    

                   


                </div>
                {showDetailInfo ?
                    <span className={"misto"}>

                                        <div className={"itemMisto"}>
                                            <FontAwesomeIcon className="i" icon={faMapPin}/>
                                            {props.data.mistoNazev}
                                        </div>
                                        <div className={"itemMisto"}>
                                            <textarea defaultValue={props.data.mistoPopis}></textarea>
                                        </div>
                                        <div className={"itemMisto map"}>
                                            <FontAwesomeIcon className="i" icon={faMap}/>
                                            <a target={"_blank"} rel="noopener noreferrer" href={props.data.mistoGurl}>Ukázat na mapě</a>
                                        </div>
                      




                                    </span>
                    : null}
                {showClenoveInfo ?
                    <div className={"misto"}>
                        <div className={"itemMisto"}>
                            <button className={"btn-1"} onClick={getClenyNaTren}><FontAwesomeIcon icon={faSync}
                                                                                                  className="i"/>Zobrazit
                                členy, kteří se k tomuto tréninku již vyjádřili
                            </button>
                        </div>
                        {clenoveNaNadchTren.length > 0 && showClenoveInfo ?
                            <span className={"clenoveTable"}>
                                <DataTable
                                    title="Výpis členů"
                                    data={clenoveNaNadchTren}
                                    columns={columns}
                                    paginationComponentOptions={pagination}
                                    pagination
                                    dense
                                    customStyles={DataTableStyles}
                                    paginationPerPage = {50}
                                    paginationRowsPerPageOptions = {[10,30,50,100]}
                                />
                            </span>
                            : null}
                        {zadniClenove && showClenoveInfo ?
                            <div className="zadnyClen">
                                K tréninku se zatím nikdo nevyjádřil.
                            </div>
                            : null}
                    </div>
                        
                : null}
                
            </div>

        </div>

    )
}

export default TreninkItem;