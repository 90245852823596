import React, {useEffect, useState} from 'react';
import axios from "axios";
import './CarouselTable.scss';
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAlert} from "react-alert";

const CarouselTable = () => {

    const alert = useAlert();
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {

        if (!loaded) {
            axios.get('api/CarouselImages').then((res) => {
                setItems(res.data);

                setLoaded(true);
            });
        }
    }, [loaded])

    const changeState = (id, isChecked) => {
        axios.post('api/CarouselImages/' + id + '/state/' + isChecked).then(() => {
            setLoaded(false);
            alert.success("Stav byl aktualizován");
        });
    }

    const remove = (id) => {
        if (window.confirm("Opravdu si přejete zvolenou fotku odebrat?") === true) {
            axios.delete('api/CarouselImages/' + id).then(() => {
                setLoaded(false);
                alert.success("Fotka byla smazána");
            });
        }
    }

    return (<div className={"carousel-table"}>

        {items.length === 0 && <div>Nejsou nahrané žádné fotky</div>}

        {items.map(function (e) {
            return <div className={"item"} key={"carIMAGE" + e.id}>
                <div className={"item-div"} >
                    <img src={e.path} alt={e.name}/>
                    <div className={"actions"}>
                        <div className={"chck"}>
                            <input type={"checkbox"} className={"showPublic"} checked={e.showInPublic} onChange={() => {
                                changeState(e.id, !e.showInPublic);
                            }}/>
                            Zobrazovat na veřejném webu
                           
                        </div>
                        <button className={"btn-delete"} onClick={() => remove(e.id)}>
                            <FontAwesomeIcon icon={faTrash} className="i"/> Smazat
                        </button>
                    </div>
                </div>
            </div>
        })}
    </div>)
}

export default CarouselTable;