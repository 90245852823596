import React,{useEffect, useState} from 'react';
import axios from "axios";
import TreninkItem from "../treninky-admin/TreninkItem";
import Time from "react-time-format";
import Modal from "../shared/Modal";
import DochazkaTrenink from "./DochazkaTrenink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import {useAlert} from "react-alert";
import {TreninkDochazkaExport} from "./TreninkDochazkaExport";



const Treninky = () =>{

    const [days, setDays] = useState([]);
    const [treninky, setTreninky] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [forModal, setForModal] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [modalExportOpen, setModalExportOpen] = useState(false);
    const [showFiltr, setShowFiltr] = useState(false);
    const [datumOd, setDatumOd] = useState('');

    const alert = useAlert();

    let cyklusDatum = '';
    const setCyklusDatum = (x) => {
        cyklusDatum = x;
    }

    const handleEdit = (e, where) => {
        let id = e.target.closest(".item3").getAttribute("data-id");
        let datum = e.target.closest(".item3").getAttribute("data-datum");
        let nazev = e.target.closest(".item3").getAttribute("data-nazev");
        let mistoNazev = e.target.closest(".item3").getAttribute("data-nazevmisto");
        id = parseInt(id);
        setForModal({
            id: id,
            datum: datum,
            nazev: nazev,
            mistoNazev: mistoNazev
        });
        if(where === 'editModal'){
            setModalOpen(true);
        } else if (where === 'export'){
            setModalExportOpen(true);
        }
        

    };

    const changaDate = (e) => {
        setDatumOd(e.target.value);
    }

    const filtrHandler = () => {
        if (datumOd === ''){
            alert.info("Vyberte datum od.");
            return false;
        }

        axios.get('api/treninky/nadchazejiciDnyOd/' + datumOd).then(res => {
            setDays(res.data);

        }).catch(err => {

        });
    }



    const loadData = () =>{


        axios.get('api/treninky/nadchazejiciDny').then(res => {
            //console.log(res.data);
            setDays(res.data);
            axios.get('api/treninky/getTreninkyByTrener').then(res => {
                setTreninky(res.data);
                setLoaded(true);
            }).catch(err => {

            });
        }).catch(err => {

        });


    }

    useEffect(() => {

        loadData();
        
        /*let dateFrom = moment().subtract(14,'d').format('YYYY-MM-DD');;
        axios.get('api/treninky/nadchazejiciDnyOd/' + dateFrom).then(res => {
            setDays(res.data);

        }).catch(err => {

        });*/

    }, []);

    return(
        <div className={"nadchazejiciTren"}>
            <h1>Tréninky</h1>
            <div className={"subMenu"}>
                <button className={"item"} onClick={() => {setShowFiltr(!showFiltr)}}><FontAwesomeIcon icon={faFilter} className={"i"} />Filtr</button>
            </div>
            { showFiltr ?
                <div className="filtr">
                    <div className="item filtrSearch">
                        <div className="searchInputs">
                            <div className="itemSearchInputs">
                                <label htmlFor="datumOD">Datum od:</label>
                                <input onChange={changaDate} type="date"  name={"datumOD"}  />

                            </div>

                        </div>
                        <div className="ctrl">
                            <button onClick={filtrHandler} className="btn-filtr" ><FontAwesomeIcon icon={faFilter} className="i"  />Filtruj</button>
                        </div>
                    </div>

                </div>
                : null}
            <div className={"treninkyVypis"}>

                {loaded && days.map((x,i) => {
                    return treninky.map((x2,i2) => {

                        return x2.den === x.denVtydnu ?
                            <div className={"item"} key={i2} data-date={x.datum}>


                                {cyklusDatum !== x.datum ? <h2>{x.denVtydnu} <Time value={x.datum} format="DD.MM.YY" /></h2> : null}
                                <TreninkItem    data={x2} den={x.denVtydnu} trener={true} handleEdit={(e,where) => handleEdit (e, where)} datum={x.datum} ></TreninkItem>
                                { setCyklusDatum(x.datum)}
                            </div>
                            : null
                    })
                })}
            </div>

            <Modal isOpen={modalOpen}  onClose={()=>{setModalOpen(false)}}>
                <DochazkaTrenink forModal={forModal} onClose={()=>{setModalOpen(false);}} />
            </Modal>
            <Modal isOpen={modalExportOpen} onClose={()=>{setModalExportOpen(false)}}>
                <TreninkDochazkaExport forModal={forModal} onClose={()=>{setModalExportOpen(false)}} />
            </Modal>




        </div>
    )
}

export default Treninky;