import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faPlusSquare,
    faEdit
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {useAlert} from "react-alert";
import DataTable from "react-data-table-component";
import DataTableStyles from "../shared/DataTableStyle";
import Modal from "../shared/Modal";
import NoveEditMisto from "./NoveEditMisto";





const Mista = () => {
    const [mista, setMista] = useState([]);
    const alert = useAlert();
    const [idMisto, setIdMisto] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const loadData = () => {
        setLoaded(false);
    }



    useEffect(() => {

    if(!loaded){
        axios.get('api/treninky/getMistaTreninkuAll').then(res => {
            setMista(res.data);
        }).catch(err => {
            alert.error('Nepovedlo se načíst data.', {timeout: 0});
            console.log(err);
        });
        setLoaded(true);
    }




    }, [alert, loaded]);

    const openModal = (id) => {

        setIdMisto(id);
        setModalOpen(true);
        //modalRef.current.openModal();
    };

    const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false
    });

    const columns =  [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
        },
        {
            name: 'nazev',
            selector: 'nazev',
            sortable: true,
        },

        {
            cell: (row) => <button className="btn-table-edit" onClick={()=>openModal(row.id)} data-id={row.id}><FontAwesomeIcon icon={faEdit}/> Upravit</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },


    ];



    return(
        <div>

                <div>
                    <h1>Místa tréninků</h1>
                    <div className="breadCrumb">
                        <div className="item"> <Link to="/treninky-admin">Tréninky</Link></div>
                        <div className="item"><FontAwesomeIcon icon={faChevronLeft} className="i" /></div>
                        <div className="item">Místa tréninků</div>
                    </div>
                    <div className="subMenu">
                        <Link className="item" to='/treninky-admin/mistaTreninku/noveMisto'><FontAwesomeIcon icon={faPlusSquare} className="i" />Nové místo</Link>

                    </div>
                </div>
            <div className="table">
                <DataTable
                    title="Výpis členů"

                    data={mista}
                    columns={columns}
                    paginationComponentOptions={pagination}
                    pagination
                    dense
                    customStyles={DataTableStyles}
                />
            </div>

            <Modal isOpen={modalOpen}  onClose={()=>{setModalOpen(false);}}><NoveEditMisto onClose={()=>{setModalOpen(false); loadData();}} edit={true} id={idMisto} /></Modal>








        </div>

    )
}

export default Mista;