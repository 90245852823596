import React, {useState, useEffect} from 'react';
import Time from 'react-time-format';
import parse from 'html-react-parser';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faInfoCircle, faSync, faMoneyBillWave, faUsers} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import DataTable from "react-data-table-component";
import DataTableStyles from "../shared/DataTableStyle";
import {useAlert} from "react-alert";
import './AkceItem.scss';
import Modal from "../shared/Modal";


const AkceItem = (props) => {

    const [showDetail, setShowDetail] = useState(false);
    const [showClenove, setShowClenove] = useState(false);
    const [zadniClenove, setZadniClenove] = useState(false);

    const [poznamka, setPoznamka] = useState("");
    const [stav, setStav] = useState(null);

    const [clenoveNaAkci, setClenoveNaAkci] = useState([]);

    const alert = useAlert();

    const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false
    });

    useEffect(() => {

        if (props.treninkData) {
            let item = props.treninkData.find(x => x.idAkce === props.data.id);

            if (item != null) {
                console.log(item);
                setPoznamka(item.poznamka);
                setStav(item.stav);
            }
        }
    }, [props.treninkData]);

    const columns = [
        {
            name: '#',
            selector: (row, index) => {return (index + 1)}, 
            sortable: true,
            width: '40px'
        },
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            width: '40px'
        },
        {
            name: 'Jméno',
            selector: 'jmeno',
            sortable: true,
            width: '100px'
        },
        {
            name: 'Příjmení',
            selector: 'prijmeni',
            sortable: true,
            width: '100px'
        },
        {
            name: 'Stav',
            selector: d => {
                switch (d.stav) {
                    case 0:
                        return 'Ano';
                    case 1:
                        return 'Ne';
                    case 2:
                        return 'Nevím'
                }
            },
            sortable: true,
            width: '50px'
        },
        {
            name: 'Poznámka',
            selector: 'poznamka',
            sortable: true,
        }


    ];

    const getClenyNaAkci = () => {
        setZadniClenove(false);
        axios.get('api/akce/getClenyNaAkci/' + props.data.id).then(res => {
            if (res.data.length > 0) {
                console.log(res.data);
                setClenoveNaAkci(res.data);

            } else {
                setZadniClenove(true);
                alert.info('K akci se zatím nikdo nevyjádřil.');
            }
        }).catch(err => {
            alert.error('Nepovedlo se načíst členy na akci.', {timeout: 0})
            console.log(err);
        });
    }


    return (

        <div className={"akceItem"} data-id={props.data.id}>
            <div className="item2">
                <div className={" date"}><Time value={props.data.od} format="DD.MM.YY"/> - <Time value={props.data.do}
                                                                                                 format="DD.MM.YY"/>
                </div>
                <div className={" nazev"}>{props.data.nazev}</div>
            </div>
            <div className="item2">
                <div className={" ctrl"}>
                    <FontAwesomeIcon onClick={() => {
                        setShowDetail(!showDetail)
                    }} icon={faInfoCircle} className="i"/>
                    <FontAwesomeIcon onClick={() => {
                        setShowClenove(!showClenove)
                    }} className="i" icon={faUsers}/>

                    {props.data.zpoplatnena ?
                        <span style={{marginRight: 25, fontWeight: 'bold'}}>
                            <FontAwesomeIcon style={{marginRight: 10}} icon={faMoneyBillWave}
                                             onClick={props.handleEdit}/>
                            {props.data.castka} Kč
                        </span>

                        : null}


                    {props.admin ?
                        <FontAwesomeIcon className="i" icon={faEdit} onClick={props.handleEdit}/>
                        : null}
                </div>


                {props.clen ?
                    <div className="item2">
                        <div className={" ctrl"}>
                            <div className={"dochazka"}>

                                {/*<input className={"checkbox"} type={"checkbox"}  checked={checked} onChange={() => setChecked(!checked)} onClick={() => props.handleEdit(props.data.id)}  />*/}
                                    <div className="form-group">
                                       
                                        <input type="text" className="form-control" id="note" placeholder="Poznámka"
                                               onChange={(e) => setPoznamka(e.target.value)} value={poznamka}/>
                                    </div>
                                <div className="stavBtns">
                                    <button className={stav === 0 ? "activeBtn" : null} onClick={() => {
                                        setStav(0);
                                        props.handleEdit(props.data.id, 0, poznamka)
                                    }}>Ano
                                    </button>
                                    <button className={stav === 1 ? "activeBtn" : null} onClick={() => {
                                        setStav(1);
                                        props.handleEdit(props.data.id, 1, poznamka)
                                    }}>Ne
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}


            </div>

            {showClenove ?

                <div className="expandContainer">
                    <div className={"itemAkce"}>
                        <button className={"btn-1"} style={{marginTop: 20}} onClick={getClenyNaAkci}><FontAwesomeIcon
                            icon={faSync} className="i"/>Členové, kteří se vyjádřili
                        </button>
                    </div>
                    <div className={"itemAkce"}>
                        {clenoveNaAkci.length > 0 ?
                            <DataTable
                                title="Výpis členů"
                                data={clenoveNaAkci}
                                columns={columns}
                                paginationComponentOptions={pagination}
                                pagination
                                dense
                                customStyles={DataTableStyles}
                                paginationPerPage = {50}
                                paginationRowsPerPageOptions = {[10,30,50,100]}
                            />

                            : null}
                        {zadniClenove  ?
                            <div className="zadnyClen">
                                K akci se zatím nikdo nevyjádřil.
                            </div>
                            : null}
                    </div>

                </div>


                : null}

            <Modal isOpen={showDetail} onClose={() => {
                setShowDetail(false);
            }}>
                <div className={"modalAkce"}>
                    <div className="uvod">
                        <div className="img"><img alt={props.data.titulek} src={props.data.headPhotoUrl}/></div>
                        <div className="uvodText">{props.data.kpopis}</div>
                    </div>
                    <div className={"dpopis itemAkce"}>
                        {parse(props.data.dpopis)}
                    </div>


                </div>


            </Modal>


        </div>

    )
}

export default AkceItem;