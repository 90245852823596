import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFilter, faPlusSquare} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import AkceItem from "./AkceItem";
import "./Akce.scss";
import Modal from "../shared/Modal";
import NovaEditAkce from "./NovaEditAkce";
import {useAlert} from "react-alert";
import BackToDashboard from "../shared/BackToDashboard";


const Akce = () => {

    const [akce, setAkce] = useState([]);
    const [id, setId] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [showFiltr, setShowFiltr] = useState(false);
    const [datumOd, setDatumOd] = useState('');

    const alert = useAlert();

    const handleEdit = (e) => {
        let id = e.target.closest(".akceItem").getAttribute("data-id");
        id = parseInt(id);
        setId(id);
        setModalOpen(true);

    };

    const changaDate = (e) => {
        setDatumOd(e.target.value);
    }

    const filtrHandler = () => {
        if (datumOd === ''){
            alert.info("Vyberte datum od.");
            return false;
        }
        axios.get('api/akce/getAllFrom/' + datumOd).then(res => {
            //console.log(res.data);
            setAkce(res.data.result);

        }).catch(err => {

        });
    }


    const loadData = () => {

        axios.get('api/akce/getAll').then(res => {
            setAkce(res.data.result);
        }).catch(err => {

        });

    }

    useEffect(() => {

        loadData();

    }, []);

    return(
        <div>
            <h1><BackToDashboard />Akce</h1>
            <div className="subMenu">
                <Link className="item" to='/akce/novaAkce'><FontAwesomeIcon icon={faPlusSquare} className="i" />Nová akce</Link>
                    <button className={"item"} onClick={() => {setShowFiltr(!showFiltr)}} ><FontAwesomeIcon icon={faFilter} className={"i"} /> Filtr</button>
            </div>
                { showFiltr ?
                    <div className="filtr">
                        <div className="item filtrSearch">
                            <div className="searchInputs">
                                <div className="itemSearchInputs">
                                    <label htmlFor="datumOD">Datum od:</label>
                                    <input onChange={changaDate} type="date"  name={"datumOD"}  />

                                </div>

                            </div>
                            <div className="ctrl">
                                <button onClick={filtrHandler} className="btn-filtr" ><FontAwesomeIcon icon={faFilter} className="i"  />Filtruj</button>
                            </div>
                        </div>

                    </div>
                    : null}
            <div className={"akceCont"}>
                {akce && akce.map((x,k)=>{
                    return (
                        <AkceItem handleEdit={handleEdit} setId={setId} data={x} key={k} admin={"true"} />
                        )
                })}
            </div>

            <Modal isOpen={modalOpen}  onClose={()=>{setModalOpen(false);}}><NovaEditAkce id={id}  edit={true} onClose={()=>{setModalOpen(false); loadData();}} /></Modal>



        </div>

    )
}

export default Akce;