import React from 'react';
import Novinky from "./Novinky";
import Akce from "../../akce-clen/Akce";
import Treninky from "../../treninky-clen/Treninky";

const ClenDashboard = () => {





    return(
        <div>
            <h1>Člen</h1>
            <div className={"dashBlocksContainer"}>
                <div className={"dashBlock-M"}>
                    <Novinky />

                </div>
                <div className={"dashBlock-M"}>
                    <Akce />



                </div>
                <div className={"dashBlock-M"}>
                    <Treninky />


                </div>
            </div>


        </div>
    )
}

export default ClenDashboard;