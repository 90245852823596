import React, {useEffect, useState} from 'react';
import axios from "axios";
import NovinkyItem from "./NovinkyItem";
import "./Novinky.scss";
import {faNewspaper} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const NovinkyDashboard = () => {

    const [novinky, setNovinky] = useState([]);
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        if(!loaded){
            axios.get('novinky/filtr?paggination=false&last=5').then(res => {setNovinky(res.data); setLoaded(true); });
        }

    }, [loaded]);



    return(
        <div className={"novinkyClenDashboard"}>
            <h1><FontAwesomeIcon icon={faNewspaper} className={"i"} /> Nejnovější novinky</h1>

            <div className={"container"}>
                    { novinky.length > 0 && novinky.map(x => {return (<NovinkyItem key={x.id} data={x}  />)})}


            </div>




        </div>
    )
}

export default NovinkyDashboard;