import React, { useState} from 'react';
import axios from 'axios';
import {useAlert} from "react-alert";
import './Finance.scss'
import 'react-tabs/style/react-tabs.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {useForm} from "react-hook-form";
import InputError from "../shared/InputError";
import messages from "../shared/InputErrorMessages";
import moment from "moment";
import {PlatbaDetailEmail} from "./PlatbaDetailEmail";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {PlatbaZa} from "./PlatbaZa";


const PlatbaDetail = (props) => {
    
    const [showEmail, setShowEmail] = useState(false);

    const {register, handleSubmit, errors, } = useForm({
        defaultValues: {
            castka: props.platba.castka,
            variabilniSymbol: props.platba.variabilniSymbol,
            datumVytvoreni: moment(props.platba.datumVytvoreni).format('YYYY-MM-DD'),
            datumPlatba: moment(props.platba.datumPlatba).format('YYYY-MM-DD'),
            platbaZa: props.platba.platbaZa,
            stav: props.platba.stav,
            poznamka: props.platba.poznamka,
            splatnostDo: moment(props.platba.splatnostDo).format('YYYY-MM-DD'),
            castkaZaplaceno: props.platba.castkaZaplaceno ?? 0,
            id: props.platba.id

        }
    });

    const alert = useAlert();


    const handleSmazat = () => {
        if (window.confirm("Opravdu chcete smazat tuto platbu?")) {
            axios.delete('finance/platba/' + props.id).then(res => {
                if (res.data.result === 0) {
                    alert.success('Ok.');
                    props.parentFilter(props.actualPage);
                    props.onClose();

                } else {
                    alert.error('Došlo k chybě.', {timeout: 0});
                }
            }).catch(() => {
                alert.error('Došlo k chybě.', {timeout: 0});
            })
        }

    }
    const onSubmit = (data, event) => {
        axios.put('api/platby/platba', data).then(res => {
            if (res.data.state === 0) {
                alert.success('Ok');
                props.parentFilter(props.actualPage);
                props.onClose();

            } else {
                alert.error('Došlo k chybě.', {timeout: 0});
            }
        }).catch(() => {
            alert.error('Došlo k chybě.', {timeout: 0});
        })
        event.preventDefault();
    }


    return (
        <div className={"finance"}>
            <h1>Detail platby</h1>
            <div className={"form"}>
                <div className={"body"}>
                    <div className={"item"}>
                        <label>ID</label>
                        <input type="text" readOnly="readonly" name="id" ref={register({required: true})}/>
                        {errors.id && <InputError message={messages.required}></InputError>}
                    </div>
                    <div className={"item"}>
                        <label>Částka</label>
                        <input type="number" name="castka" ref={register({required: true, min: 0})}/>
                        {errors.castka &&
                            <InputError message="Částka musí být číslo, větší nebo rovno  0"></InputError>}
                    </div>
                    <div className={"item"}>
                        <label>Částka zaplaceno</label>
                        <input type="number"  name="castkaZaplaceno" ref={register({required: true, min: 0})}/>
                        {errors.castkaZaplaceno &&
                            <InputError message="Částka musí být číslo, větší nebo rovno  0"></InputError>}
                    </div>
                    <div className={"item"}>
                        <label>Variabilní symbol</label>
                        <input type="text" readOnly="readonly" name="variabilniSymbol"
                               ref={register({required: true})}/>
                        {errors.variabilniSymbol && <InputError message={messages.required}></InputError>}
                    </div>
                    <div className={"item"}>
                        <label>Datum vytvoření</label>
                        <input type="date" readOnly="readonly" name="datumVytvoreni" ref={register({required: true})}/>
                        {errors.datumVytvoreni && <InputError message={messages.required}></InputError>}
                    </div>
                    <div className={"item"}>
                        <label>Datum splatnost</label>
                        <input type="date" name="splatnostDo" ref={register({required: true})}/>
                        {errors.splatnostDo && <InputError message={messages.required}></InputError>}
                    </div>
                    <div className={"item"}>
                        <label>Datum platba</label>
                        <input type="date" name="datumPlatba" ref={register()}/>

                    </div>
                    <div className={"item"}>
                        <label>Platba za</label>
                        {/*{props.platba.platbaZa === 1 ?
                            <input type="text" readOnly="readonly" value="Členský poplatek"/> : null}
                        {props.platba.platbaZa === 2 ? <input type="text" readOnly="readonly" value="Akci"/> : null}
                        {props.platba.platbaZa === 0 ? <input type="text" readOnly="readonly" value="Ostatní"/> : null}*/}
                        <PlatbaZa platbaZa={props.platba.platbaZa} input={true} />
                    </div>
                    <div className={"item"}>
                        <label>Stav</label>
                        <select name="stav" ref={register()}>
                            <option value={1}>Nezaplaceno</option>
                            <option value={2}>Zaplaceno</option>
                            <option value={0}>Storno</option>
                        </select>

                    </div>
                    <div className={"item"}>
                        <label>Poznámka</label>
                        <textarea name="poznamka" maxLength={1000} ref={register()} value={props.platba.poznamka}/>
                    </div>
                </div>
                <div className="footer">
                    <div className="ctrl">
                        <div className="item">
                            <button
                                className="btn-delete"
                                onClick={() => {
                                    handleSmazat()
                                }}>
                                <FontAwesomeIcon className="i" icon={faTrash}/>
                                Smazat
                            </button>
                        </div>
                        <div className="item">
                            <button
                                className="btn-save"
                                onClick={handleSubmit(onSubmit)}
                            >
                                <FontAwesomeIcon className="i" icon={faSave}/>
                                Uložit
                            </button>
                        </div>
                        <div className="item">
                            <button
                                className="btn-otherActionOrange"
                                onClick={() => setShowEmail(!showEmail)}
                            >
                                <FontAwesomeIcon className="i" icon={faEnvelope}/>
                                Email
                            </button>
                        </div>
                       
                        
                        
                    </div>

                </div>
            </div>

            { showEmail ? <PlatbaDetailEmail platba={props.platba} hide={() => setShowEmail(false)} /> : null }

        </div>
    )
}

export default PlatbaDetail;