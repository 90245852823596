import React, {useState, useEffect} from 'react';
import {faChevronLeft, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from 'react-router-dom';
import '../editor.scss';
import axios from "axios";
import {useForm} from "react-hook-form";
import Select from 'react-select'
import './DokumentyAddOrUpdate.scss';
import {useAlert} from 'react-alert';
import InputError from "../shared/InputError";
import messages from "../shared/InputErrorMessages";


const DokumentyAddOrUpdate = ({id, close}) => {

    const alert = useAlert();
    const [edit] = useState(id !== 0);
    const [loaded, setLoaded] = useState(false);
    const [kategorie, setKatgorie] = useState([]);
    const [file, setFile] = useState([])
    const {register, handleSubmit, errors, setValue, reset} = useForm({
        defaultValues: {
            nazev: "",
            popis: "",
            kategorie: "0",
            zobrazovat: false
        }
    });

    const [selectedCategory, setSelectedCategory] = useState(0);

    useEffect(() => {

        const getKategorie = () => {
            axios.get('/dokumenty/kategorie').then(res => {
                setKatgorie(res.data);
            })
        }

        const getDokument = () => {
            axios.get("/dokumenty/" + id).then(res => {
                setValue("nazev", res.data.nazev);
                setValue("popis", res.data.popis);
                setSelectedCategory(res.data.dokumentyKategorieId);
                setValue("zobrazovat", res.data.verejny);

            }).catch(err => {
                alert.error('Nepovedlo se načíst data.', {timeout: 0});
            });

        }

        if (!loaded) {
            getKategorie();
            setLoaded(true);
        }

        if (id && !loaded) {
            getDokument();
            setLoaded(true);
        }
    }, [loaded, id, setValue, alert]);

    const onSubmit = async (data, e) => {

        if (selectedCategory === 0) {
            alert.error('Vyberte kategorii', {timeout: 0});
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        await axios.post('/dokumenty/ulozit/' + id + '/' + data.nazev + '/' + data.zobrazovat + '/' + selectedCategory + '/' + data.popis, file)
            .then(function (res) {
                if (res.data.result === 0) {
                    reset();
                    close();
                    alert.success(res.data.message);
                } else {
                    alert.error(res.data.message, {timeout: 0});
                }
            }).catch(function (err) {
            }).then(res => {

            });

    }


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("File", file);

        setFile(formData);

        e.preventDefault();
        e.stopPropagation();
    };

    const handleSelect = (e) => {
        setSelectedCategory(e.value);
    }

    const handleRemove = () => {

        let r = window.confirm("Opravdu si přejete uvedený dokument smazat?");
        if (r === true) {
            axios.delete("/dokumenty/" + id).then(res => {
                if (res) {
                    alert.success('Dokument byl smazán');
                    close();
                } else {
                    alert.error('Dokument se nepovedlo vymazat', {timeout: 0});
                }
            }).catch(err => {
                alert.error('Dokument se nepovedlo vymazat', {timeout: 0});
            });
        }
    }

    return (

        <div className={"dokumenty-add"}>
            {!id ? <h1>Přidat dokument</h1> : <h1>Upravit dokument</h1>}
            {!id && <div className="breadCrumb">
                <div className="item"><Link to="/dok">Dokumenty</Link></div>
                <div className="item"><FontAwesomeIcon icon={faChevronLeft} className="i"/></div>
                <div className="item">Nový dokument</div>
            </div>
            }
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={"form"}>
                    <div className="header"></div>
                    <div className="body">
                        <div className={"item"}>
                            <label>Název</label>
                            <input type={"text"} name={"nazev"} maxLength="100"
                                   ref={register({required: true})}/>
                            {errors.nazev &&
                            <InputError message={messages.required}></InputError>}
                        </div>
                        <div className={"item"}>
                            <label>Popis</label>
                            <textarea name={"popis"} maxLength={"255"} ref={register({required: true})}></textarea>
                            {errors.popis &&
                            <InputError message={messages.required}></InputError>}
                        </div>
                        {!edit && <div className={"item"}>
                            
                            aa
                            <label>Soubor</label>
                            <input id='img' type="file"
                                   onChange={(e) => handleImageChange(e)}
                                   accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                        </div>}
                        <div className={"item"}>
                            <label>Kategorie</label>
                            <Select name={"kategorie"}
                                    className={"select"}
                                    value={kategorie.filter(option => parseInt(option.value) === parseInt(selectedCategory))}
                                    onChange={(e) => {
                                        handleSelect(e)
                                    }} options={kategorie}/>
                        </div>
                        <div className={"item"}>
                            <label>Zobrazovat na webu</label>
                            <input type={"checkbox"} name={"zobrazovat"} ref={register}/>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="ctrl">
                            <button type="submit" className="item btn-save">
                                <FontAwesomeIcon icon={faSave} className="i"/>Uložit
                            </button>
                            {id ?
                            <button type="button" className="item btn-delete" onClick={handleRemove}>
                                <FontAwesomeIcon icon={faTrash} className="i"/>Smazat
                            </button> : null
                            }
                        </div>

                    </div>
                </div>
            </form>
        </div>
    );

}
export default DokumentyAddOrUpdate;