import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBan, faPlusSquare} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import TreninkItem from './TreninkItem';
import './Treninky.scss';
import Modal from "../shared/Modal";
import NovyTrenink from "./NovyTrenink";
import BackToDashboard from "../shared/BackToDashboard";

const Treninky = () => {

    const [data, setData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [id, setId] = useState(0);
    const dny = ['po','út','st','čt','pá','so','ne']
    
    const loadData = () => {

        axios.get('api/treninky/getAllActive').then(res => {
            //console.log(res.data);
           setData(res.data);
        }).catch(err => {

        });

    }
    const handleEdit = (e) => {
        let id = e.target.closest(".item3").getAttribute("data-id");
        console.log(id);
        id = parseInt(id);
        setId(id);
        setModalOpen(true);

    };

    const loadAll = () => {
        axios.get('api/treninky/getAll').then(res => {
            //console.log(res.data);
            setData(res.data);
        }).catch(err => {

        });
    }


    useEffect(() => {

      loadData();


        
    }, []);

 

    return(
        <div>
             <h1><BackToDashboard />Tréninky</h1>
             <div className="subMenu">
                <Link className="item" to='/treninky-admin/novyTrenink'><FontAwesomeIcon icon={faPlusSquare} className="i" />Nový trénink</Link>
                 <Link className="item" to='/treninky-admin/mistaTreninku'><FontAwesomeIcon icon={faPlusSquare} className="i" />Místa tréninků</Link>
                 <button onClick={() => loadAll()} className={"item"}><FontAwesomeIcon icon={faBan} className="i" />Načti i neaktivní</button>
            </div>
            
            <div className="treninkyVypis">
                {
                    dny.map((x,k) =>{
                        return <div className={"item"} key={k}>
                            <h2>{x}</h2>
                            {data.map((x2,k2) => {
                                return x === x2.den ?<TreninkItem data={x2} key={k2} admin={true} handleEdit={handleEdit}></TreninkItem>
                                    : null
                            })}
                        </div>



                    })
                }




             
            </div>
            <Modal isOpen={modalOpen}  onClose={()=>{setModalOpen(false);}}><NovyTrenink id={id} edit={true} onClose={()=>{setModalOpen(false); loadData();}} /></Modal>
        </div>
        
    )
}

export default Treninky;