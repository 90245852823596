import React, {useState, useEffect} from 'react';
import {faDownload, faPlusSquare, faPen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from 'react-router-dom';
import DataTable from "react-data-table-component";
import DataTableStyles from "../shared/DataTableStyle";
import axios from "axios";
import Modal from "../shared/Modal";
import DokumentyKategorie from "./DokumentyKategorie";
import DokumentyAddOrUpdate from "./DokumentyAddOrUpdate";
import BackToDashboard from "../shared/BackToDashboard";
import {authenticationService} from "../../helpers/AuthenticationService";

const Dokumenty = (props) => {

    const [dokumenty, setDokumenty] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [categoryModalOpen, setCategoryModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const currentUser = authenticationService.currentUserValue;
    
    
    useEffect(() => {
        const load = () => {
            axios.get('/dokumenty').then(res => {
                setDokumenty(res.data);
            });
        }

        if (!loaded) {
            load();
            setLoaded(true);
        }
    }, [loaded]);

    const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false
    });

    const columns = (clickHandler => [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
        },
        {
            name: 'Název',
            selector: 'nazev',
            sortable: true,
        },
        {
            cell: (row) => currentUser.admin ? <button className="btn-table-edit" onClick={() => {handleEditClick(row)}}><FontAwesomeIcon
                icon={faPen}/> Upravit</button> : null,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => <button className="btn-table-edit" onClick={() => {handleButtonClick(row)}}><FontAwesomeIcon
                icon={faDownload}/> Stáhnout</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]);

    const handleEditClick = (state) => {
        setSelectedId(state.id);
        setEditModalOpen(true);
    }

    const handleButtonClick = (state) => {
        const config = { responseType: 'blob' };
        let fileName = state.fileName;
        axios.get("/dokumenty/download?fileName=" + fileName, config).then((res)=> {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }).catch(() =>{

        });
    };

    return (
        <div>
            <h1><BackToDashboard />Dokumenty</h1>
            
            {currentUser.admin &&
            <div className={"subMenu"}>
                <button onClick={() => {setSelectedId(0); setEditModalOpen(true)}} className={"item"} > <FontAwesomeIcon icon={faPlusSquare} className="i"/>Nový dokument</button>
                <Link className={"item"} to={"#"} onClick={() => { setCategoryModalOpen(true) }}><FontAwesomeIcon icon={faPlusSquare} className="i"/>Kategorie</Link>
            </div>}

            <DataTable
                title="Výpis dokumentů"

                data={dokumenty}
                columns={columns(handleButtonClick)}
                paginationComponentOptions={pagination}
                pagination
                dense
                customStyles={DataTableStyles}
            />

            <Modal isOpen={categoryModalOpen} onClose={() => {
                setCategoryModalOpen(false);
            }}>
                <DokumentyKategorie />
            </Modal>

            <Modal isOpen={editModalOpen} onClose={() => {setEditModalOpen(false); setLoaded(false)}}>
                <DokumentyAddOrUpdate id={selectedId} close={() => {setEditModalOpen(false); setLoaded(false)}}  />
            </Modal>

        </div>
    );

}
export default Dokumenty;