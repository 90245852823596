import React, {useState, useEffect} from 'react';
import axios from "axios";



const SelectTreninkoveSkupiny = (props) => {

    const [selectedValue, setSelectedValue] = useState(props.default);
    const [data, setData] = useState([]);


    const onChange = (e) =>{
        setSelectedValue(e.target.value);
    }

    useEffect(() =>{
        axios.get('api/treninkoveSkup/getAllActive').then(res => {
            setData(res.data);
        }).catch(err => {

        });
    }, []);


    return(
        <select name={props.name} onChange={onChange}   ref={props.reff} value={selectedValue}>
            {props.allowNull ?
                <option key={1} value={0}>Bez tréninkové skupiny</option>
                : null}
            {data.map((x, k) =>
                <option key={k+1} value={x.id}>{x.nazev}</option>
            )}
        </select>
        
    )
}

export default SelectTreninkoveSkupiny;