import React, {useEffect, useState} from 'react';
import {useAlert} from "react-alert";
import './ClenovePlatReg.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle, faUserPlus} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import Moment from "react-moment";

const ClenovePlatReg = () => {

    const [data, setData] = useState([]);
    const [detail, setDetail] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        const loadData = async () => {
            axios.get('api/clenove/filtr?pouzeAktivni=true&konciciPlatnostReg=true').then(res => {

                setData(res.data);

            }).catch(err => {
                alert.error('Nepovedlo se načíst členy z DB.', {timeout: 0});
                console.log(err);

            });
        }

        loadData();


    }, [alert]);


    return (


        data.length > 0 ?
            <div className={"dash_clenovePlatReg"}>
                <div className={"header"}>
                    <span className={"text"}><FontAwesomeIcon icon={faUserPlus} className={"i"}/>Počet členů, kterým končí platnost reg. pop.:</span><span
                    className={"no"}>{data.length}</span>
                    <span className={"ctrl"} onClick={() => {
                        setDetail(!detail)
                    }}><FontAwesomeIcon icon={faInfoCircle} className={"i"}/></span>
                </div>
                {
                    detail ?
                        <div className={"container"}>
                            {data.map((x, k) => {
                                return (<div key={k} className={"item"}>
                            <span className={"item2 title"}>
                                {x.jmeno} {x.prijmeni} <Moment format="YYYY" date={x.datumNar}/>
                            </span>
                                    <span className={"item2 timeSpan"}><Moment  unit={"days"} to={x.platRegPruk}/></span>





                                </div>)
                            })}
                        </div>

                        : null
                }

            </div>


            :
            <div className={"dash_clenovePlatReg"}>
                <div className={"header"}>
                    <span className={"text"}>Počet členů, kterým končí platnost reg. pop.:</span> <span
                    className={"val"}>0</span>
                </div>
            </div>


    )
}

export default ClenovePlatReg;