import React, {useState, useEffect, useCallback} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSave, faChevronLeft, faMinusSquare, faPlus} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {useForm} from "react-hook-form";
import InputError from '../shared/InputError';
import messages from '../shared/InputErrorMessages';
import SelectTrenSkupina from '../treninkovaSkupina-admin/SelectTrenSkupina';
import Select from "react-select";
import DataTable from "react-data-table-component";
import DataTableStyles from "../shared/DataTableStyle";
import SelectMistaTreninku from "./SelectMistaTreninku";
import {useAlert} from "react-alert";




const NovyTrenink = (props) => {


    const {register, handleSubmit, errors, setValue, reset} = useForm({
        defaultValues: {nazev: "", den: "po", od: "08:00", do: "09:00",  popis: ""}
    });

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState(0);
    const [clenove, setClenove] = useState([]);
    const [selectedDen, setSelectedDen] = useState("PO");
    const [selectedTrenS, setSelectedTrenS] = useState(0);
    const [misto, setMisto] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [aktivni, setAktivni] = useState(0);

    const alert = useAlert();


    const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false
    });

    function handleButtonClick (idZaznam) {


        axios.delete('api/treninky/odebratClenaZTren/' + idZaznam).then(res => {
            if(res.data.result === 1){

                getClenoveNaTreninku();
            } else {
                alert.error(res.data.message);
                console.log(res.data.exception);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const columns = (  handleButtonClick => [
        {
            name: 'ID',
            selector: 'idClen',
            sortable: true,
        },
        {
            name: 'Jméno',
            selector: 'jmeno',
            sortable: true,
        },
        {
            name: 'Příjmení',
            selector: 'prijmeni',
            sortable: true,
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
        },
        {
            name: 'Ročník',
            selector: 'datumNar',
            sortable: true,
        },
        {
            cell: (row) => <button type={"button"} data-idzaznam={row.idZaznam} className="btn-table-edit" onClick={()=>{handleButtonClick(row.idZaznam)}}><FontAwesomeIcon className={"i"} icon={faMinusSquare}/> Odebrat</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },


    ]);

    const denSelectChange = (e) =>{
        setSelectedDen(e.target.value);
    }



    const onSubmit = (data) => {
        if(props.edit){
            axios.put('api/treninky/editTrenink', data).then(res => {
                //this.props.alert.success('Produkt byl přidán');

                if(res.data.result === 1){
                    alert.success(res.data.message);
                    props.onClose();
                }else{
                    alert.error(res.data.message, {timeout:0});
                    console.log(res.data.exception)
                }


            }).catch(err => {
                alert.error('Došlo k chybě na serveru při ukládání', {timeout:0});
                console.log(err)
            });

        }else{
            axios.post('api/treninky/ulozTrenink', data).then(res => {
                if(res.data.result === 1){
                    alert.success(res.data.message);
                    reset();
                }else{
                    alert.error(res.data.message, {timeout:0});
                    console.log(res.data.exception)
                }

            }).catch(err => {
                alert.error('Došlo k chybě na serveru při ukládání', {timeout:0});
                console.log(err)
            });
        }


    }


    const getDataForSelect = useCallback( () =>{
        axios.get('api/clenove/clen-select/').then(res => {

            setOptions(res.data);


        }).catch(err => {
            console.log(err);
        });
    }, [])



    const getClenoveNaTreninku = useCallback (() => {
        axios.get('api/treninky/clenoveNaTreninku/' + props.id).then(res => {
            setClenove(res.data)


        }).catch(err => {

        });
    }, [props.id])

    function pridatClenaHandler() {
        let data = {};
        data.idClen = selectedOptions;
        data.idTrenink = props.id;
        axios.post('api/treninky/pridatClenaNaTren', data).then(res => {
            //this.props.alert.success('Produkt byl přidán');
            if(res.data.result >= 2){
                alert.success(res.data.message);
                getClenoveNaTreninku();
            } else if (res.data.result === 1){
                alert.info(res.data.message);
            } else {
                alert.error(res.data.message);
                console.log(res.data.exception);
            }


        }).catch(err => {

        });

    }

    const changeSelectHandler = (data) =>{
        setSelectedOptions(data.value);

    }

    /*const deleteTrenink = () =>{
        axios.delete('api/treninky/smazTrenink/' + props.id).then(res =>{
            if(res.data.result === 1){
                alert(res.data.message);
                props.onClose();

            } else {
                alert(res.data.message);
                console.log(res.data.exception);
            }
        }).catch(err => {

        });
    }*/




    


    useEffect(() => {

        const loadData = () => {


            if(props.edit){
                axios.get('api/treninky/detailTrenink/' + props.id).then(res => {
                    //console.log(res.data);
                    setValue("id", props.id);
                    setValue("nazev", res.data.nazev);
                    setSelectedDen(res.data.den);
                    setSelectedTrenS(res.data.trens);
                    setValue("od", res.data.od.substring(0, res.data.od.length-3));
                    setValue("do", res.data.do.substring(0, res.data.do.length-3));
                    setValue("popis", res.data.popis);
                    setMisto(res.data.misto);
                    setAktivni(res.data.aktivni);
                    setLoaded(true);

                }).catch(err => {

                });

                getDataForSelect();
                getClenoveNaTreninku();
            }
            else{
                setLoaded(true);
            }

        }

    if(!loaded){
        loadData();
    }


    }, [loaded, getClenoveNaTreninku, getDataForSelect, props.edit, props.id, setValue]);




    return(
        <div>
            {props.edit
                ?
                <div>
                    <h1>Editovat trénink</h1>
                    
                </div>
                :
                <div>
                    <h1>Nový trénink</h1>
                    <div className="breadCrumb">
                        <div className="item"> <Link to="/treninky-admin">Tréninky</Link></div>
                        <div className="item"><FontAwesomeIcon icon={faChevronLeft} className="i" /></div>
                        <div className="item">Nový trénink</div>
                    </div>
                </div>
            }


            
            <div className="form">
                <div className="header">
                    
                </div>
                <div className="body">
                    {props.edit ?
                        <div className="item">
                            <label>Id*</label>
                            <input type="text" readOnly={"readonly"} name="id"  ref={register()}/>

                        </div>
                        : null}
                    <div className="item">
                        <label>Název*</label>
                        <input type="text" name="nazev" maxLength="100" ref={register({required: true})}/>
                            {errors.nazev &&
                            <InputError message={messages.required}></InputError>}
                    </div>
                    <div className="item">
                        <label>Den v týdnu*</label>
                        <select value={selectedDen} onChange={denSelectChange}  name="den" ref={register()}>
                            <option value={"po"}>PO</option>
                            <option value={"út"}>ÚT</option>
                            <option value={"st"}>ST</option>
                            <option value={"čt"}>ČT</option>
                            <option value={"pá"}>PÁ</option>
                            <option value={"so"}>SO</option>
                            <option value={"ne"}>NE</option>

                        </select>

                    </div>
                    <div className="item">
                        <label>Od*</label>
                        <input type="time" name="od" ref={register()} ></input>
                    </div>
                    <div className="item">
                        <label>Do*</label>
                        <input type="time" name="do" ref={register()}></input>
                    </div>
                    {loaded ?
                        <div className="item">
                        <label>Tréninková s.</label>
                            <SelectTrenSkupina name="trens" default={selectedTrenS} reff={register()} ></SelectTrenSkupina>
                        </div>
                        : null}
                    {loaded ?
                        <div className="item">
                            <label>Místo</label>
                            <SelectMistaTreninku name="misto" default={misto} reff={register()} />
                        </div>
                    : null}
                    {loaded ?
                        <div className="item">
                            <label>Aktivní</label>
                            <select name={"aktivni"} defaultValue={aktivni} ref={register()}>
                                <option value={1}>Ano</option>
                                <option value={0}>Ne</option>
                            </select>
                        </div>
                        : null}

                    <div className="item">
                    <label>Popis</label>
                        <textarea name="popis" maxLength="2000" ref={register()} />
                    </div>
                    
                    
                    
                </div>
                <div className="footer">
                    <div className="ctrl">
                        <button type="button" onClick={handleSubmit(onSubmit)} className="item btn-save"><FontAwesomeIcon icon={faSave} className="i" />Uložit</button>
                        {/*{props.edit ?
                            <button type="button" onClick={deleteTrenink} className="item btn-delete"><FontAwesomeIcon icon={faTrash} className="i" />Smazat</button>: null}*/}
                    </div>
                </div>
                  
            </div>
            {props.edit ?
                <div>
                    <h2>Přidat člena na trénink</h2>
                    <div>
                        <Select
                            onChange={changeSelectHandler}
                            closeMenuOnSelect={true}
                            options={options}
                        />
                        <button className="btn-1" onClick={pridatClenaHandler}><FontAwesomeIcon className={"i"} icon={faPlus}/>Přidat</button>
                    </div>

                    <DataTable
                        title="Seznam členů přidaných na tréninku"
                        columns={columns(handleButtonClick)}
                        data={clenove}
                        paginationComponentOptions={pagination}
                        pagination
                        dense
                        customStyles={DataTableStyles}
                    />

                </div>
                : null}
           
        </div>
        
    )
}

export default NovyTrenink;