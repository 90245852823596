import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMoneyBill} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Modal from "../shared/Modal";
import {useAlert} from "react-alert";
import DataTable from "react-data-table-component";
import DataTableStyles from "../shared/DataTableStyle";
import './Finance.scss'
import 'react-tabs/style/react-tabs.css';
import ClenPlatby from "./ClenPlatby";

const ClenoveTab = () => {

    const [clenove, setClenove] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedClenId, setSelectedClenId] = useState(0);
    const [selectedClen, setSelectedClen] = useState(null);
    const [filter, setFilter] = useState({
        maZaplacenyAktualniRok: true,
        pouzeNezaplacenyAktualniRok: false,
        nemaVygenerovanouPlatbuAktualniRok: false
    });

    const alert2 = useAlert();

    useEffect(() => {

        const load = () => {
            axios.get('finance/clenove?maZaplacenyAktualniRok=' + filter.maZaplacenyAktualniRok + '&pouzeNezaplacenyAktualniRok=' + filter.pouzeNezaplacenyAktualniRok + '&nemaVygenerovanouPlatbuAktualniRok=' + filter.nemaVygenerovanouPlatbuAktualniRok).then(res => {
                setClenove(res.data);
            }).catch(() => {
                alert2.error('Nepovedlo se načíst data.', {timeout: 0});
            })
        }

        if (!loaded) {
            load();
            setLoaded(true);
        }
    }, [loaded, filter.maZaplacenyAktualniRok, filter.pouzeNezaplacenyAktualniRok,  filter.nemaVygenerovanouPlatbuAktualniRok, alert2]);

    const columns = (() => [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            width: '80px'
        },
        {
            name: 'Jméno',
            selector: d => d.jmeno ,
            sortable: true,
            width: '120px'
        },
        {
            name: 'Příjmení',
            selector: d =>  d.prijmeni,
            sortable: true,
            width: '120px'
        },
        {
            name: 'Tren. skupina',
            selector: d => d.trenSkupiny ? d.trenSkupiny.nazev : null,
            sortable: true
        },
      
        {
            cell: (row) => <button className="btn-table-edit" onClick={() => {
                handleButtonClick(row)
            }}><FontAwesomeIcon
                icon={faMoneyBill}/> Platby</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]);


    const handleButtonClick = (state) => {
        setSelectedClenId(state.id);
        setSelectedClen(state);
        setModalOpen(true);
    };


    const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false
    });

    return (
        <div>
            <div className={"filter"}>


                <div className={"filter-item"}>
                    
                    <label>
                        <input type={"radio"} checked={filter.maZaplacenyAktualniRok} onChange={() => {
                            setFilter({
                                maZaplacenyAktualniRok: !filter.maZaplacenyAktualniRok,
                                pouzeNezaplacenyAktualniRok: false,
                                nemaVygenerovanouPlatbuAktualniRok: false
                            });
                            setLoaded(false);
                        }}/>
                        Členové, kteří mají zaplacený aktuální rok
                    </label>
                </div>
                <div className={"filter-item"}>
                   
                    <label>
                        <input type={"radio"} checked={filter.pouzeNezaplacenyAktualniRok} onChange={() => {
                            setFilter({
                                pouzeNezaplacenyAktualniRok: !filter.pouzeNezaplacenyAktualniRok,
                                maZaplacenyAktualniRok: false,
                                nemaVygenerovanouPlatbuAktualniRok: false
                            });
                            setLoaded(false);
                        }}/>
                        Členové, kteří nemají zaplacený aktualni rok 
                    </label>
                </div>
                <div className={"filter-item"}>
                   
                    <label> 
                        <input type={"radio"} checked={filter.nemaVygenerovanouPlatbuAktualniRok} onChange={() => {
                        setFilter({
                            nemaVygenerovanouPlatbuAktualniRok: !filter.nemaVygenerovanouPlatbuAktualniRok,
                            pouzeNezaplacenyAktualniRok: false,
                            maZaplacenyAktualniRok: false
                        });
                        setLoaded(false);
                    }}/>
                        Členové, kteří nemají vygenerovanou platbu za tento rok 
                    </label>
                </div>


                <DataTable
                    data={clenove}
                    columns={columns()}
                    paginationComponentOptions={pagination}
                    pagination
                    dense
                    customStyles={DataTableStyles}
                    paginationPerPage = {200}
                    paginationRowsPerPageOptions = {[20,50,100,200,300]}
                />

                <Modal isOpen={modalOpen} clen={selectedClen} onClose={() => {
                    setModalOpen(false)
                }}>
                    <ClenPlatby selectedClenId={selectedClenId} clen={selectedClen} />
                </Modal>


            </div>
        </div>
    )
}

export default ClenoveTab;