import React, {useState, useEffect} from 'react';
import {faPen, faSave, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../editor.scss';
import axios from "axios";
import {useForm} from "react-hook-form";
import {useAlert} from "react-alert";
import DataTable from "react-data-table-component";
import DataTableStyles from "../shared/DataTableStyle";
import Moment from "react-moment";

const DokumentyKategorie = () => {

    const alert = useAlert();

    const [showForm, setShowForm] = useState(false);
    const [id, setId] = useState(0);
    const [kategorie, setKategorie] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const {register, handleSubmit, setValue} = useForm({
        defaultValues: {
            nazev: ""
        }
    });

    useEffect(() => {

        const load = async () => {
            await axios.get('dokumenty/kategorie/full').then(res => {
                setKategorie(res.data);
            }).catch(err => {
                alert.error('Nepovedlo se načíst data.', {timeout: 0});
            })
        }

        if (!loaded) {
            load().then(res => {
                setLoaded(true);
            });
        }
    });

    const onSubmit = async (data) => {
        await axios.post('/dokumenty/kategorie/' + id, data).then((res) => {
            setShowForm(false);
            setId(0);
            setLoaded(false);
            alert.success('Kategorie byla uložena');
        }).catch(err => {
            alert.error('Bšhem', {timeout: 0});
        });

    }

    const handleButtonClick = (state) => {
        setId(state.id);
        axios.get('/dokumenty/kategorie/' + state.id).then(res => {
            setValue("nazev", res.data.nazev);
        }).catch(err => {
            alert.error('Nepovedlo se načíst data.', {timeout: 0});
        });
        setShowForm(true);
    }

    return (
        <div>
            <div className={"subMenu"}>
                <button className="item" onClick={() => {
                    setId(0);
                    setShowForm(true);
                }}><FontAwesomeIcon
                    icon={faPlus}/> Přidat kategorii
                </button>
            </div>

            <DataTable
                title="Kategorie"
                data={kategorie}
                columns={[
                    {
                        name: 'ID',
                        selector: 'id',
                        sortable: true,
                    },
                    {
                        name: 'Název',
                        selector: 'nazev',
                        sortable: true,
                    },
                    {
                        name: 'Vytvořeno',
                        selector: d => <Moment date={d.vytvoreno} format={"DD.MM.yyyy"}/>,
                        sortable: true,
                    },
                    {
                        cell: (row) =>
                            <button className="btn-table-edit" onClick={() => {
                                handleButtonClick(row)
                            }}><FontAwesomeIcon
                                icon={faPen}/> Upravit</button>,
                        ignoreRowClick: true,
                        allowOverflow: true,
                        button: true,
                    }
                ]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Záznamů na stránku:',
                    rangeSeparatorText: 'z',
                    noRowsPerPage: false
                }}
                dense
                customStyles={DataTableStyles}
            />

            {showForm &&
            <form onSubmit={handleSubmit(onSubmit)}>
                {id === 0 ? <h3>Nová kategorie</h3> : <h3>Upravit kategorii</h3>}
                <div className={"form"}>
                    <div className="header"></div>
                    <div className="body">
                        <div className={"item"}>
                            <label>Název kategorie</label>
                            <input type={"text"} name={"nazev"} maxLength="100"
                                   ref={register({required: true})}/>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="ctrl">
                            <button type="submit" className="item btn-save">
                                <FontAwesomeIcon icon={faSave} className="i"/>Uložit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            }
        </div>
    );

}
export default DokumentyKategorie;