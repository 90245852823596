import React, {useRef} from 'react';
import {useForm} from "react-hook-form";
import InputError from '../shared/InputError';
import messages from '../shared/InputErrorMessages';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faSync} from '@fortawesome/free-solid-svg-icons';
import {Link, useHistory, useParams} from "react-router-dom";
import './ObnovaHesla.scss';
import axios from 'axios';
import {useAlert} from "react-alert";

const ObnovaHesla = () => {

    const alert = useAlert();
    const {register, handleSubmit, errors, watch} = useForm();
    const password = useRef({});
    password.current = watch("password", "");

    const {token} = useParams();

    let history = useHistory();

    const onSubmit = (async (data) => {

        axios.post("/api/clenove/obnova-hesla", data).then(res => {
            if (res.data.result > 0) {
                alert.error(res.data.message, {timeout: 0});

            } else {
                history.push('/login')
                alert.success(res.data.message, {timeout: 2000});
            }
        }).catch(err => {
            alert.error('Během zpracování došlo k chybě', {timeout: 0});
        });

    });

    const onSubmitReset = (data) => {
        axios.post("/api/clenove/obnova-hesla/" + token, data).then(res => {
            if (res.data.result === 0) {
                alert.success(res.data.message);
            }
            else{

                switch (res.data.result) {
                    case 1:
                    case 2:
                        alert.error(res.data.message, {timeout: 0});
                        break;
                    default:
                        break;
                }
            }
        }).catch(err => {
            alert.error('Během zpracování došlo k chybě', {timeout: 0});
        });
    }


    return (
        <div className="obnova-hesla-page">
            <h1>Obnova hesla</h1>

            <div className="form">
                <div className="header">
                    <div>
                        <FontAwesomeIcon icon={faChevronLeft} className="i"/> <Link to={"/login"}>Zpět</Link>
                    </div>
                </div>

                {!token ?
                    <div>
                        <div className="body">
                            <div className={"item"}>
                                Pro obnovu Vašeho hesla zadejte ID uživatele a  Váš email, na který Vám zašleme instrukce.
                            </div>
                            <div className="item">
                                <label>ID uživatele</label>
                                <input type={"text"} name={"id"} ref={register({required: true})}/>
                                {errors.id &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                            <div className="item">
                                <label>Email</label>
                                <input type="email" name="email" ref={register({required: true})}/>
                                {errors.email &&
                                <InputError message={messages.required}></InputError>}
                            </div>
                        </div>
                        <div className="footer">
                            <div className="ctrl">
                                <button type="button" onClick={handleSubmit(onSubmit)} className="btn-save">
                                    <FontAwesomeIcon
                                        icon={faSync} className="i"/>
                                    Odeslat instrukce na email
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="body">
                            <div className={"item"}>
                                Vyplňte prosím nové heslo a jeho potvrzení.
                            </div>
                            <div className="item">
                                <label>Heslo</label>
                                <input
                                    name="password"
                                    type="password"
                                    ref={register({required: true})}
                                />
                                {errors.password && <InputError message={"Položka je povinná"}></InputError>}
                            </div>
                            <div className="item">
                                <label>Heslo - potrzení</label>
                                <input
                                    name="passwordConfirm"
                                    type="password"
                                    ref={register({
                                        validate: value =>
                                            value === password.current || "Hesla se neshodují"
                                    })}
                                />
                                {errors.passwordConfirm &&
                                <InputError message={"Hesla se neshodují"}></InputError>}
                            </div>
                            <div className="footer">
                                <div className="ctrl">
                                    <button type="button" onClick={handleSubmit(onSubmitReset)} className="btn-save">
                                        <FontAwesomeIcon
                                            icon={faSync} className="i"/>
                                        Nastavit nové heslo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>

        </div>

    )
}

export default ObnovaHesla;