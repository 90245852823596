import React, {useState, useEffect} from 'react';
import EmailService from "../../Services/EmailService";
import {useAlert} from "react-alert";
import DataTable from "react-data-table-component";
import DataTableStyles from "../shared/DataTableStyle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import {faInfo} from "@fortawesome/free-solid-svg-icons/faInfo";
import Parser from 'html-react-parser';




const EmailHeader = (props) => {
    const alert = useAlert();
    const [data, setData] = useState([]);
    const [textEmail, setTextEmail] = useState(null);




    const deleteQue = async () => {
        let res = await EmailService.DeleteEmailHeader(props.nazev, props.vytvoreno, alert);
        if(res.data.result === 1){
            props.onClose();
        }
    }

    useEffect(() => {
        const loadData = async () => {
            let res = await EmailService.GetDetailEmailHeader(props.nazev,props.vytvoreno, alert);
            console.log(res.data);
            setData(res.data);


        }

        loadData();
    }, [alert, props.nazev, props.vytvoreno]);

    const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false,

    });

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            width: '60px'
        },
        {
            name: 'Email',
            selector: 'emailAddr',
            sortable: true,
            width: '250px'
        },
        {
            name: 'Stav',
            selector: d => { if (d.stav === 0) {return 'neodesláno'} else {return 'odesláno'}} ,
            sortable: true,
            width: '100px'

        },
        {
            name: 'Update',
            selector: d => { if(d.update !== '0001-01-01T00:00:00' && d.update !== ''){return <Moment date={d.update} format={"DD.MM.YYYY"}/>}},
            sortable: true,
            width: '120px'
        },
        {
            name: 'Zprava',
            selector: d => { return (<span onClick={() => setTextEmail({zprava: d.zprava, predmet: d.predmet})} style={{backgroundColor: "#3693d0", padding: 10, color: 'white', borderRadius: 5, cursor: 'pointer'}}> <FontAwesomeIcon icon={faInfo} /></span>)},
            sortable: false,
            width: '70px'
        },
        {
            name: 'Note',
            selector: 'note',
            sortable: false
        }
        



    ];




    return(
        <div className={"emailyHeaders"}>
            <h1>Detail emailů v dané emailové frontě</h1>
            <div className="subMenu">
                <button onClick={deleteQue} className={"btn-delete"}><FontAwesomeIcon icon={faTrashAlt} className={"i"} /> Smazat celou frontu</button>
            </div>

                <div className={"table"}>
                    <DataTable
                        title="Výpis emailů v dané frontě"

                        data={data}
                        columns={columns}
                        paginationComponentOptions={pagination}
                        pagination
                        dense
                        defaultSortField = 'stav'
                        customStyles={DataTableStyles}
                        paginationPerPage = {30}
                        paginationRowsPerPageOptions = {[10,30,50,100]}

                    />
                </div>
            { textEmail ?
                <div className='textEmailu'>
                    <div><h5>{textEmail.predmet}</h5></div>
                    <div>
                        {Parser(textEmail.zprava)}
                    </div>
                </div> : null
            }



        </div>
    )
}

export default EmailHeader;